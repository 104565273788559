/* ===================================================================================================================
   Slider - Caroufredsel (Erweiterung)
   ================================================================================================================ */

// -------------------------------------------------------------------------------------------------------------------
// Prevent layout jumping / No FOUC
// -------------------------------------------------------------------------------------------------------------------

.no-js .ce_caroufredsel_gallery {
	height : 225px;
}

.js .ce_caroufredsel_gallery {
	height : auto;
}

.no-js .ce_caroufredsel_gallery .caroufredsel_controls,
.no-js .ce_caroufredsel_gallery .image_container {
	display : none;
}

.js .ce_caroufredsel_gallery .caroufredsel_controls,
.js .ce_caroufredsel_gallery .image_container {
	display : block;
}

// -------------------------------------------------------------------------------------------------------------------
// Layout
// -------------------------------------------------------------------------------------------------------------------


.ce_caroufredsel_gallery {
	
	@include flex-grid-row();
}


.ce_caroufredsel_gallery .caroufredsel_skin{
	
	@include flex-grid-column(12);
}


.ce_caroufredsel_gallery .caroufredsel_skin {
	position : relative;
	margin-top: $margin-medium;
	margin-bottom: $margin-medium;
	//@include breakpoint(medium) {
	//	margin-top: $margin-large;
	//	margin-bottom: $margin-large;
	//}
}

.ce_caroufredsel_gallery {
	margin-bottom : $margin-medium;
	
	@include breakpoint(large) {
		//margin-bottom : $margin-large;
	}
}

.ce_caroufredsel_gallery .image_container {
	margin     : 1px 15px 1px 15px;
	//padding    : $padding-small;
	//border     : 1px solid $light-gray;
	box-shadow : $global-shadow;
	background-color: $white;
}

@include breakpoint(1300) {
	
	.ce_caroufredsel_gallery .caroufredsel_controls {
		position : absolute;
		top      : calc(50% - 32px);
		left     : 0;
		width    : 100%;
	}
}

.ce_caroufredsel_gallery .caroufredsel_navi {
	position : relative;
	height   : 42px;
	
	@include breakpoint(1300) {
		height : 0;
	}
}

.ce_caroufredsel_gallery .caroufredsel_navi a.caroufredsel_prev,
.ce_caroufredsel_gallery .caroufredsel_navi a.caroufredsel_next {
	position  : absolute;
	top       : 10px;
	width     : 32px;
	height    : 32px;
	font-size : 32px;
	color     : rgba($light-gray,1);
}

.ce_caroufredsel_gallery .caroufredsel_navi a.caroufredsel_prev:hover,
.ce_caroufredsel_gallery .caroufredsel_navi a.caroufredsel_next:hover {
	color     : $white;
}
.ce_caroufredsel_gallery .caroufredsel_navi a.caroufredsel_prev.disabled,
.ce_caroufredsel_gallery .caroufredsel_navi a.caroufredsel_next.disabled,
.ce_caroufredsel_gallery .caroufredsel_navi a.caroufredsel_prev.disabled:hover,
.ce_caroufredsel_gallery .caroufredsel_navi a.caroufredsel_next.disabled:hover {
	color     : rgba($light-gray,0.3);
	cursor: default;
}


.ce_caroufredsel_gallery .caroufredsel_navi a.caroufredsel_prev {
	left : calc(50% - 40px);
	@include icon(circle-left2, before);
	
	@include breakpoint(1300) {
		left : -46px;
	}
}

.ce_caroufredsel_gallery .caroufredsel_navi a.caroufredsel_next {
	right : calc(50% - 40px);
	@include icon(circle-right2, before);
	
	@include breakpoint(1300) {
		right : -38px;
	}
	
}

.ce_caroufredsel_gallery .caption {
	
	display: none;
}



.ce_caroufredsel_gallery .image_container  a {
	display  : block;
	position : relative;
	height     : 100%;
}

.ce_caroufredsel_gallery .image_container  a:after {
	background : rgba($black, 0) none no-repeat scroll center center / 2rem 2rem;
	content    : "";
	height     : 100%;
	left       : 0;
	position   : absolute;
	top        : 0;
	transition : background 0.3s ease 0s;
	width      : 100%;
	z-index    : 2;
}

.ce_caroufredsel_gallery .image_container a:hover:after {
	background : rgba($black, .7)  no-repeat scroll center center / 2rem 2rem;
	
}

.ce_caroufredsel_gallery .image_container a:before {
	@include icon(plus3);
	color      : $white;
	font-size  : 20px;
	left       : 0;
	//height     : 100%;
	top: 48%;
	position   : absolute;
	text-align : center;
	width      : 100%;
	z-index    : 99;
	opacity    : 0;
	transition : opacity 0.3s ease 0s;
	align-self : center;
}

.ce_caroufredsel_gallery .image_container a:hover:before {
	
	opacity: 1;
}
