/* ===================================================================================================================
   RSCE 1-001 - CE Item - Intro Text (Template: rsce_1_001_intro_text)
   ================================================================================================================ */

.ce_item-intro-text2 {
	position : relative;
	height   : calc(100vh - 78px);
	
	.inside {
		
		position        : absolute;
		top             : 0;
		left            : 0;
		width           : 100%;
		height          : 100%;
		display         : flex;
		flex-direction  : column;
		justify-content : center;
		align-items     : center;
		
		.headline,
		.addition {
			max-width   : rem-calc(1200);
			width       : 100%;
			padding     : 0 rem-calc(20);
			color       : $white;
			text-shadow : 0 0 30px rgba($black, 0.5);
		}
		
		.headline h1 {
			//font-weight : $font-weight-3;
		}
		
		.addition p {
			line-height : 1;
			font-size   : rem-calc(30);
		}
	}
	
	.overlay {
		position            : absolute;
		bottom              : 0;
		left                : 0;
		width               : 100%;
		height              : 100%;
		background-image    : url("../../beilharz/lp/musterhauspark/silhouette.svg");
		background-size     : contain; // cover
		background-position : 50% 100%;
		background-repeat   : no-repeat;
	}
	
}

//.ios .ce_item-intro-text2 {
//
//	@include breakpoint(large) {
//		//height : 85vh;
//		height : calc(85vh - 78px);
//	}
//}

//.page_type-intro-small .ce_item-intro-text2 {
//	//height   : 65vh;
//	height   : calc(65vh - 119px);
//	position : relative;
//	@include flex-grid-row;
//
//	@include breakpoint(large) {
//		//height : 45vh;
//		height : calc(45vh - 78px);
//	}
//}

//.ce_item-intro-text2__headline {
//	font-weight : $font-weight-1;
//	@include flex-grid-column(12);
//}
//
//.ce_item-intro-text2__headline {
//	position   : absolute;
//	text-align : center;
//	width      : 100%;
//	bottom     : 13%;
//	display    : block;
//	opacity    : 1;
//}

//.start-scroll .ce_item-intro-text2__headline h1 {
//	opacity    : 0;
//	transition : opacity 1s linear;
//}

//.ce_item-intro-text2__headline h1 {
//	hyphens     : none;
//	color       : $white;
//	text-shadow : 0 0 30px rgba($black, 0.5);
//	@include breakpoint(large) {
//		position : relative;
//		margin   : 0 auto;
//		width    : 600px;
//	}
//}



