// ===================================================================================================================
// NIVO Lightbox
// Link: https://github.com/fritzmg/contao-nivo-lightbox
// Link: http://docs.dev7studios.com/jquery-plugins/nivo-lightbox
// ===================================================================================================================

body.overflow-hidden {
	// when nivio lightbox is open, the content in the background won't scroll
	// siehe 'j_nivo_lightbox.html5' Zeile 48ff.
	overflow : hidden;
}

.page_type-privacy-policy.lightbox { // Anzeige Datenschutz in Lightbox bei Formularen
	.ce_item-content-wrapper {
		padding-top    : rem-calc(30) !important;
		padding-bottom : rem-calc(30) !important;
	}
}

//nivo-lightbox-open

.nivo-lightbox-theme-default.nivo-lightbox-overlay {
	background : rgba($black, 0.9);
}

.nivo-lightbox-theme-default .nivo-lightbox-nav:focus,
.nivo-lightbox-theme-default .nivo-lightbox-nav:hover {
	background-color : transparent;
}

.nivo-lightbox-theme-default .nivo-lightbox-prev {
	background-size   : 32px 32px;
	background-repeat : no-repeat;
	background-image  : url("../img/icon_arrow-left-white-1.svg");
	border-radius     : 0;
}

.nivo-lightbox-theme-default .nivo-lightbox-next {
	background-size   : 32px 32px;
	background-repeat : no-repeat;
	background-image  : url("../img/icon_arrow-right-white-1.svg");
	border-radius     : 0;
}

.nivo-lightbox-close {
	position : absolute;
	top      : 2%;
	right    : 2%;
}

.nivo-lightbox-theme-default .nivo-lightbox-close {
	display           : block;
	width             : 32px;
	height            : 32px;
	padding           : 0;
	background-size   : 32px 32px;
	background-repeat : no-repeat;
	background-image  : url("../img/icon_close-white-1.svg");
}

.nivo-lightbox-theme-default .nivo-lightbox-title {
	display       : inline-block;
	padding       : 5px;
	color         : $white;
	background    : $black;
	border-radius : 0;
}

.nivo-lightbox-content iframe {
	//background : rgba($black, 0.9);
	background : $white;
}
