/* ====================================================================================================================
   RSCE 2-040-01 - Parallax // Template: rsce_2_040_01_parallax
   ================================================================================================================= */
// https://github.com/marrio-h/universal-parallax - Version 1.3.2
// -------------------------------------------------------------------------------------------------------------------

.ce_rsce-parallax.parallax-2-040-01 {
	z-index : 1;
	height  : 350px;
	@include breakpoint(medium) { height : 450px; }
	@include breakpoint(large) { height : 650px; }
	
	.parallax__container {
		z-index  : -100;
		position : absolute;
		top      : 0;
		left     : 0;
		width    : 100%;
		height   : 100%;
		overflow : hidden;
		clip     : rect(0, auto, auto, 0);
	}
	
	.parallax {
		position            : fixed;
		top                 : 0;
		width               : 100%;
		transform           : translate3d(0, 0, 0);
		transform-style     : preserve-3d;
		// Can be put in a separate class for better control - Start
		background-position : center;
		background-repeat   : no-repeat;
		background-size     : cover;
		// Can be put in a separate class for better control - Stop
	}
	
	.image_container {
		height : 250px;
		@include breakpoint(medium) { height : 350px; }
		@include breakpoint(large) { height : 450px; }
	}
	
	img {
		height          : 100%;
		width           : 100%;
		object-fit      : cover;
		object-position : 50% 50%;
	}
	
	.content-container {
		position : absolute;
		top      : 0;
		left     : 0;
		width    : 100%;
		height   : 100%;
		
		> .inside {
			display         : flex;
			flex-direction  : column;
			justify-content : center;
			align-items     : center;
			width           : 100%;
			height          : 100%;
			
			padding         : 0 rem-calc(20);
			//@include global-width;
			//@include padding-x-standard;
			
			.background {
				max-width : rem-calc(1200);
				//background : $color-transparent-light;
				
				//@include breakpoint(large) { padding : rem-calc(30); }
			}
		}
		
		.headline {
			
			line-height : 1;
			color       : $white;
			font-size   : rem-calc(32);
			font-weight : $font-weight-3;
			
			@include breakpoint(medium) {
				font-size : rem-calc(64);
			}
			
			span { display : block; }
			
		}
		
		//.addition { font-size : rem-calc(18); }
		//
		//&.with-addition > .inside {
		//	.headline { margin-bottom : rem-calc(10); }
		//}
	}
	
	// --------------------------------------------------------------------------------------------------------------------
	
	@include breakpoint(large) {
		.content-container > .inside {
			.background { width : 66.66667%;
			}
		}
	}
}
