/* ===================================================================================================================
   CE Content Wrapper
   ================================================================================================================ */

.ce_item-content-wrapper {
	padding-top    : $padding-large;
	padding-bottom : $padding-large;
	
	@include breakpoint(medium) {
		padding-top    : $padding-xlarge;
		padding-bottom : $padding-xlarge;
	}
}

.page_content .ce_item-content-wrapper:first-child {
	padding-top : 180px;
}

.page_content .content_type-icon-small .ce_item-content-wrapper,
.page_content .content_box-below .ce_item-content-wrapper {
	padding-top    : 3.5rem;
	padding-bottom : 1rem;
	@include breakpoint(medium) {
		padding-top    : 120px;
		padding-bottom : 120px;
	}
}

.page_type-company-10-steps .ce_item-content-wrapper {
	padding-bottom : 0;
	padding-top    : 0;
	
	@include breakpoint(medium) {
		padding-top    : $padding-xlarge;
		padding-bottom : $padding-xlarge;
	}
}

.page_type-company-10-steps .page_content {
	padding-bottom : 0;
	padding-top    : 120px;
	
	@include breakpoint(medium) {
		padding-top : 0;
	}
}
