/* ===================================================================================================================
   Booknav
   ================================================================================================================ */


.mod_booknav{
	
	background: $white;
	font-size: rem-calc(18);
	text-transform: uppercase;
}

.mod_booknav .row{
	
	margin-bottom: 0;
}
.mod_booknav ul{
	
	//@include flex-grid-row();
	margin-bottom: 0;

	
}

.mod_booknav li {
	@include flex-grid-column(12);
	border-bottom: 1px solid $light-gray;
	list-style: none;

	text-align: center;

	@include breakpoint(large) {
		border-bottom: 0 solid $light-gray;
		text-align: left;
	@include flex-grid-column(4);
	}
	transition: background 0.25s ease-out 0s;

}


.mod_booknav li.next{
	border-bottom: 0 solid $light-gray;
	@include breakpoint(large) {
	text-align: right;
	}
}

.mod_booknav li.up{

	@include breakpoint(large) {
		//background: rgba($light-gray,0.5);
		border-left: 1px solid $light-gray;
		border-right: 1px solid $light-gray;
	}
}

.mod_booknav a{
	padding-top: $padding-small;
	padding-bottom: $padding-small;
	display: block;
	width: 100%;
	max-width: 100%;
	float: right;
	@include breakpoint(large) {
		max-width: 18rem;
	}
	//transition: color 0.25s ease-out 0s;
	
}

.mod_booknav li.next a{
	float: left;
	
}

.mod_booknav li.up a{
	float: none;
	text-align: center;
	width: 100%;
	max-width: 100%;
	
}

.mod_booknav li.prev a:before {
	@include icon(arrow-left4);
	position: relative;
	margin-right: 5px;
	display: inline-block;
	font-size      : rem-calc(14);
}

.mod_booknav li.next a:after {
	@include icon(arrow-right4);
	position: relative;
	margin-left: 5px;
	display: inline-block;
	font-size      : rem-calc(14);
}

.mod_booknav li.active:hover{
	background: $secondary-color;
	
}

.mod_booknav li:hover a{
	color: $white;
	
}

.mod_booknav.booknav-back li {
	
	border: 0 ;
}
