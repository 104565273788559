/* ===================================================================================================================
   RSCE 1-001 - CE Item - Intro Text (Template: rsce_1_001_intro_text)
   ================================================================================================================ */
//.ce_item-intro-text { }
//.ce_item-intro-text__addition {}
//.ce_item-intro-text__headline { }
//.ce_item-intro-text__subheadline { }
//.ce_item-intro-text__content { }
// -------------------------------------------------------------------------------------------------------------------

.ce_item-intro-text {
	//height   : 80vh;
	height   : calc(80vh - 119px);
	position : relative;
	@include flex-grid-row;
	
	@include breakpoint(large) {
		//height : 95vh;
		height : calc(95vh - 78px);
	}
	
}

.ios .ce_item-intro-text {
	
	@include breakpoint(large) {
		//height : 85vh;
		height : calc(85vh - 78px);
	}
}

.page_type-intro-small .ce_item-intro-text {
	//height   : 65vh;
	height   : calc(65vh - 119px);
	position : relative;
	@include flex-grid-row;
	
	@include breakpoint(large) {
		//height : 45vh;
		height : calc(45vh - 78px);
	}
	
}

.ce_item-intro-text__headline {
	font-weight : $font-weight-1;
	@include flex-grid-column(12);
}

.ce_item-intro-text__headline {
	position   : absolute;
	text-align : center;
	width      : 100%;
	bottom     : 13%;
	display    : block;
	opacity    : 1;
}

.start-scroll .ce_item-intro-text__headline h1 {
	opacity    : 0;
	transition : opacity 1s linear;
}

.ce_item-intro-text__headline h1 {
	hyphens     : none;
	color       : $white;
	text-shadow : 0 0 30px rgba($black, 0.5);
	@include breakpoint(large) {
		position : relative;
		margin   : 0 auto;
		width    : 600px;
	}
}

.ce_item-intro-text__headline h1 span {
	font-size : rem-calc(30);
	display   : block;
}

.ce_item-intro-text .link_enter-wrapper {
	display  : block;
	position : absolute;
	bottom   : -13px;
	width    : 100%;
	@include breakpoint(large) {
		bottom : -23px;
	}
	
}

.page_type-intro-small .link_enter-wrapper {
	display : none;
}

.ce_item-intro-text a.link_enter {
	font-size  : rem-calc(25);
	height     : 35px;
	width      : 36px;
	background : $secondary-color;
	//float: right;
	//margin-right : 0;
	margin     : 0 auto;
	padding    : 2px 6px;
	color      : $white;
	display    : block;
	z-index    : 50;
	@include icon(arrow-down3, before);
	@include breakpoint(medium) {
		font-size : rem-calc(36);
		height    : 45px;
		width     : 46px;
		padding   : 0 5px;
	}
}
