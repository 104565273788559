/* ===================================================================================================================
   Settings - Project
   ================================================================================================================ */

$color-1                   : #639d23;
$color-2                   : darken($medium-gray, 10%);
//$color-3 : #f5f4f4;
//$color-4 : #5e8bc6;

$global-shadow             : 2px 2px 7px rgba($dark-gray, 0.4);
$global-lighter-shadow     : 2px 2px 7px rgba($dark-gray, 0.2);

@mixin secondary-font {
	font-family    : 'Freehand521W01-Regular', cursive;
	letter-spacing : normal;
	letter-spacing : -0.02em;
}

.secondary-font {
	@include secondary-font();
}

p .secondary-font {
	font-size : 150%;
}

.img-circle img {
	border-radius : 50%;
	@include breakpoint(medium) {
		max-width : 50%;
	}
}

// Anpassung je nach verwendeten Schriftarten im Projekt erforderlich
// Einbindung der Schriften erfolgt je nach Variante entweder in 'project.scss' und/oder Contao Seitenlayout
// Einstellung Variable '$body-font-family' in 'settings-foundation' beachten/anpassen
$font-weight-1             : 300; // 100 - 300
$font-weight-2             : 400; // 400 - 500
$font-weight-3             : 700; // 600 - 700
$font-weight-4             : 900; // 800 - 900

// Überschreibt Foundation Core Einstellungen
$global-weight-normal      : $font-weight-2;
$global-weight-bold        : $font-weight-4;

$letter-spacing-small      : 0.002em;
$letter-spacing-medium     : 0.05em;
$letter-spacing-large      : 0.075em;

// -------------------------------------------------------------------------------------------------------------------

// Klassen zur Steuerung vertikaler(!) Abstände
// TODO PF: Umbenennen in $margin-y-small etc.?!
$margin-small              : 20px;
$margin-medium             : 30px;
$margin-large              : 80px;
$margin-xlarge             : 120px;

.margin-y-large {
	margin-top    : $margin-medium !important;
	margin-bottom : $margin-medium + 30px !important;
	@include breakpoint(medium) {
		margin-top    : $margin-large !important;
		margin-bottom : $margin-large + 30px !important;
	}
}

$padding-small             : 15px;
$padding-medium            : 30px;
$padding-large             : 80px;
$padding-xlarge            : 120px;

.padding-none {
	padding : 0;
}

.padding-top {
	padding-top : $padding-small !important;
	@include breakpoint(medium) {
		padding-top : $padding-medium !important;
	}
}

.padding-bottom {
	padding-bottom : $padding-medium !important;
	@include breakpoint(medium) {
		padding-bottom : $padding-large !important;
	}
}

// -------------------------------------------------------------------------------------------------------------------

// Ausgleich Außenabstand Grid. Setzt Zentrierung außer Kraft!
// Anwendung mit vorangestelltem Minus und Wert/2!
$grid-column-gutter-small  : map-get($grid-column-gutter, small);
$grid-column-gutter-medium : map-get($grid-column-gutter, medium);

@mixin grid-remove-row-margin {
	margin-left  : -$grid-column-gutter-small/2;
	margin-right : -$grid-column-gutter-small/2;
	@include breakpoint(medium) {
		margin-left  : -$grid-column-gutter-medium/2;
		margin-right : -$grid-column-gutter-medium/2;
	}
}

// Helferklasse 'remove-margin' wird in theme-development erzeugt
// Integration in Array 'row' von DMA Simple Grid Konfiguration

// -------------------------------------------------------------------------------------------------------------------

// Klasse für Zuweisung horizontaler Paddings in Abgleich mit Grid Paddings
@mixin grid-padding-x {
	padding-left  : rem-calc($grid-column-gutter-small) / 2;
	padding-right : rem-calc($grid-column-gutter-small) / 2;
	@include breakpoint(medium) {
		padding-left  : rem-calc($grid-column-gutter-medium) / 2;
		padding-right : rem-calc($grid-column-gutter-medium) / 2;
	}
}

@mixin grid-padding-x-none {
	padding-left  : 0;
	padding-right : 0;
	@include breakpoint(medium) {
		padding-left  : 0;
		padding-right : 0;
	}
}

// -------------------------------------------------------------------------------------------------------------------
// Buttons
// -------------------------------------------------------------------------------------------------------------------

@mixin button-primary {
	// Noch ohne Invertierung
	// TinyMCE Klasse wahrscheinlich kaum sinnvoll. Besser separates Mixin erstellen und bei Abschnitt mit Farbe zuweisen
	position        : relative;
	padding-right   : rem-calc(46);
	color           : $white;
	font-size       : rem-calc(16);
	text-align      : left;
	text-decoration : none;
	
	&:after {
		@include icon(arrow-right4);
		position  : absolute;
		top       : calc(50% - 7px);
		right     : 1rem;
		font-size : 14px;
		color     : $white;
	}
	
	&.full-width { // Noch keine Einrichtung in TinyMCE - Die Frage ist, ob das überhaupt sinnvoll wäre
		width : 100%;
	}
}

@mixin button-secondary {
	// Noch ohne Invertierung
	// TinyMCE Klasse wahrscheinlich kaum sinnvoll. Besser separates Mixin erstellen und bei Abschnitt mit Farbe zuweisen
	position        : relative;
	padding-right   : rem-calc(46);
	background      : transparent;
	border          : 1px solid $medium-gray;
	color           : $medium-gray;
	font-size       : rem-calc(16);
	text-align      : left;
	text-decoration : none;
	
	&:after {
		@include icon(arrow-right4);
		position  : absolute;
		top       : calc(50% - 7px);
		right     : 1rem;
		font-size : 14px;
		color     : $medium-gray;
	}
	
	&.full-width { // Noch keine Einrichtung in TinyMCE - Die Frage ist, ob das überhaupt sinnvoll wäre
		width : 100%;
	}
	&:focus,
	&:hover {
		background : $medium-gray;
		color      : $primary-color;
	}
	&:hover:after {
		color : $primary-color;
	}
}

.button.button-secondary {
	@include button-secondary;
}
