/* ===================================================================================================================
   RSCE 1-001 - CE Item - Intro Text (Template: rsce_1_001_intro_text-box)
   ================================================================================================================ */
//.ce_item-intro-text-box { }
//.ce_item-intro-text-box__addition {}
//.ce_item-intro-text-box__headline { }
//.ce_item-intro-text-box__subheadline { }
//.ce_item-intro-text-box__content { }
// -------------------------------------------------------------------------------------------------------------------
.ce_item-intro-text-box-wrapper {
	
	height : calc(100vh - 78px);
	
	//@include breakpoint(large) {
	//	height : 100vh;
	//}
}

.ce_item-intro-text-box {
	height   : 80vh;
	position : relative;
	@include flex-grid-row;
	@include breakpoint(large) {
		height : 95vh;
	}
	
}

.ios .ce_item-intro-text-box {
	
	@include breakpoint(large) {
		height : 85vh;
	}
	
}

.page_type-intro-small .ce_item-intro-text-box {
	height   : 65vh;
	position : relative;
	@include flex-grid-row;
	@include breakpoint(large) {
		height : 45vh;
	}
	
}

.ce_item-intro-text-box__addition {
	color     : $white;
	font-size : rem-calc(26);
}

.ce_item-intro-text-box__headline {
	font-weight : $font-weight-1;
	@include flex-grid-column(12);
}

.ce_item-intro-text-box__headline {
	position   : absolute;
	text-align : center;
	width      : 100%;
	bottom     : 13%;
	display    : block;
	opacity    : 1;
	@include breakpoint(large) {
		margin-top : 25%;
		position   : relative;
		text-align : left;
		bottom     : 0;
	}
}

.start-scroll .ce_item-intro-text-box__headline {
	opacity    : 0;
	transition : opacity 1s linear;
}

.ce_item-intro-text-box__headline h1 {
	@include secondary-font;
	//font-size   : 3.375rem;
	hyphens     : none;
	color       : $white;
	text-shadow : 0 0 30px rgba($black, 0.5);
	@include breakpoint(large) {
		position : relative;
		width    : 600px;
	}
}

.ce_item-intro-text-box__subheadline {
	background-color : $secondary-color;
	color            : $white;
	display          : inline-block;
	padding          : 3px 10px;
	font-weight      : $font-weight-3;
	margin-top       : 0.5em;
	//transform        : rotate(-2deg);
}

.ce_item-intro-text-box .link_enter-wrapper {
	display  : block;
	position : absolute;
	bottom   : -13px;
	width    : 100%;
	@include breakpoint(large) {
		bottom : -23px;
	}
	
}

.ce_item-intro-text-box__boxwrapper {
	@include flex-grid-row;
	background-color : $white;
	position         : relative;
	z-index          : 1;
	@include breakpoint(large) {
		position         : relative;
		background-color : transparent;
		margin-top       : -425px;
		//top              : -585px;
	}
}

.ce_item-intro-text-box__contentwrapper {
	@include flex-grid-column(12);
	@include breakpoint(large) {
		@include flex-grid-column(6);
		@include grid-column-offset(6);
	}
}

.ce_item-intro-text-box__contentwrapper .image_container {
	margin-top : 2em;
	text-align : center;
	position   : relative;
	@include breakpoint(large) {
		text-align : right;
	}
}

.ce_item-intro-text-box__contentwrapper img {
	width         : 150px;
	border-radius : 50%;
	@include breakpoint(large) {
		position : absolute;
		top      : -80px;
		right    : -30px;
	}
}

.ce_item-intro-text-box__content {
	background-color : $white;
	text-align       : center;
	@include breakpoint(large) {
		text-align : left;
		padding    : 1rem 4rem 1rem 2rem;
	}
}

.ce_item-intro-text-box__content h3 {
	@include secondary-font;
}

.ce_item-intro-text-box__content h3:after {
	content : none;
}

.page_type-intro-small .link_enter-wrapper {
	display : none;
}

.ce_item-intro-text-box a.link_enter {
	font-size    : rem-calc(25);
	height       : 35px;
	width        : 36px;
	background   : $secondary-color;
	//float: right;
	margin-right : 0;
	margin       : 0 auto;
	padding      : 2px 6px;
	color        : $white;
	display      : block;
	position     : relative;
	z-index      : 50;
	@include icon(arrow-down3, before);
	@include breakpoint(medium) {
		font-size : rem-calc(36);
		height    : 45px;
		width     : 46px;
		padding   : 0 5px;
	}
	
}

