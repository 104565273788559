/* ===================================================================================================================
   Special Styles Landingpages
   ================================================================================================================ */
.page_type-landingpage #enter {
	position : relative;
	@include breakpoint(medium) {
		//margin-top : 110px
	}
}

.page_type-landingpage .page_header .logo_company {
	text-align   : left;
	//padding-left : $padding-small;
	padding-left : rem-calc(20);
	@include breakpoint(medium) {
		text-align : left;
	}
}

.page_type-landingpage .ce_gallery {
	margin-top    : 3em;
	margin-bottom : 3em;
}

.img-frame-left img {
	border        : 5px solid $white;
	box-shadow    : $global-lighter-shadow;
	transform     : rotate(-3deg);
	margin-bottom : 3em;
}

.img-frame-right img {
	border        : 5px solid $white;
	box-shadow    : $global-lighter-shadow;
	transform     : rotate(3deg);
	margin-bottom : 3em;
}

.page_type-landingpage legend {
	font-weight : $font-weight-2;
}

.page_type-landingpage .ce_form {
	margin-top : 30px;
}

.page_type-landingpage .slogan .image_container {
	margin : 0 auto;
	@include breakpoint(xsmall) {
		width : 50%;
	}
}

// Umsortierung Text/ Bild im Bereich "GUT FÜR SIE UND GUT FÜR DIE NATUR"

.page_type-landingpage-1 .nature-section-1 .sustainability-text {
	@include breakpoint(medium) {
		order : 2;
	}
	@include breakpoint(large) {
		order : 1;
	}
}

.page_type-landingpage-1 .nature-section-1 .sustainability-image {
	@include breakpoint(medium) {
		order : 1;
	}
	@include breakpoint(large) {
		order : 2;
	}
}

.page_type-landingpage-1 .nature-section-2 .stability-text {
	@include breakpoint(large) {
		order : 2;
	}
}

.page_type-landingpage-1 .nature-section-2 .stability-image {
	@include breakpoint(large) {
		order : 1;
	}
}

.page_type-landingpage-1 .nature-section-2 .quality-text {
	@include breakpoint(medium) {
		order : 2;
	}
	@include breakpoint(large) {
		order : 4;
	}
}

.page_type-landingpage-1 .nature-section-2 .quality-image {
	@include breakpoint(medium) {
		order : 1;
	}
	@include breakpoint(large) {
		order : 3;
	}
}

.sustainability-image,
.stability-image,
.quality-image,
.health-image {
	@include breakpoint(medium) {
		margin-top : 20px;
	}
	@include breakpoint(large) {
		margin-top : 0;
	}
}
