/* ===================================================================================================================
   Navigation - Foundation
   ================================================================================================================ */

.no-js {
	@include breakpoint(small only) {
		.top-bar {
			display : none;
		}
	}
	
	@include breakpoint(medium) {
		.title-bar {
			display : none;
		}
	}
}

.title-bar .menu-icon {
	margin : 0 auto;
}

.menu-icon:after {
	box-shadow : 0 6px 0 $white, 0 12px 0 $white;
}

.menu-icon:hover:after {
	box-shadow : 0 6px 0 #cacaca, 0 12px 0 #cacaca;
}

.title-bar {
	position       : relative;
	z-index        : 999;
	width          : 100%;
	text-align     : center;
	text-transform : uppercase;
	display        : block;
}

.title-bar-title {
	font-weight : $font-weight-2;
}

.title-bar-title:hover {
	cursor : pointer;
}

.nav-wrapper {
	width      : 100%;
	transition : position ease 0.5s;
}

.top-bar {
	background-color : $primary-color;
	position         : relative;
	z-index          : 999;
	padding          : 0;
	
	@include breakpoint(large) {
		background-color : $white;
		padding-top      : 20px;
		float            : right;
	}
	
}

.top-bar .top-bar-right {
	width : 100%;
	@include breakpoint(large) {
		position : relative;
		//width        : 100%;
		//margin-right : 20px;
	}
}

.top-bar a {
	color          : $white;
	text-transform : uppercase;
	letter-spacing : $letter-spacing-medium;
	padding        : 0.7rem 1rem;
	@include breakpoint(large) {
		color     : $primary-color;
		font-size : rem-calc(15);
	}
	@include breakpoint(xlarge) {
		font-size : rem-calc(16);
	}
}

.top-bar a.active, .top-bar a.trail {
	font-weight      : $font-weight-4;
	background-color : transparent;
	@include breakpoint(large) {
		color : $primary-color;
	}
	
}

.start-scroll .nav-wrapper {
	position : fixed;
	right    : 0;
	top      : 0;
	z-index  : 9999;
	@include breakpoint(large) {
		position : relative;
	}
}

.top-bar .menu.nested {
	background  : $black;
	margin-left : 0;
	@include breakpoint(large) {
		box-shadow : 2px 2px 7px 0 rgba($black, 0.2);
		background : $dark-gray;
	}
}

.top-bar ul ul a {
	color          : $white;
	text-transform : none;
	letter-spacing : $letter-spacing-small;
	padding        : 0.7rem 1rem;
	border-bottom  : 1px solid rgba($white, 0.05);
	@include breakpoint(large) {
		border-bottom : 0px solid rgba($white, 0.05);
	}
}

.top-bar ul ul a.active,
.top-bar ul ul a.trail {
	background-color : $secondary-color;
	font-weight      : $font-weight-4;
	color            : $white;
	@include breakpoint(large) {
		background-color : darken($dark-gray, 10%);
	}
}

.top-bar ul ul a:hover,
.top-bar ul ul a:focus {
	
	@include breakpoint(large) {
		background-color : darken($dark-gray, 50%);
		//color: $primary-color;
	}
}

.top-bar ul.vertical.dropdown {
	background : transparent;
}

.is-dropdown-submenu {
	min-width : 270px;
}

.dropdown.menu.vertical.dropdown > li.is-dropdown-submenu-parent > a:after,
.is-accordion-submenu-parent > a:after {
	border-color : $white transparent transparent;
	border       : 0 solid;
}

.dropdown.menu.vertical.dropdown > li.is-dropdown-submenu-parent > a:after,
.is-accordion-submenu-parent > a:after {
	@include icon(arrow-down2);
	color     : $white;
	font-size : .5rem;
	right     : 10px;
	top       : calc(50% - 4px);
	position  : absolute;
	width     : 8px;
	height    : 8px;
	@include breakpoint(large) {
		color : $primary-color;
		top   : calc(50% - 2px);
	}
}

.top-bar .accordion-menu {
	background-color : darken($dark-gray, 80%);
	color            : $white;
}

.top-bar .accordion-menu li a {
	color : $white;
}

.top-bar .accordion-menu .is-accordion-submenu-item a {
	padding-left : 25px;
}


