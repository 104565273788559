.ce_gallery > ul {
	margin          : 0;
	list-style-type : none;
}

.ajax_gallery > ul {
	margin          : 0;
	list-style-type : none;
	@include flex-grid-row;
}

.ce_gallery {
	padding-bottom : $padding-medium;
	width          : 100%;
	flex           : 0 0 100%;
	max-width      : 100%;
}

//.ce_gallery.layout-1 .ajax_gallery > ul li {
//	@include flex-grid-column(12);
//	margin-bottom : $margin-medium;
//
//	@include breakpoint(medium) {
//		@include flex-grid-column(6);
//	}
//
//	@include breakpoint(large) {
//		@include flex-grid-column(4);
//	}
//
//	@include breakpoint(xlarge) {
//		@include flex-grid-column(3);
//	}
//}

.ce_gallery .ajax_gallery > ul li,
.ce_gallery > ul li {
	padding-bottom : $margin-medium;
}

.ce_gallery a, .ce_gallery span {
	display  : block;
	position : relative;
	height   : 100%;
}

.ce_gallery a:after {
	background : rgba($black, 0) none no-repeat scroll center center / 2rem 2rem;
	content    : "";
	height     : 100%;
	left       : 0;
	position   : absolute;
	top        : 0;
	transition : background 0.3s ease 0s;
	width      : 100%;
	z-index    : 2;
}

.ce_gallery .image_container a:hover:after {
	background : rgba($black, .7) no-repeat scroll center center / 2rem 2rem;
}

.ce_gallery .image_container a:before {
	@include icon(plus3);
	color      : $white;
	font-size  : 20px;
	left       : 0;
	//height     : 100%;
	top        : 45%;
	position   : absolute;
	text-align : center;
	width      : 100%;
	z-index    : 99;
	opacity    : 0;
	transition : opacity 0.3s ease 0s;
	align-self : center;
}

.ce_gallery .image_container a:hover:before {
	opacity : 1;
}

.ce_gallery .pagination a:after,
.ce_gallery .pagination span:after {
	background : rgba($black, 0) none no-repeat scroll center center / 2rem 2rem;
}

.ce_gallery .pagination a:before,
.ce_gallery .pagination span:before {
	content : none;
	opacity : 0;
}

.ce_gallery a img {
	box-shadow : $global-lighter-shadow;
}

.ajax_gallery .pagination {
	@include flex-grid-column(12);
}

.ajax_gallery .ajax_gallery__wrapper-image
	//, .ce_gallery  .image_container
{
	//padding    : 10px;
	border     : 1px solid $light-gray;
	box-shadow : 2px 2px 7px 0 rgba(230, 230, 230, 0.75);
}

.ajax_gallery .image_container {
	position : relative;
}

.ajax_gallery .ajax_gallery__wrapper-figcaption {
	position         : absolute;
	left             : 0;
	right            : 0;
	bottom           : 0;
	display          : none;
	max-width        : 100%;
	padding          : 10px;
	text-transform   : uppercase;
	background-color : rgba(255, 255, 255, .65);
}

.ce_gallery figcaption,
.ajax_gallery figcaption {
	font-size   : rem-calc(12);
	line-height : 1.25;
	display     : none;
}


