/* ====================================================================================================================
   RSCE 2-000-04 - Hero // Template: rsce_2_000_04_hero
   ================================================================================================================= */

.ce_rsce-hero.hero-2-000-04 {
	
	> .inside {
		position : relative;
		
		//&.color {
		//	height     : 250px;
		//	background : #ffffff;
		//@include breakpoint(large) { height : 650px; }
		
		//&.color-1 { background : $color-primary; }
		//
		//&.color-2 { background : $color-gray-light; }
		//}
		
		&.image,
		&.video {
			//height : calc(80vh - 119px);
			height : 100vh;
			//height : calc(100vh - 119px);
			@include breakpoint(large) {
				//height : calc(95vh - 78px);
				height : calc(100vh - 78px);
			}
		}
		
		.video-item {
			//height : calc(80vh - 119px);
			height : 100vh;
			//height : calc(100vh - 119px);
			@include breakpoint(large) {
				//height : calc(95vh - 78px);
				height : calc(100vh - 78px);
			}
		}
		
		.video-container { // Abgleich mit image-container
			//position : relative;
			//height   : calc(80vh - 119px);
			height   : 100vh;
			//height   : calc(100vh - 119px);
			width    : 100%;
			overflow : hidden;
			
			@include breakpoint(large) {
				//height : calc(95vh - 78px);
				height : calc(100vh - 78px);
			}
			
			//&:after {
			//	content    : '';
			//	position   : absolute;
			//	top        : 0;
			//	right      : 0;
			//	bottom     : 0;
			//	left       : 0;
			//	background : radial-gradient(circle, transparent 75%, black 160%); //radial-gradient(circle, transparent 60%, black 150%)
			//}
		}
		
		video {
			width      : 100%;
			//height     : calc(80vh - 119px);
			height     : 100vh;
			//height     : calc(100vh - 119px);
			object-fit : cover;
			
			@include breakpoint(large) {
				//height : calc(95vh - 78px);
				height : calc(100vh - 78px);
			}
		}
		
		// --------------------------------------------------------------------------------------------------------------------
		
		//.image-container { // Abgleich mit video-container
		//	height : 450px;
		//	@include breakpoint(large) { height : 650px; }
		//
		//	.image_container { height : 100%; }
		//
		//	img {
		//		width      : 100%;
		//		height     : 100%;
		//		object-fit : cover;
		//		@include breakpoint(large) { height : 650px; }
		//	}
		//}
		
		// --------------------------------------------------------------------------------------------------------------------
		
		.content-container {
			position : absolute;
			top      : 0;
			left     : 0;
			width    : 100%;
			height   : 100%;
			
			> .inside {
				//display         : flex;
				//flex-direction  : column;
				//justify-content : flex-end;
				width  : 100%;
				height : 100%;
				//@include global-width;
				//@include padding-x-standard;
				
				//.background {
				//position : relative;
				//padding : rem-calc(20);
				//background : $color-transparent-light;
				
				//@include breakpoint(large) { padding : rem-calc(30); }
				
				//&:before { // Siehe: https://stackoverflow.com/questions/47316364/how-to-apply-mix-blend-mode-while-keeping-text-opaque
				//	content        : '';
				//	position       : absolute;
				//	top            : 0;
				//	right          : 0;
				//	left           : 0;
				//	bottom         : 0;
				//	background     : $color-primary;
				//	mix-blend-mode : multiply;
				//}
				//}
				
				.section-1 {
					z-index  : 1;
					position : absolute;
					top      : rem-calc(200);
					left     : rem-calc(60); //100
					display  : block;
					width    : rem-calc(200); // 200
					
					.image_container {
						max-width : rem-calc(255);
					}
					
					@include breakpoint(landscape) {
						display : none;
					}
					
					@media (min-height : rem-calc(420)) {
						@include breakpoint(landscape) {
							display : block;
						}
					}
					
					@include breakpoint(xsmall) {
						//top   : rem-calc(125);
						left  : rem-calc(150);
						width : rem-calc(300);
						
						.image_container {
							max-width : rem-calc(300);
						}
					}
					
					@include breakpoint(large) {
						top   : rem-calc(125);
						left  : rem-calc(300);
						width : rem-calc(500);
						
						.image_container {
							max-width : rem-calc(500);
						}
						
						@include breakpoint(landscape) {
							display : none;
						}
						
						@media (min-height : rem-calc(520)) {
							@include breakpoint(landscape) {
								display : block;
							}
						}
						
					}
					
					//.image_container {
					//	max-width : rem-calc(250);
					//
					//	@include breakpoint(large) {
					//
					//	}
					//}
				}
				
				.section-2 {
					position  : absolute;
					left      : 0;
					bottom    : 0;
					display   : block;
					max-width : rem-calc(200);
					
					@include breakpoint(large) {
						max-width : rem-calc(450);
						
						.image_container {
							max-width : rem-calc(450);
						}
					}
				}
			}
			
			//&.with-addition > .inside { justify-content: center; }
			
			.headline {
				z-index     : 2;
				position    : absolute;
				//right       : rem-calc(100);
				left        : rem-calc(80); // 100
				bottom      : rem-calc(100);
				padding     : rem-calc(10) rem-calc(20); // 15/20
				background  : #ffffff;
				
				transform   : rotate(-3.5deg);
				
				color       : $secondary-color;
				line-height : 1;
				font-size   : rem-calc(26); // 28
				font-weight : $font-weight-4;
				text-align  : center;
				
				&:focus,
				&:hover {
					background : $secondary-color;
					color      : #ffffff;
				}
				
				@include breakpoint(xsmall) {
					left : rem-calc(200);
				}
				
				@include breakpoint(medium) {
					font-size : rem-calc(38);
					left      : unset;
					right     : rem-calc(100);
				}
				
				@include breakpoint(large) {
					font-size : rem-calc(48);
				}
				
				//h1 {
				//	margin      : 0;
				//	line-height : 1;
				//}
			}
			
			//.addition { font-size : rem-calc(18); }
			//
			//&.with-addition > .inside {
			//	.headline { margin-bottom : rem-calc(10); }
			//}
			
			.link_enter-wrapper {
				display  : block;
				position : absolute;
				bottom   : -13px;
				width    : 100%;
				
				@include breakpoint(large) {
					bottom : -23px;
				}
				
				a.link_enter {
					font-size  : rem-calc(25);
					height     : 35px;
					width      : 36px;
					background : $secondary-color;
					//float: right;
					//margin-right : 0;
					margin     : 0 auto;
					padding    : 2px 6px;
					color      : $white;
					display    : block;
					z-index    : 50;
					@include icon(arrow-down3, before);
					@include breakpoint(medium) {
						font-size : rem-calc(36);
						height    : 45px;
						width     : 46px;
						padding   : 0 5px;
					}
					
				}
			}
			
		}
		
		// --------------------------------------------------------------------------------------------------------------------
		
		//@include breakpoint(large) {
		//	.content-container > .inside {
		//		.background { width : 66.66667%; }
		//	}
		//}
	}
}

.page_type-intro-small .link_enter-wrapper {
	display : none;
}

.ios ce_rsce-hero.hero-2-000-04 {
	
	@include breakpoint(large) {
		height : calc(85vh - 78px);
		
		> .inside {
			&.image,
			&.video {
				height : calc(85vh - 78px);
			}
			
			.video-item {
				height : calc(85vh - 78px);
			}
			
			.video-container {
				height : calc(85vh - 78px);
			}
			
			video {
				height : calc(85vh - 78px);
			}
		}
	}
}
