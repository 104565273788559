/* ===================================================================================================================
   RSCE 1-035 - CE Item - Image Teaser (Template: rsce_1_035_image-teaser.html5)
   ================================================================================================================ */

.ce_item-image-teaser {
	position: relative;
}

.ce_item-image-teaser a{
	position: relative;
	display: block;
	height: 100%;
	box-shadow: $global-shadow;
	margin-bottom: $margin-medium;
}

.ce_item-image-teaser img {

	width: 100%;
	//display: none;
	@include breakpoint(medium) {
		display: block;
	}
}

.ce_item-image-teaser .title {
	display: block;
	text-transform: uppercase;
	background-color: $light-gray;
	position: relative;
	width: 100%;
	padding: 15px;
	font-size: rem-calc(18);
	height: auto;
	margin-bottom: 0;
	transition: background-color 0.2s, color 0.2s ease;
	@include breakpoint(medium) {
		//position : absolute;
	}
	
}
.ce_item-image-teaser h3:after {
	content: none;
	
}

.ce_item-image-teaser:hover .title {
	
	background-color: darken($secondary-color,2%);
	color: $white;
}
.ce_item-image-teaser .hover {
	height: 100%;
	width: 100%;
	display: block;
	position: absolute;
	top:0;
	background-color: rgba($primary-color,0);
	transition: background-color 0.2s ease;
	
}
.ce_item-image-teaser:hover .hover {
	
	background-color: rgba($primary-color,0.2);
	
	
}

//.ce_item-image-teaser:hover  .hover:after {
//	@include icon(arrow-right3);
//	color: $white;
//	position: absolute;
//	font-size: rem-calc(25);
//	top: calc(48% - 10px);
//	left: calc(50% - 10px);
//}

//.ce_item-image-teaser  .title:after {
//	@include icon(arrow-right4);
//
//	position: relative;
//	margin-right: 15px;
//	//top: 15px;
//	font-size: rem-calc(18);
//
//}
