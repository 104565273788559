/* ===================================================================================================================
   Tabs
   ================================================================================================================ */


.tabs-panel.is-active ,
.is-active .accordion-content{
	
	display: block !important;
}
.tabs-title.is-active > a,
.is-active .accordion-title,
.tabs-title:hover a,
.accordion-title:hover,
.accordion-title:focus,
.accordion-title:active
{
	background: darken($light-gray, 5%);
	color: $primary-color;
}



//.tabs {
//	//text-align: center;
//}
//.text-center .tabs li {
//	display: inline-block;
//	float: none;
//}

.ce_item-tabs {

	max-width: 100% ;
	flex: 0 0 100%;
}

.tabs-content {
	
	padding-top: $padding-medium;
}
.page_type-system .tabs-title {
	text-align: center;
	width: 25%;
}

