/* ===================================================================================================================
   Page Layout
   ================================================================================================================ */
html {
	height                      : 100%;
	// Disable Tab Highlight Color on Webkit Engines
	-webkit-tap-highlight-color : rgba(255, 255, 255, 0);
}

body { height  : 100%;
	overflow-x : hidden;
}

// Klasse wird via JS getriggert und verhindert das Inhalte im Hintergrund scrollen
// Im Einsatz z.B. bei aktiver Navigation Fullscreen von CodyHouse oder Nivo Lightbox
body.overflow-hidden { overflow : hidden; }

/* ===================================================================================================================
   Typography - Global
   ================================================================================================================ */

h1, h2, h3 {
	line-height : 1;
	hyphens     : none;
	font-weight : $font-weight-1;
}

h1 {
	padding-top : 0.5rem;
	font-weight : $font-weight-2;
}

.ce_text.item-lp h1 {
	
	max-width      : 45rem;
	margin         : 0 auto;
	padding-top    : 0;
	padding-bottom : 3rem;
	font-size      : rem-calc(32);
	text-transform : uppercase;
	
	@include breakpoint(medium) { font-size : rem-calc(38); }
	
	&:after {
		content        : '';
		border-bottom  : 2px solid $secondary-color;
		width          : 60px;
		height         : 2px;
		display        : block;
		padding-top    : 12px;
		padding-bottom : 0.25rem;
		margin         : 0 auto;
	}
}

h2 {
	text-transform : uppercase;
	padding-bottom : 3rem;
	max-width      : 45rem;
	//margin         : 0 auto;
}

.text-center h2 {
	margin : 0 auto;
}

h3 {
	font-weight    : $font-weight-2;
	padding-bottom : 0;
}

.bg-color-lightgrey h3 {
	text-transform : uppercase;
}

h4 {
	//display        : block;
	//	font-weight: $font-weight-3;
	letter-spacing : $letter-spacing-large;
	width          : 100%;
	margin-top     : $margin-large;
	text-align     : center;
	padding-bottom : 3.5rem;
	color          : $color-2;
	text-transform : uppercase;
	display        : table;
	white-space    : nowrap;
	
	&:before, &:after {
		border-top : 1px solid $color-2;
		content    : '';
		display    : table-cell;
		position   : relative;
		top        : 0.6em;
		width      : 45%;
	}
	
	&:before { right : 1.2%; }
	
	&:after { left : 1.2%; }
	
}

.bg-color-lightgrey h4 {
	margin-top : 0;
}

h3 {
	padding-bottom : 1rem;
	padding-top    : 1rem;
}

.page_content .ce_headline h2:after,
.page_content .ce_text h2:after {
	content        : '';
	border-bottom  : 2px solid $secondary-color;
	width          : 60px;
	height         : 2px;
	display        : block;
	padding-top    : 12px;
	padding-bottom : 0.25rem;
	margin         : 0 auto;
}

.item_imprint,
.item_privacy-policy {
	h2 {
		padding-bottom : 1rem;
		padding-top    : 1rem;
		font-size      : rem-calc(24);
		font-weight    : $font-weight-3;
		text-transform : none;
		@include breakpoint(medium) {
			font-size : rem-calc(26);
		}
		
		&:after {
			display    : none !important;
			visibility : hidden !important;
		}
	}
	
	a {
		word-break : break-all;
		word-wrap  : break-word;
	}
}

//.text-center h2:after {
//	margin : 0 auto;
//}

p {
	hyphens : auto;
	@include breakpoint(medium) {
		hyphens : none;
	}
}

p.lead {
	
	//margin-bottom: $margin-large;
}

.button,
a.button {
	//font-size   : rem-calc(19);
	font-weight    : 600;
	text-transform : uppercase;
	border         : 2px solid $medium-gray;
	
	&:hover {
		color : $white;
	}
}

cite:before {
	content : '';
}

cite, p.small {
	color       : darken($medium-gray, 10%);
	//color: $medium-gray;
	font-style  : normal;
	font-weight : bold;
	font-size   : rem-calc(14);
}

.text-center ul {
	list-style-position : inside;
	margin-left         : 0;
}

ul.lead {
	list-style    : none;
	margin-top    : 0;
	margin-bottom : 0;
	font-weight   : $font-weight-3;
	margin-left   : 0;
	@include breakpoint(large) {
		//margin-top: 1.5em;
		margin-bottom : 1.5em;
		padding-left  : 15px;
		font-size     : 125%;
		line-height   : 1.6;
	}
	
	li {
		//font-family: $secondary-font;
		position       : relative;
		margin-bottom  : 1em;
		//font-weight: bold;
		//color: $color-2;
		padding-bottom : 1rem;
		border-bottom  : 1px solid $light-gray;
		@include breakpoint(large) {
			padding-bottom : 0;
			border-bottom  : 0 solid $light-gray;
			margin-bottom  : 0.5em;
			margin-left    : 5px;
		}
	}
	
	li:before {
		@include icon(checkmark4);
		position       : relative;
		color          : $secondary-color;
		display        : block;
		padding-bottom : 0.25rem;
		@include breakpoint(large) {
			display        : inline;
			position       : absolute;
			margin-left    : -25px;
			top            : 6px;
			padding-bottom : 0;
		}
	}
}

.anchor-list {
	list-style : none;
}

.anchor-list li a:before {
	list-style    : none;
	@include icon(arrow-down3);
	font-size     : rem-calc(12);
	padding-right : 5px;
}

.anchor-list li a:hover {
	color : $secondary-color;
}

// -------------------------------------------------------------------------------------------------------------------
// Responsive Sticky Footer - Start
// -------------------------------------------------------------------------------------------------------------------
.page_wrapper {
	display : table;
	width   : 100%;
	height  : 100%;
}

.page_header,
.page_teaser,
.page_footer,
.page_content-wrapper-outside { display : table-row; }

.page_content-wrapper-outside { height : 100%; }

// -------------------------------------------------------------------------------------------------------------------
// Responsive Sticky Footer - Stop
// -------------------------------------------------------------------------------------------------------------------
.page_column-wrapper {
	position     : relative;
	display      : table;
	table-layout : fixed;
	width        : 100%;
	height       : 100%;
}

// -------------------------------------------------------------------------------------------------------------------
// Page Layout: 0-1-0
// -------------------------------------------------------------------------------------------------------------------
// page-column-1 = main content
// -------------------------------------------------------------------------------------------------------------------
.page_layout-0-1-0 .page_column-1 {
	width  : 100%;
	height : 100%;
}

// TODO-PF: Eventuell Austausch durch Grid System (Float oder Flex)
// -------------------------------------------------------------------------------------------------------------------
// Page Layout: 3-1-0 | Unit-Mix: No | Allowed Units: % | px |(r)em
// If linearized, the sidebar content drops below the main content.
// -------------------------------------------------------------------------------------------------------------------
// page_column-1 => main content | page_column-3 => sidebar content (left)
// -------------------------------------------------------------------------------------------------------------------
@include breakpoint(large) {
	.page_layout-3-1-0 .page_column-1 { float : right; width : 75%; }
	.page_layout-3-1-0 .page_column-3 { margin : 0 75% 0 0; }
	
	.page_layout-3-1-0 .page_content { margin-left : rem-calc(20); }
	.page_layout-3-1-0 .page_sidebar { margin-right : rem-calc(20); }
}

// -------------------------------------------------------------------------------------------------------------------
// Layout variant with fixed sidebar.
// -------------------------------------------------------------------------------------------------------------------
// This layout variant requires that 'page_column-1' isn't completely empty. IE needs at least dummy content (&nbsp;),
// otherwise it doesn't render the element and the alignment of the sidebar within the parents padding area fails.
// -------------------------------------------------------------------------------------------------------------------
//@include breakpoint(large) {
//	.page_layout-3-1-0 .page_column-wrapper {
//		display      : block;
//		width        : auto;
//		overflow     : hidden;
//		padding-left : 250px; // column width
//	}
//
//	.page_layout-3-1-0 .page_column-1 {
//		float    : left;
//		width    : 100%;
//		position : relative;
//	}
//
//	.page_layout-3-1-0 .page_column-3 {
//		float    : left;
//		width    : 250px; // column width
//		right    : 250px; // column width
//		position : relative;
//		margin   : 0 0 0 -100%;
//	}
//
//	.page_layout-3-1-0 .page_content { margin-left : rem-calc(20); }
//	.page_layout-3-1-0 .page_sidebar { margin-right : rem-calc(20); }
//}
// -------------------------------------------------------------------------------------------------------------------
// Page Layout: 0-1-3 | Unit-Mix: No | Allowed Units: % | px |(r)em
// If linearized, the sidebar content drops below the main content.
// -------------------------------------------------------------------------------------------------------------------
// page_column-1 => main content | page_column-3 => sidebar content (right)
// -------------------------------------------------------------------------------------------------------------------
//@include breakpoint(large) {
//	.page_layout-0-1-3 .page_column-1 { float : left; width : 66.666%; }
//	.page_layout-0-1-3 .page_column-3 { margin : 0 0 0 66.666%; }
//
//	.page_layout-0-1-3 .page_content { margin-right : rem-calc(20); }
//	.page_layout-0-1-3 .page_sidebar { margin-left : rem-calc(20); }
//}

// -------------------------------------------------------------------------------------------------------------------
// Layout variant with fixed sidebar.
// -------------------------------------------------------------------------------------------------------------------
// This layout variant requires that 'page-column-1' isn't completely empty. IE needs at least dummy content (&nbsp;),
// otherwise it doesn't render the element and the alignment of the sidebar within the parents padding area fails.
// -------------------------------------------------------------------------------------------------------------------
//@include breakpoint(large) {
//	.page_layout-0-1-3 .page_column-wrapper {
//		display       : block;
//		width         : auto;
//		overflow      : hidden;
//		padding-right : 250px; // column width
//	}
//
//	.page_layout-0-1-3 .page_column-1 {
//		float    : left;
//		width    : 100%;
//		position : relative;
//	}
//
//	.page_layout-0-1-3 .page_column-3 {
//		float    : left;
//		width    : 250px; // column width
//		position : relative;
//		margin   : 0 -250px 0 0; // column width
//	}
//
//	.page_layout-0-1-3 .page_content { margin-right : rem-calc(20); }
//	.page_layout-0-1-3 .page_sidebar { margin-left : rem-calc(20); }
//}
// -------------------------------------------------------------------------------------------------------------------
// Page Layout: 1-3-0 | Unit-Mix: No | Allowed Units: % | px |(r)em
// If linearized, the main content drops below the sidebar content.
// -------------------------------------------------------------------------------------------------------------------
// page_column-1 => sidebar content (left) | page_column-3 => main content
// -------------------------------------------------------------------------------------------------------------------
@include breakpoint(large) {
	.page_layout-1-3-0 .page_column-1 { float : left; width : 25%; }
	.page_layout-1-3-0 .page_column-3 { margin : 0 0 0 25%; }
	
	.page_layout-1-3-0 .page_content { margin-left : rem-calc(20); }
	.page_layout-1-3-0 .page_sidebar { margin-right : rem-calc(20); }
}

// -------------------------------------------------------------------------------------------------------------------
// Page Layout: 0-3-1 | Unit-Mix: No | Allowed Units: % | px |(r)em
// If linearized, the main content drops below the sidebar content.
// -------------------------------------------------------------------------------------------------------------------
// page_column-1 => sidebar content (right) | page_column-3 => main content
// -------------------------------------------------------------------------------------------------------------------
//@include breakpoint(large) {
//	.page_layout-0-3-1 .page_column-1 { float : right; width : 33.333%; }
//	.page_layout-0-3-1 .page_column-3 { margin : 0 33.333% 0 0; }
//
//	.page_layout-0-3-1 .page_content { margin-right : rem-calc(20); }
//	.page_layout-0-3-1 .page_sidebar { margin-left : rem-calc(20); }
//}
// -------------------------------------------------------------------------------------------------------------------
// Page Layout: 2-1-3 | Unit-Mix: No | Allowed Units: % | px
// If linearized, the sidebar content drops below the main content.
// -------------------------------------------------------------------------------------------------------------------
// page_column-1 => main content | page_column-2 => sidebar content (left) | page_column-3 => sidebar content (right)
// -------------------------------------------------------------------------------------------------------------------
//@include breakpoint(large) {
//	.page_layout-2-1-3 .page_column-1 { width : 50%; float : left; margin-left : 25%; }
//	.page_layout-2-1-3 .page_column-2 { width : 25%; float : left; margin-left : -75%; }
//	.page_layout-2-1-3 .page_column-3 { width : 25%; float : right; margin-left : -5px; margin-right : 0; }
//
//	.page_layout-2-1-3 .page_content { margin : 0 rem-calc(20); }
//	.page_layout-2-1-3 .page_sidebar { margin-right : rem-calc(20); }
//	.page_layout-2-1-3 .page_sidebar-right { margin-left : rem-calc(20); }
//}
/* ===================================================================================================================
   Page Main Elements - Header, Teaser, Content, Sidebar, Footer
   ================================================================================================================ */
// Page Header
// -------------------------------------------------------------------------------------------------------------------
.page_header {
	background-color : $white;
	z-index          : 1000 !important;
	position         : absolute;
	width            : 100%;
	//height           : rem-calc(119);
	height           : rem-calc(78);
	
	@include breakpoint(large) {
		position : relative;
		//height   : rem-calc(78);
	}
	
}

.page_header .columns {
	padding-left  : 0;
	padding-right : 0;
	@include breakpoint(large) {
		padding-left  : 1.25rem;
		padding-right : 1.25rem;
	}
	
}

.page_header .mod_boxes4ward {
	position : relative;
}

.page_header .boxes4ward_article.first {
	box-shadow       : 2px 2px 4px rgba($black, 0.3);
	z-index          : 1000;
	background-color : $white;
}

.page_header .boxes4ward_article.first {
	position : relative;
	width    : 100%;
	@include breakpoint(large) {
		position : fixed;
	}
}

.page_header .logo_company {
	margin     : $margin-small 0;
	text-align : center;
	@include breakpoint(large) {
		text-align : left;
	}
}

.page_header .logo_company img {
	max-width : 180px;
	width     : 100%;
}

// -------------------------------------------------------------------------------------------------------------------
// Page Teaser
// -------------------------------------------------------------------------------------------------------------------
.page_teaser { }

// -------------------------------------------------------------------------------------------------------------------
// Page Content
// -------------------------------------------------------------------------------------------------------------------
.page_content-wrapper { }

.page_column-wrapper { }

// -------------------------------------------------------------------------------------------------------------------
//.page_content {
//	text-align : center;
//	@include breakpoint(large) {
//		text-align : left;
//	}
//}

.page_content .ce_image figcaption {
	margin-top    : 0.5em;
	margin-bottom : 2em;
}

// -------------------------------------------------------------------------------------------------------------------
// Page Sidebar
// -------------------------------------------------------------------------------------------------------------------
.page_sidebar { }

// -------------------------------------------------------------------------------------------------------------------
// Page Footer
// -------------------------------------------------------------------------------------------------------------------

.page_footer {
	//margin-top: $margin-large;
	display    : block;
	color      : $body-font-color;
	background : darken($light-gray, 10%);
	//font-weight: $font-weight-3;
	position   : relative;
	z-index    : 2;
	//background-color: darken($color-2,50%);
}

.page_type-home .page_footer,
.page_type-company-10-steps .page_footer,
.page_type-concept .page_footer,
.page_type-show-house .page_footer,
.page_type-balance .page_footer {
	margin-top : 0;
}

.page_footer .boxes4ward_article .row:first-of-type {
	padding-top    : 50px;
	padding-bottom : $padding-medium;
}

.page_footer .boxes4ward_article .row:first-of-type .ce_text {
	margin-bottom : $margin-medium;
}

.page_footer .boxes4ward_article .row:last-of-type {
	padding-bottom : $padding-small;
}

.page_footer .boxes4ward_article h2 {
	//text-transform : uppercase;
	font-size      : rem-calc(22);
	font-weight    : $font-weight-2;
	padding-bottom : 0.25rem;
	padding-top    : 0.25rem;
}

.page_footer .boxes4ward_article p,
.page_footer .boxes4ward_article li {
	//font-size : rem-calc(14);
}

.page_footer .boxes4ward_article a {
	//color : $body-font-color;
}

.page_footer .boxes4ward_article ul {
	//margin-left : 0;
}

.page_footer .boxes4ward_article li {
	list-style     : none;
	position       : relative;
	padding-bottom : 0.25rem;
}

.page_footer .boxes4ward_article li a {
	color           : $body-font-color;
	text-decoration : none;
	margin-left     : 0;
}

.page_footer .boxes4ward_article li a:before {
	@include icon(arrow-right3);
	position    : absolute;
	margin-left : -18px;
	top         : 4px;
	color       : $secondary-color;
	display     : inline;
	font-size   : rem-calc(12);
}

.page_footer .boxes4ward_article li a[href$=".pdf"]:before {
	@include icon(file-pdf);
	top : 2px;
}

.page_footer .boxes4ward_article .row.footerbar {
	padding-top    : 1rem;
	padding-bottom : 1rem;
}

.page_footer .boxes4ward_article .row.footerbar .ce_text {
	margin-bottom : 0;
}

.page_footer .boxes4ward_article .footerbar p,
.page_footer .boxes4ward_article .footerbar a {
	font-size  : rem-calc(14);
	text-align : center;
}

.page_footer .boxes4ward_article .ce_row img {
	margin-bottom : $margin-small;
}

.page_footer .boxes4ward_article a.button {
	background-color : transparent;
	border           : 2px solid darken($medium-gray, 10%);
	transition       : background-color 0.25s ease-out 0s, border 0.25s ease-out 0s;
}

.page_footer .boxes4ward_article a.button:hover {
	background-color : $secondary-color;
	border           : 2px solid $secondary-color;
}

.page_footer .footerbar {
	//background-color : $medium-gray;
	padding-top    : $padding-small;
	padding-bottom : $padding-small;
	text-align     : center;
}

.page_footer .boxes4ward_article .footerbar p {
	//color         : darken($color-2, 50%);
	margin-bottom : 0;
}

.page_footer .boxes4ward_article .footerbar a {
	//color           : darken($color-2, 50%);
	text-decoration : underline;
	position        : relative;
}

.page_footer .boxes4ward_article .icon-map {
	text-decoration : none;
	margin-top      : 0.75em;
	position        : relative;
	display         : block;
}

.page_footer .boxes4ward_article .icon-map:before {
	@include icon(location3);
	margin-right : 2px;
	color        : $secondary-color;
}

.toplink-wrapper {
	width            : 100%;
	position         : fixed;
	padding-top      : 1rem;
	z-index          : 99;
	background-color : $secondary-color;
	@include breakpoint(large) {
		//height : 50px;
		//margin-right: 8.5em;
	}
}

a.toplink, a.kontaktlink {
	font-size        : rem-calc(18);
	//background-color: rgba($white, 1);
	height           : 36px;
	width            : 36px;
	position         : fixed;
	color            : $white;
	display          : block;
	z-index          : 50;
	right            : 20px;
	bottom           : -10%;
	padding-top      : 8px;
	padding-left     : 9px;
	background-color : $secondary-color;
	transition       : bottom 0.5s ease;
	@include icon(arrow-up3, before) {
		margin-right : 0;
	}
	//@include breakpoint(medium) {
	//	font-size    : rem-calc(36);
	//	height       : 48px;
	//	width        : 48px;
	//	padding-top  : 0;
	//	padding-left : 6px;
	//}
	
	&:hover, &:focus {
		color : $white;
	}
	
}

a.kontaktlink {
	
	@include icon(at-sign, before) {
		margin-right : 0;
	}
	
}

.start-scroll .toplink {
	bottom : 20px;
}

.start-scroll .kontaktlink {
	bottom : 60px;
	//@include breakpoint(medium) {
	//	bottom : 80px;
	//}
}

.page_type-company-10-steps .toplink {
	display : none;
}

// -------------------------------------------------------------------------------------------------------------------

.social {
	margin-bottom : 30px;
	
	.inside {}
	
	.icon {
		
		&:after {
			color     : $black;
			font-size : 32px;
		}
		
		&.facebook:after { @include icon(custom-facebook); }
		
		&.twitter:after { @include icon(custom-twitter); }
		
		&.instagram:after { @include icon(custom-instagram); }
		
		&.linkedin:after { @include icon(custom-linkedin); }
		
		&.pinterest:after { @include icon(custom-pinterest); }
		
		&.xing:after { @include icon(custom-xing); }
		
		&.youtube:after { @include icon(custom-youtube); }
	}
}

/* ===================================================================================================================
   Content Type Elements
   ================================================================================================================ */
// .content_type-[xxx]
// ...
// -------------------------------------------------------------------------------------------------------------------

.content_type-icon-text .float_above {
	margin-bottom : 0;
	margin-top    : $margin-large;
}

.content_type-icon-text h3 {
	margin-bottom  : 0;
	padding-bottom : 0;
	text-transform : uppercase;
}

.content_type-icon-text h3:after {
	border-bottom : 0;
	padding-top   : 0;
}

.content_type-icon-text figure {
	position : relative;
}

.content_type-icon-text img {
	background-color : $white;
	position         : relative;
	z-index          : 2;
}

.content_type-icon-text figure:before {
	content       : '';
	position      : absolute;
	border-bottom : 1px solid $color-2;
	width         : 100%;
	left          : 0;
	top           : 40px;
	z-index       : 1;
}

.content_type-icon-text .img-circle figure:before {
	content : none;
}

.content_type-icon-text .img-circle .float_above {
	margin-bottom : $margin-small;
	margin-top    : $margin-small;
}

.content_type-icon-small h3 {
	display : none;
	@include breakpoint(medium) {
		display : block;
	}
}

.content_type-icon-small p:first-of-type {
	display : none;
	@include breakpoint(medium) {
		display : block;
	}
}

.content_type-icon-small figure {
	float      : left;
	width      : 10%;
	margin-top : 4px;
	@include breakpoint(medium) {
		float      : none;
		width      : auto;
		margin-top : 0;
	}
}

.content_type-icon-small a {
	width : 87%;
	float : right;
	@include breakpoint(medium) {
		width : auto;
		float : none;
	}
	
}

.content_type-logos figure {
	opacity : 0.3;
	@include breakpoint(medium) {
		width       : 80%;
		margin-left : 10%;
	}
}

.content_type-logos figure:before {
	content : none;
}

/* ===================================================================================================================
   Beilharz 10 Steps
   ================================================================================================================ */
.page_type-company-10-steps .mod_article.first .row {
}

.page_type-company-10-steps .mod_breadcrumb {
	text-align : center;
}

.page_type-company-10-steps .page_content-wrapper-outside {
	background-image  : url('../img/bg_page-company-10-steps_light.png');
	background-repeat : repeat;
}

.page_type-company-10-steps .pagebg-container {
	background-image : none !important;
}

.page_type-company-10-steps .page_content .ce_image .image_container {
	text-align : center;
}

.page_type-company-10-steps .ce_item-content-wrapper .row {
	padding-top    : $padding-medium;
	padding-bottom : $padding-medium;
	max-width      : 57rem !important;
	
	@include breakpoint(large) {
		//padding-top    : $padding-xlarge;
		//padding-bottom : $padding-xlarge;
		
		padding-top    : 0;
		padding-bottom : 0;
	}
}

.page_type-company-10-steps #section1.ce_item-content-wrapper .row {
	padding-top : $padding-large;
	@include breakpoint(large) {
		padding-top : 180px;
		//padding-bottom : $padding-xlarge;
		
	}
}

.arrow-10-steps-right,
.arrow-10-steps-left {
	display : none;
	@include breakpoint(large) {
		display : block;
	}
}

.arrow-10-steps-right img {
	float         : right;
	position      : relative;
	right         : 20%;
	width         : 90px;
	margin-top    : 0;
	margin-bottom : $margin-large;
}

.arrow-10-steps-left img {
	float         : left;
	position      : relative;
	left          : 20%;
	width         : 90px;
	margin-top    : 0;
	margin-bottom : $margin-large;
}

.page_type-company-10-steps #section10 {
	
	//padding-top    : $padding-large;
	padding-bottom : $padding-xlarge;
}

/* ===================================================================================================================
   Sonstiges
   ================================================================================================================ */
// -------------------------------------------------------------------------------------------------------------------

a.map {
	@include icon(location, before);
	text-decoration : none !important;
}

a.map:before {
	font-size    : rem-calc(27);
	margin-right : 5px;
}

a[href$=".pdf"] {
	//text-decoration: none !important;
	@include icon(file-pdf, before);
	margin-left : 20px;
	display     : inline-block;
}

a[href$=".pdf"]:hover {
	//text-decoration: underline !important;
	
}

a[href$=".pdf"]:before {
	margin-left     : -20px;
	margin-top      : 3px;
	text-decoration : none !important;
	position        : absolute;
}

#google_map, #google_map2, #google_map3 {
	height : 250px;
	@include breakpoint(medium) {
		height : 350px;
	}
}

#google_map3 {
	//margin-bottom : 2em;
}

.mod_article .content_box-below h2 {
	font-size   : 1.5rem !important;
	font-weight : $font-weight-4;
}

.mod_article .content_box-right h2 {
	//font-weight    : $font-weight-4 !important;
	//padding-bottom : 0;
	//line-height    : 1.4;
}

.page_type-concept .expanded {
	//background-image  : url('../img/bg_page-company-10-steps_light.png');
	//background-repeat : repeat;
	background-color : $light-gray;
	padding-top      : $padding-large;
	padding-bottom   : $padding-medium;
}

.cta-teaser-box {
	margin-bottom : 30px;
}

.cta-teaser-box h2 {
	font-weight : $font-weight-3;
	font-size   : rem-calc(32);
	padding-top : 0;
	margin-top  : 0;
}

.cta-teaser-box h3 {
	font-size   : rem-calc(30);
	font-weight : $font-weight-2;
	padding-top : 0;
	margin-top  : 0;
}

.cta-teaser-box h3:after {
	content : none;
}

.cta-teaser-box a {
	background-color : $light-gray;
	background-color : $secondary-color;
	color            : $white;
	display          : block;
	padding          : 15px;
	height           : 100%;
	width            : 100%;
	position         : relative;
	transition       : background-color 0.25s ease-out 0s;
}

.cta-teaser-box a:hover {
	background-color : darken($secondary-color, 5%);
}

.cta-teaser-box a:after {
	@include icon(arrow-right3);
	position  : absolute;
	right     : 10px;
	bottom    : 15px;
	color     : $white;
	display   : inline;
	font-size : rem-calc(22);
}

.cta-teaser-box p {
	
	//@include secondary-font;
	
	font-weight : $font-weight-3;
}

//Teaser Box

.teaser_box {
	display    : none;
	visibility : hidden;
	@include breakpoint(medium) {
		display    : block;
		visibility : visible;
	}
	color      : $white;
	text-align : right;
	position   : relative;
	//margin-bottom: -30px !important;
	top        : 150px;
	right      : -5px;
	z-index    : 999;
	height     : 0;
	overflow   : visible;
	@include breakpoint(large) {
		margin-bottom : 0 !important;
		top           : 100px;
	}
	
}

.teaser-box-inner {
	max-width : 300px;
	display   : inline-block;
	position  : relative;
	top       : 0;
	transform : rotate(-3deg);
	@include breakpoint(xlarge) {
		position : absolute;
		right    : 0;
	}
	
}

.teaser-box-inner p {
	background-color : $secondary-color;
	font-size        : rem-calc(20);
	color            : $white;
	border           : 0 solid $primary-color;
	padding          : 15px 15px 15px 15px;
	z-index          : 1000;
	line-height      : 1;
	text-transform   : uppercase;
	z-index          : 99;
	transition       : background-color 0.2s linear;
	box-shadow       : 3px 3px 4px rgba($dark-gray, 0.3);
	//text-align: center;
	
}

.teaser-box-inner p:hover {
	background-color : darken($secondary-color, 8%);
}

.teaser-box-inner a {
	color           : $white;
	text-decoration : none !important;
	display         : inline-block;
	//padding-bottom  : 0.5em;
}

.teaser-box-inner a:last-child {
	padding-bottom : 0;
}

.teaser_box.teaser_musterhaus .teaser-box-inner {
	max-width : none;
	width     : 360px;
}

.teaser_box.teaser_musterhaus .teaser-box-inner a {
	display    : block;
	text-align : left;
}

.teaser_box.teaser_musterhaus .teaser-box-inner a:before {
	content           : '';
	background-image  : url(../img/musterhauspark.svg);
	background-repeat : no-repeat;
	background-size   : 90px;
	height            : 90px;
	width             : 90px;
	display           : inline-block;
	position          : relative;
	float             : left;
	margin-right      : 5px;
}

.teaser_box.teaser_musterhaus strong {
	display     : block;
	font-weight : $font-weight-3;
}


