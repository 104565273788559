/* ===================================================================================================================
   Settings - Fonts
   ================================================================================================================ */
// Einbindung eigener Schriften (Ablage unter 'files/theme/fonts')
// Einstellungen von Variablen '$font-weight-x' in 'settings-project' beachten/anpassen
// -------------------------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------------------------
// Icon Font
// -------------------------------------------------------------------------------------------------------------------
// Einbindung eigener Schriften (Ablage unter 'files/theme/fonts')
// -------------------------------------------------------------------------------------------------------------------

$icon-font   : 'Icons';
@font-face {
	font-family : $icon-font;
	src         : url('../fonts/icon-beilharz-haus.woff2') format('woff2'),
	url('../fonts/icon-beilharz-haus.woff') format('woff'),
	url('../fonts/icon-beilharz-haus.svg') format('svg');
	font-weight : normal;
	font-style  : normal;
}

// -------------------------------------------------------------------------------------------------------------------
$icon-margin : 10px;

$icons       : (
		music3: "\e94c",
		bell2: "\ea58",
		custom-facebook: "\e902",
		custom-instagram: "\e903",
		custom-linkedin: "\e905",
		custom-pinterest: "\e906",
		custom-twitter: "\e907",
		custom-xing: "\e908",
		custom-youtube: "\e909",
		home: "\e900",
		home2: "\e901",
		home5: "\e904",
		newspaper: "\e90b",
		magazine: "\e90c",
		image: "\e93b",
		image2: "\e93c",
		image4: "\e941",
		image5: "\e942",
		camera: "\e944",
		camera2: "\e945",
		play: "\e957",
		film3: "\e960",
		bullhorn: "\e979",
		megaphone: "\e97a",
		new: "\e97b",
		connection: "\e97c",
		file-text3: "\e9ae",
		file-zip2: "\e9ba",
		file-spreadsheet2: "\e9c6",
		price-tag2: "\e9ee",
		price-tags: "\e9ef",
		price-tag3: "\e9f0",
		price-tags2: "\e9f1",
		price-tag4: "\e9f2",
		price-tags3: "\e9f3",
		price-tag5: "\e9f4",
		price-tags4: "\e9f5",
		cart4: "\ea01",
		cart5: "\ea02",
		coin-euro: "\ea0c",
		credit-card: "\ea15",
		credit-card2: "\ea16",
		calculator2: "\ea18",
		phone: "\ea1c",
		phone2: "\ea1d",
		phone-wave: "\ea1f",
		at-sign: "\ea2f",
		envelop: "\ea30",
		envelop2: "\ea31",
		envelop3: "\ea32",
		location: "\ea38",
		location2: "\ea39",
		location3: "\ea3a",
		location4: "\ea3b",
		location5: "\ea3c",
		location6: "\ea3d",
		calendar2: "\ea60",
		calendar3: "\ea61",
		calendar4: "\ea62",
		printer4: "\ea6a",
		bubble6: "\ead4",
		quotes-left: "\eb1b",
		quotes-right: "\eb1c",
		quotes-left2: "\eb1d",
		quotes-right2: "\eb1e",
		search: "\eb30",
		search3: "\eb36",
		lamp7: "\ebf9",
		lamp8: "\ebfa",
		clipboard: "\ec50",
		clipboard2: "\ec51",
		clipboard3: "\ec52",
		clipboard4: "\ec53",
		clipboard5: "\ec54",
		clipboard6: "\ec55",
		more: "\ec5b",
		more2: "\ec5c",
		grid: "\ec5d",
		grid2: "\ec5e",
		grid3: "\ec5f",
		grid4: "\ec60",
		grid5: "\ec61",
		grid6: "\ec62",
		menu: "\ec6a",
		circle-small: "\ec6b",
		menu3: "\ec6d",
		menu4: "\ec6e",
		menu5: "\ec6f",
		menu6: "\ec70",
		menu7: "\ec71",
		menu8: "\ec72",
		menu9: "\ec73",
		menu10: "\ec74",
		link: "\ec99",
		link2: "\ec9b",
		link3: "\ec9d",
		link5: "\eca1",
		star-empty2: "\ece1",
		star-full: "\ece2",
		star-empty3: "\ece3",
		thumbs-up: "\ecf4",
		thumbs-up2: "\ecf5",
		thumbs-down: "\ecf6",
		thumbs-down2: "\ecf7",
		warning: "\ed4f",
		warning2: "\ed50",
		notification: "\ed51",
		notification2: "\ed52",
		question3: "\ed55",
		question4: "\ed56",
		plus: "\ed59",
		minus: "\ed5a",
		plus2: "\ed5b",
		minus2: "\ed5c",
		plus3: "\ed5d",
		minus3: "\ed5e",
		plus-circle: "\ed5f",
		minus-circle: "\ed60",
		plus-circle2: "\ed61",
		minus-circle2: "\ed62",
		info: "\ed63",
		info2: "\ed64",
		cancel-circle: "\ed65",
		cancel-circle2: "\ed66",
		cancel-square: "\ed68",
		cancel-square2: "\ed69",
		cancel: "\ed6a",
		cross: "\ed6c",
		cross2: "\ed6d",
		cross3: "\ed6e",
		checkmark: "\ed6f",
		checkmark2: "\ed70",
		checkmark3: "\ed71",
		checkmark4: "\ed72",
		checkmark5: "\ed73",
		enter: "\ed76",
		exit: "\ed77",
		play4: "\ed85",
		volume-medium: "\ed90",
		arrow-up: "\edb7",
		arrow-up2: "\edb8",
		arrow-up3: "\edb9",
		arrow-up4: "\edba",
		arrow-right: "\edbb",
		arrow-right2: "\edbc",
		arrow-right3: "\edbd",
		arrow-right4: "\edbe",
		arrow-down: "\edbf",
		arrow-down2: "\edc0",
		arrow-down3: "\edc1",
		arrow-down4: "\edc2",
		arrow-left: "\edc3",
		arrow-left2: "\edc4",
		arrow-left3: "\edc5",
		arrow-left4: "\edc6",
		arrow-up5: "\edc7",
		arrow-right5: "\edc8",
		arrow-down5: "\edc9",
		arrow-left5: "\edca",
		arrow-up6: "\edcc",
		arrow-right6: "\edce",
		arrow-down6: "\edd0",
		arrow-left6: "\edd2",
		arrow-up7: "\edd4",
		arrow-right7: "\edd6",
		arrow-down7: "\edd8",
		arrow-left7: "\edda",
		arrow-up-left3: "\eddb",
		arrow-up8: "\eddc",
		arrow-up-right3: "\eddd",
		arrow-right8: "\edde",
		arrow-down-right3: "\eddf",
		arrow-down8: "\ede0",
		arrow-down-left3: "\ede1",
		arrow-left8: "\ede2",
		circle-up2: "\ede7",
		circle-right2: "\ede8",
		circle-down2: "\ede9",
		circle-left2: "\edea",
		circle-up3: "\edec",
		circle-right3: "\edee",
		circle-down3: "\edf0",
		circle-left3: "\edf2",
		circle-up4: "\edf4",
		circle-right4: "\edf6",
		circle-down4: "\edf8",
		circle-left4: "\edfa",
		square-up: "\ee04",
		square-right: "\ee06",
		square-down: "\ee08",
		square-left: "\ee0a",
		arrow-up9: "\ee0b",
		arrow-right9: "\ee0c",
		arrow-down9: "\ee0d",
		arrow-left9: "\ee0e",
		arrow-up12: "\ee1c",
		arrow-right12: "\ee1e",
		arrow-down12: "\ee20",
		arrow-left12: "\ee22",
		arrow-up14: "\ee27",
		arrow-right14: "\ee28",
		arrow-down14: "\ee29",
		arrow-left14: "\ee2a",
		circle-up5: "\ee2b",
		circle-right5: "\ee2c",
		circle-down5: "\ee2d",
		circle-left5: "\ee2e",
		circle-up6: "\ee2f",
		circle-right6: "\ee30",
		circle-down6: "\ee31",
		circle-left6: "\ee32",
		arrow-up15: "\ee33",
		arrow-right15: "\ee34",
		arrow-down15: "\ee35",
		arrow-left15: "\ee36",
		arrow-up16: "\ee37",
		arrow-right16: "\ee38",
		arrow-down16: "\ee39",
		arrow-left16: "\ee3a",
		checkbox-checked: "\ee66",
		checkbox-unchecked: "\ee67",
		checkbox-partial: "\ee68",
		square: "\ee69",
		diamond3: "\ee6c",
		diamond4: "\ee6d",
		checkbox-checked2: "\ee6e",
		checkbox-unchecked2: "\ee6f",
		radio-checked: "\ee71",
		radio-checked2: "\ee72",
		radio-unchecked: "\ee73",
		checkmark-circle: "\ee76",
		circle: "\ee77",
		circle2: "\ee78",
		new-tab: "\eec9",
		new-tab2: "\eeca",
		share3: "\eee0",
		amazon: "\eee6",
		google2: "\eee8",
		google-plus: "\eeea",
		google-plus2: "\eeeb",
		facebook: "\eeef",
		facebook2: "\eef0",
		twitter: "\eef5",
		youtube: "\eefc",
		youtube2: "\eefd",
		xing: "\ef32",
		xing2: "\ef33",
		file-pdf: "\ef3e"
);
// -------------------------------------------------------------------------------------------------------------------
i,
%icon,
[class^="icon_"],
[class*=" icon_"] {
	// Use !important to prevent issues with browser extensions that change fonts
	font-family             : $icon-font !important;
	speak                   : none;
	font-style              : normal;
	font-weight             : normal;
	font-variant            : normal;
	text-transform          : none;
	line-height             : 1;
	-webkit-font-smoothing  : antialiased;
	-moz-osx-font-smoothing : grayscale;
}

// -------------------------------------------------------------------------------------------------------------------
@mixin icon($icon, $position:"") {
	@if $position == "" {
		@extend %icon;
		content : '#{map-get($icons, $icon)}';
		@content;
	} @else if $position == both {
		&:before {
			@extend %icon;
			content      : '#{map-get($icons, $icon)}';
			margin-right : $icon-margin;
			@content;
		}
		&:after {
			@extend %icon;
			content     : '#{map-get($icons, $icon)}';
			margin-left : $icon-margin;
			@content;
		}
	} @else if $position == before {
		&:before {
			@extend %icon;
			content      : '#{map-get($icons, $icon)}';
			margin-right : $icon-margin;
			@content;
		}
	} @else if $position == after {
		&:after {
			@extend %icon;
			content     : '#{map-get($icons, $icon)}';
			margin-left : $icon-margin;
			@content;
		}
	}
}

// -------------------------------------------------------------------------------------------------------------------
// Beispiel Anwendung 01:
// -------------------------------------------------------------------------------------------------------------------
//.foo {
//  @include icon([Icon Name], [Position: before, after, both oder leer lassen]);
//}
// Ergänzung um individuelle Formatierungen
//.foo {
//	@include icon([Icon Name], [Position: before, after, both oder leer lassen]) {
//		margin-left: rem-calc(20);
//		color: blue;
//		...
//	}
//}
// -------------------------------------------------------------------------------------------------------------------
// Beispiel Anwendung 02:
// -------------------------------------------------------------------------------------------------------------------
//.foo {
//  &:after {
//     @include icon([Icon Name]);
//     font-size: rem-calc(20);
//     color: red;
//     ...
//  }
//}
// -------------------------------------------------------------------------------------------------------------------
// Setup a class name for each icon
@each $name, $char in $icons {
	.icon_#{$name} {
		content : $char;
	}
}
