/* ===================================================================================================================
   Background Images
   ================================================================================================================ */
@mixin parallax {
	background-position : 40% center;
	background-repeat   : no-repeat;
	background-size     : cover;
	height              : 100vh;
	position            : fixed;
	top                 : 0;
	width               : 100vw;
	
	@include breakpoint(medium) {
		background-position : center center;
	}
}

.pagebg-container {
	@include parallax;
	background-image   : url('../img/background_unternemen.jpg');
	transition         : filter 0.5s linear;
	@include mui-animation(fade(in));
	animation-duration : 1s;
}

.blur .pagebg-container {
	filter : blur(8px);
}

.page_type-form-feedback .pagebg-container,
.page_type-imprint .pagebg-container {
	background-image : none;
}

.page_type-home .pagebg-container {
	background-image : url('../../beilharz/Home/rotator.php');
}

.page_type-contact .pagebg-container {
	background-image : url('../img/background_leaf.jpg');
}

.page_type-philosophy .pagebg-container {
	background-image : url('../img/background-philosophy.jpg');
}

.page_type-system .pagebg-container {
	background-image : url('../img/background-bausystem2.jpg');
}

.page_type-voehringen .pagebg-container {
	background-image : url('../img/background_musterhaus_avance_150.jpg');
}

.page_type-fellbach .pagebg-container {
	background-image : url('../img/background_fellbach.jpg');
}

// Architektenhäuser

.page_type-architect-house-overview .pagebg-container {
	background-image    : url('../img/background_architektenhaeuser.jpg');
	background-position : 64% center;
}

.page_type-architect-house-772419 .pagebg-container {
	background-image : url('../img/background_772419.jpg');
}

.page_type-architect-house-772413 .pagebg-container {
	background-image : url('../img/background_772413.jpg');
}

.page_type-architect-house-772411 .pagebg-container {
	background-image : url('../img/background_772411.jpg');
}

.page_type-architect-house-772367 .pagebg-container {
	background-image    : url('../img/background_772367.jpg');
	background-position : 82% center;
}

.page_type-architect-house-772342 .pagebg-container {
	background-image    : url('../img/background_772342.jpg');
	background-position : 26% center;
}

.page_type-architect-house-772330 .pagebg-container {
	background-image : url('../img/background_772330.jpg');
}

.page_type-architect-house-772321 .pagebg-container {
	background-image : url('../img/background_772321.jpg');
}

.page_type-architect-house-772313 .pagebg-container {
	background-image : url('../img/background_772313.jpg');
}

.page_type-architect-house-772306 .pagebg-container {
	background-image    : url('../img/background_772306.jpg');
	background-position : 15% center;
}

.page_type-architect-house-772214 .pagebg-container {
	background-image : url('../img/background_772214.jpg');
}

.page_type-architect-house-772140 .pagebg-container {
	background-image : url('../img/background_772140.jpg');
}

.page_type-architect-house-772083 .pagebg-container {
	background-image : url('../img/background_772083.jpg');
}

.page_type-architect-house-772051 .pagebg-container {
	background-image    : url('../img/background_772051.jpg');
	background-position : 15% center;
}

.page_type-architect-house-772032 .pagebg-container {
	background-image    : url('../img/background_772032.jpg');
	background-position : 83% center;
}

.page_type-architect-house-771795 .pagebg-container {
	background-image    : url('../img/background_771795.jpg');
	background-position : 52% center;
}

.page_type-architect-house-772576 .pagebg-container {
	background-image : url('../img/background_772576.jpg');
}

.page_type-architect-house-772398 .pagebg-container {
	background-image : url('../img/background_772398.jpg');
}

.page_type-architect-house-772453 .pagebg-container {
	background-image : url('../img/background_772453.jpg');
}

.page_type-architect-house-772460 .pagebg-container {
	background-image : url('../img/background_772460.jpg');
}

.page_type-architect-house-772597 .pagebg-container {
	background-image : url('../img/background_772597.jpg');
}

.page_type-architect-house-772473 .pagebg-container {
	background-image : url('../img/background_772473.jpg');
}

.page_type-architect-house-772606 .pagebg-container {
	background-image : url('../img/background_772606.jpg');
}

.page_type-architect-house-772614 .pagebg-container {
	background-image : url('../img/background_772614.jpg');
}

.page_type-architect-house-772638 .pagebg-container {
	background-image : url('../img/background_772638.jpg');
}

.page_type-architect-house-772651 .pagebg-container {
	background-image : url('../img/background_772651.jpg');
}

.page_type-architect-house-772440 .pagebg-container {
	background-image : url('../img/background_772440.jpg');
}

.page_type-architect-house-772593 .pagebg-container {
	background-image : url('../img/background_772593.jpg');
}

.page_type-architect-house-772630 .pagebg-container {
	background-image : url('../img/background_772630.jpg');
}

.page_type-architect-house-772634 .pagebg-container {
	background-image : url('../img/background_772634.jpg');
}

// Modulhäuser

.page_type-module-house-772586 .pagebg-container {
	background-image    : url('../img/background_772586.jpg');
	background-position : 56% center;
}

.page_type-module-house-pure .pagebg-container {
	background-image    : url('../img/background_pure.jpg');
	background-position : 56% center;
}

.page_type-module-house-balance .pagebg-container {
	background-image    : url('../img/background_balance.jpg');
	background-position : 56% center;
}

.page_type-module-house-overview .pagebg-container {
	background-image    : url('../img/background_modulhaeuser.jpg');
	background-position : 56% center;
}

.page_type-module-house-772337 .pagebg-container {
	background-image : url('../img/background_772337.jpg');
}

.page_type-module-house-772369 .pagebg-container {
	background-image    : url('../img/background_772369.jpg');
	background-position : 52% center;
}

.page_type-module-house-772340 .pagebg-container {
	background-image : url('../img/background_772340.jpg');
}

.page_type-module-house-772311 .pagebg-container {
	background-image    : url('../img/background_772311.jpg');
	background-position : 76% center;
}

.page_type-module-house-772242 .pagebg-container {
	background-image : url('../img/background_772242.jpg');
}

.page_type-module-house-772241 .pagebg-container {
	background-image : url('../img/background_772241.jpg');
}

.page_type-module-house-772226 .pagebg-container {
	background-image    : url('../img/background_772226.jpg');
	background-position : 97% center;
}

.page_type-module-house-772153 .pagebg-container {
	background-image    : url('../img/background_772153.jpg');
	background-position : 76% center;
}

.page_type-module-house-772145 .pagebg-container {
	background-image    : url('../img/background_772145.jpg');
	background-position : 65% center;
}

.page_type-module-house-772465 .pagebg-container {
	background-image : url('../img/background_772465.jpg');
}

.page_type-module-house-772565 .pagebg-container {
	background-image : url('../img/background_772565.jpg');
}

.page_type-module-house-772529 .pagebg-container {
	background-image : url('../img/background_772529.jpg');
}

.page_type-module-house-772545 .pagebg-container {
	background-image : url('../img/background_772545.jpg');
}

.page_type-modulbox .pagebg-container {
	background-image : url('../img/background-modulbox.jpg');
}

.page_type-module-house-772648 .pagebg-container {
	background-image : url('../img/background_772648.jpg');
}

// Pure

.page_type-module-house-772457 .pagebg-container {
	background-image : url('../img/background_772457.jpg');
}

.page_type-module-house-772446 .pagebg-container {
	background-image : url('../img/background_772446.jpg');
}

.page_type-module-house-772558 .pagebg-container {
	background-image : url('../img/background_772558.jpg');
}

.page_type-module-house-772454 .pagebg-container {
	background-image : url('../img/background_772454.jpg');
}

//----

// Sonstiges

.page_type-care .pagebg-container {
	background-image : url('../img/background-pflegetipps.jpg');
}

//----

// Landingpages

.page_type-landingpage-1 .pagebg-container {
	background-image : url('../img/background_lp_oekologischbauen.jpg');
}

.page_type-landingpage-2 .pagebg-container {
	background-image : url('../img/background_lp_individuellerhausbau.jpg');
}

.page_type-landingpage-3 .pagebg-container {
	background-image : url('../img/background_lp_musterhauspark.jpg');
}

.page_type-landingpage-4 .pagebg-container {
	background-image : url('../img/background_lp_zimmerer.jpg');
}

//----

.page_type-intro-small .pagebg-container {
	//height: 60vh;
	
}

