@charset "UTF-8";
/*!
======================================================================================================================
   Projekt: Beilharz Haus - WS Firma
   -------------------------------------------------------------------------------------------------------------------
   Werbeagentur Neubert GmbH - https://werbeagentur-neubert.de + Contao Service - https://contao-service.de
======================================================================================================================
*/
/* ===================================================================================================================
   Settings - Fonts
   ================================================================================================================ */
@font-face {
  font-family: "Icons";
  src: url("../fonts/icon-beilharz-haus.woff2") format("woff2"), url("../fonts/icon-beilharz-haus.woff") format("woff"), url("../fonts/icon-beilharz-haus.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

i,
.button.button-secondary:after,
.page_content .ce_download a[href$=".pdf"]:before,
.page_content .ce_download a[href$=".doc"]:before,
.page_content .ce_download a[href$=".docx"]:before,
.page_content .ce_download a[href$=".word"]:before,
.page_content .ce_downloads a[href$=".pdf"]:before,
.page_content .ce_downloads a[href$=".doc"]:before,
.page_content .ce_downloads a[href$=".docx"]:before,
.page_content .ce_downloads a[href$=".word"]:before,
.page_content .ce_download a[href$=".png"]:before,
.page_content .ce_download a[href$=".jpg"]:before,
.page_content .ce_download a[href$=".svg"]:before,
.page_content .ce_download a[href$=".bmp"]:before,
.page_content .ce_download a[href$=".gif"]:before,
.page_content .ce_download a[href$=".tif"]:before,
.page_content .ce_downloads a[href$=".png"]:before,
.page_content .ce_downloads a[href$=".jpg"]:before,
.page_content .ce_downloads a[href$=".svg"]:before,
.page_content .ce_downloads a[href$=".bmp"]:before,
.page_content .ce_downloads a[href$=".gif"]:before,
.page_content .ce_downloads a[href$=".tif"]:before,
.page_content .ce_download a[href$=".zip"]:before,
.page_content .ce_downloads a[href$=".zip"]:before,
.page_content .ce_download a[href$=".xl"]:before,
.page_content .ce_download a[href$=".xls"]:before,
.page_content .ce_download a[href$=".xlsx"]:before,
.page_content .ce_downloads a[href$=".xl"]:before,
.page_content .ce_downloads a[href$=".xls"]:before,
.page_content .ce_downloads a[href$=".xlsx"]:before,
.page_content .ce_download a[href$=".mp4"]:before,
.page_content .ce_downloads a[href$=".mp4"]:before,
.page_content .ce_download a[href$=".mov"]:before,
.page_content .ce_download a[href$=".avi"]:before,
.page_content .ce_download a[href$=".wmv"]:before,
.page_content .ce_downloads a[href$=".mov"]:before,
.page_content .ce_downloads a[href$=".avi"]:before,
.page_content .ce_downloads a[href$=".wmv"]:before,
ul.lead li:before,
.anchor-list li a:before,
.page_footer .boxes4ward_article li a:before,
.page_footer .boxes4ward_article li a[href$=".pdf"]:before,
.page_footer .boxes4ward_article .icon-map:before,
a.toplink:before,
a.kontaktlink:before,
.social .icon.facebook:after,
.social .icon.twitter:after,
.social .icon.instagram:after,
.social .icon.linkedin:after,
.social .icon.pinterest:after,
.social .icon.xing:after,
.social .icon.youtube:after,
a.map:before,
a[href$=".pdf"]:before,
.cta-teaser-box a:after,
.cd-nav-trigger:after,
.cd-nav-trigger.open:after,
.dropdown.menu.vertical.dropdown > li.is-dropdown-submenu-parent > a:after,
.is-accordion-submenu-parent > a:after,
.ce_caroufredsel_gallery .caroufredsel_navi a.caroufredsel_prev:before,
.ce_caroufredsel_gallery .caroufredsel_navi a.caroufredsel_next:before,
.ce_caroufredsel_gallery .image_container a:before,
.ce_gallery .image_container a:before,
.content_type-modulbox-form .ui-accordion-header-icon:before,
.content_type-modulbox-form .active .ui-accordion-header-icon:before,
.widget-upload .file-upload:after,
.mod_booknav li.prev a:before,
.mod_booknav li.next a:after,
#nav-modal .secondary-menu li a:before,
.ce_item-intro-text a.link_enter:before,
.ce_item-intro-text-box a.link_enter:before,
.ce_rsce-hero.hero-2-000-04 > .inside .content-container .link_enter-wrapper a.link_enter:before,
.ce_rsce-hero.hero-2-000-05 > .inside .content-container .content-box .cta-container p:before,
.item_youtube-button a:after,
.ce_rsce-hero.hero-2-000-06 > .inside .content-container .section-1 .cta-container p:before,
[class^="icon_"],
[class*=" icon_"] {
  font-family: "Icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon_music3 {
  content: "";
}

.icon_bell2 {
  content: "";
}

.icon_custom-facebook {
  content: "";
}

.icon_custom-instagram {
  content: "";
}

.icon_custom-linkedin {
  content: "";
}

.icon_custom-pinterest {
  content: "";
}

.icon_custom-twitter {
  content: "";
}

.icon_custom-xing {
  content: "";
}

.icon_custom-youtube {
  content: "";
}

.icon_home {
  content: "";
}

.icon_home2 {
  content: "";
}

.icon_home5 {
  content: "";
}

.icon_newspaper {
  content: "";
}

.icon_magazine {
  content: "";
}

.icon_image {
  content: "";
}

.icon_image2 {
  content: "";
}

.icon_image4 {
  content: "";
}

.icon_image5 {
  content: "";
}

.icon_camera {
  content: "";
}

.icon_camera2 {
  content: "";
}

.icon_play {
  content: "";
}

.icon_film3 {
  content: "";
}

.icon_bullhorn {
  content: "";
}

.icon_megaphone {
  content: "";
}

.icon_new {
  content: "";
}

.icon_connection {
  content: "";
}

.icon_file-text3 {
  content: "";
}

.icon_file-zip2 {
  content: "";
}

.icon_file-spreadsheet2 {
  content: "";
}

.icon_price-tag2 {
  content: "";
}

.icon_price-tags {
  content: "";
}

.icon_price-tag3 {
  content: "";
}

.icon_price-tags2 {
  content: "";
}

.icon_price-tag4 {
  content: "";
}

.icon_price-tags3 {
  content: "";
}

.icon_price-tag5 {
  content: "";
}

.icon_price-tags4 {
  content: "";
}

.icon_cart4 {
  content: "";
}

.icon_cart5 {
  content: "";
}

.icon_coin-euro {
  content: "";
}

.icon_credit-card {
  content: "";
}

.icon_credit-card2 {
  content: "";
}

.icon_calculator2 {
  content: "";
}

.icon_phone {
  content: "";
}

.icon_phone2 {
  content: "";
}

.icon_phone-wave {
  content: "";
}

.icon_at-sign {
  content: "";
}

.icon_envelop {
  content: "";
}

.icon_envelop2 {
  content: "";
}

.icon_envelop3 {
  content: "";
}

.icon_location {
  content: "";
}

.icon_location2 {
  content: "";
}

.icon_location3 {
  content: "";
}

.icon_location4 {
  content: "";
}

.icon_location5 {
  content: "";
}

.icon_location6 {
  content: "";
}

.icon_calendar2 {
  content: "";
}

.icon_calendar3 {
  content: "";
}

.icon_calendar4 {
  content: "";
}

.icon_printer4 {
  content: "";
}

.icon_bubble6 {
  content: "";
}

.icon_quotes-left {
  content: "";
}

.icon_quotes-right {
  content: "";
}

.icon_quotes-left2 {
  content: "";
}

.icon_quotes-right2 {
  content: "";
}

.icon_search {
  content: "";
}

.icon_search3 {
  content: "";
}

.icon_lamp7 {
  content: "";
}

.icon_lamp8 {
  content: "";
}

.icon_clipboard {
  content: "";
}

.icon_clipboard2 {
  content: "";
}

.icon_clipboard3 {
  content: "";
}

.icon_clipboard4 {
  content: "";
}

.icon_clipboard5 {
  content: "";
}

.icon_clipboard6 {
  content: "";
}

.icon_more {
  content: "";
}

.icon_more2 {
  content: "";
}

.icon_grid {
  content: "";
}

.icon_grid2 {
  content: "";
}

.icon_grid3 {
  content: "";
}

.icon_grid4 {
  content: "";
}

.icon_grid5 {
  content: "";
}

.icon_grid6 {
  content: "";
}

.icon_menu {
  content: "";
}

.icon_circle-small {
  content: "";
}

.icon_menu3 {
  content: "";
}

.icon_menu4 {
  content: "";
}

.icon_menu5 {
  content: "";
}

.icon_menu6 {
  content: "";
}

.icon_menu7 {
  content: "";
}

.icon_menu8 {
  content: "";
}

.icon_menu9 {
  content: "";
}

.icon_menu10 {
  content: "";
}

.icon_link {
  content: "";
}

.icon_link2 {
  content: "";
}

.icon_link3 {
  content: "";
}

.icon_link5 {
  content: "";
}

.icon_star-empty2 {
  content: "";
}

.icon_star-full {
  content: "";
}

.icon_star-empty3 {
  content: "";
}

.icon_thumbs-up {
  content: "";
}

.icon_thumbs-up2 {
  content: "";
}

.icon_thumbs-down {
  content: "";
}

.icon_thumbs-down2 {
  content: "";
}

.icon_warning {
  content: "";
}

.icon_warning2 {
  content: "";
}

.icon_notification {
  content: "";
}

.icon_notification2 {
  content: "";
}

.icon_question3 {
  content: "";
}

.icon_question4 {
  content: "";
}

.icon_plus {
  content: "";
}

.icon_minus {
  content: "";
}

.icon_plus2 {
  content: "";
}

.icon_minus2 {
  content: "";
}

.icon_plus3 {
  content: "";
}

.icon_minus3 {
  content: "";
}

.icon_plus-circle {
  content: "";
}

.icon_minus-circle {
  content: "";
}

.icon_plus-circle2 {
  content: "";
}

.icon_minus-circle2 {
  content: "";
}

.icon_info {
  content: "";
}

.icon_info2 {
  content: "";
}

.icon_cancel-circle {
  content: "";
}

.icon_cancel-circle2 {
  content: "";
}

.icon_cancel-square {
  content: "";
}

.icon_cancel-square2 {
  content: "";
}

.icon_cancel {
  content: "";
}

.icon_cross {
  content: "";
}

.icon_cross2 {
  content: "";
}

.icon_cross3 {
  content: "";
}

.icon_checkmark {
  content: "";
}

.icon_checkmark2 {
  content: "";
}

.icon_checkmark3 {
  content: "";
}

.icon_checkmark4 {
  content: "";
}

.icon_checkmark5 {
  content: "";
}

.icon_enter {
  content: "";
}

.icon_exit {
  content: "";
}

.icon_play4 {
  content: "";
}

.icon_volume-medium {
  content: "";
}

.icon_arrow-up {
  content: "";
}

.icon_arrow-up2 {
  content: "";
}

.icon_arrow-up3 {
  content: "";
}

.icon_arrow-up4 {
  content: "";
}

.icon_arrow-right {
  content: "";
}

.icon_arrow-right2 {
  content: "";
}

.icon_arrow-right3 {
  content: "";
}

.icon_arrow-right4 {
  content: "";
}

.icon_arrow-down {
  content: "";
}

.icon_arrow-down2 {
  content: "";
}

.icon_arrow-down3 {
  content: "";
}

.icon_arrow-down4 {
  content: "";
}

.icon_arrow-left {
  content: "";
}

.icon_arrow-left2 {
  content: "";
}

.icon_arrow-left3 {
  content: "";
}

.icon_arrow-left4 {
  content: "";
}

.icon_arrow-up5 {
  content: "";
}

.icon_arrow-right5 {
  content: "";
}

.icon_arrow-down5 {
  content: "";
}

.icon_arrow-left5 {
  content: "";
}

.icon_arrow-up6 {
  content: "";
}

.icon_arrow-right6 {
  content: "";
}

.icon_arrow-down6 {
  content: "";
}

.icon_arrow-left6 {
  content: "";
}

.icon_arrow-up7 {
  content: "";
}

.icon_arrow-right7 {
  content: "";
}

.icon_arrow-down7 {
  content: "";
}

.icon_arrow-left7 {
  content: "";
}

.icon_arrow-up-left3 {
  content: "";
}

.icon_arrow-up8 {
  content: "";
}

.icon_arrow-up-right3 {
  content: "";
}

.icon_arrow-right8 {
  content: "";
}

.icon_arrow-down-right3 {
  content: "";
}

.icon_arrow-down8 {
  content: "";
}

.icon_arrow-down-left3 {
  content: "";
}

.icon_arrow-left8 {
  content: "";
}

.icon_circle-up2 {
  content: "";
}

.icon_circle-right2 {
  content: "";
}

.icon_circle-down2 {
  content: "";
}

.icon_circle-left2 {
  content: "";
}

.icon_circle-up3 {
  content: "";
}

.icon_circle-right3 {
  content: "";
}

.icon_circle-down3 {
  content: "";
}

.icon_circle-left3 {
  content: "";
}

.icon_circle-up4 {
  content: "";
}

.icon_circle-right4 {
  content: "";
}

.icon_circle-down4 {
  content: "";
}

.icon_circle-left4 {
  content: "";
}

.icon_square-up {
  content: "";
}

.icon_square-right {
  content: "";
}

.icon_square-down {
  content: "";
}

.icon_square-left {
  content: "";
}

.icon_arrow-up9 {
  content: "";
}

.icon_arrow-right9 {
  content: "";
}

.icon_arrow-down9 {
  content: "";
}

.icon_arrow-left9 {
  content: "";
}

.icon_arrow-up12 {
  content: "";
}

.icon_arrow-right12 {
  content: "";
}

.icon_arrow-down12 {
  content: "";
}

.icon_arrow-left12 {
  content: "";
}

.icon_arrow-up14 {
  content: "";
}

.icon_arrow-right14 {
  content: "";
}

.icon_arrow-down14 {
  content: "";
}

.icon_arrow-left14 {
  content: "";
}

.icon_circle-up5 {
  content: "";
}

.icon_circle-right5 {
  content: "";
}

.icon_circle-down5 {
  content: "";
}

.icon_circle-left5 {
  content: "";
}

.icon_circle-up6 {
  content: "";
}

.icon_circle-right6 {
  content: "";
}

.icon_circle-down6 {
  content: "";
}

.icon_circle-left6 {
  content: "";
}

.icon_arrow-up15 {
  content: "";
}

.icon_arrow-right15 {
  content: "";
}

.icon_arrow-down15 {
  content: "";
}

.icon_arrow-left15 {
  content: "";
}

.icon_arrow-up16 {
  content: "";
}

.icon_arrow-right16 {
  content: "";
}

.icon_arrow-down16 {
  content: "";
}

.icon_arrow-left16 {
  content: "";
}

.icon_checkbox-checked {
  content: "";
}

.icon_checkbox-unchecked {
  content: "";
}

.icon_checkbox-partial {
  content: "";
}

.icon_square {
  content: "";
}

.icon_diamond3 {
  content: "";
}

.icon_diamond4 {
  content: "";
}

.icon_checkbox-checked2 {
  content: "";
}

.icon_checkbox-unchecked2 {
  content: "";
}

.icon_radio-checked {
  content: "";
}

.icon_radio-checked2 {
  content: "";
}

.icon_radio-unchecked {
  content: "";
}

.icon_checkmark-circle {
  content: "";
}

.icon_circle {
  content: "";
}

.icon_circle2 {
  content: "";
}

.icon_new-tab {
  content: "";
}

.icon_new-tab2 {
  content: "";
}

.icon_share3 {
  content: "";
}

.icon_amazon {
  content: "";
}

.icon_google2 {
  content: "";
}

.icon_google-plus {
  content: "";
}

.icon_google-plus2 {
  content: "";
}

.icon_facebook {
  content: "";
}

.icon_facebook2 {
  content: "";
}

.icon_twitter {
  content: "";
}

.icon_youtube {
  content: "";
}

.icon_youtube2 {
  content: "";
}

.icon_xing {
  content: "";
}

.icon_xing2 {
  content: "";
}

.icon_file-pdf {
  content: "";
}

/* ===================================================================================================================
   Settings - Project
   ================================================================================================================ */
.secondary-font {
  font-family: 'Freehand521W01-Regular', cursive;
  letter-spacing: normal;
  letter-spacing: -0.02em;
}

p .secondary-font {
  font-size: 150%;
}

.img-circle img {
  border-radius: 50%;
}

@media print, screen and (min-width: 40em) {
  .img-circle img {
    max-width: 50%;
  }
}

.margin-y-large {
  margin-top: 30px !important;
  margin-bottom: 60px !important;
}

@media print, screen and (min-width: 40em) {
  .margin-y-large {
    margin-top: 80px !important;
    margin-bottom: 110px !important;
  }
}

.padding-none {
  padding: 0;
}

.padding-top {
  padding-top: 15px !important;
}

@media print, screen and (min-width: 40em) {
  .padding-top {
    padding-top: 30px !important;
  }
}

.padding-bottom {
  padding-bottom: 30px !important;
}

@media print, screen and (min-width: 40em) {
  .padding-bottom {
    padding-bottom: 80px !important;
  }
}

.button.button-secondary {
  position: relative;
  padding-right: 2.875rem;
  background: transparent;
  border: 1px solid #d1cec5;
  color: #d1cec5;
  font-size: 1rem;
  text-align: left;
  text-decoration: none;
}

.button.button-secondary:after {
  content: "";
  position: absolute;
  top: calc(50% - 7px);
  right: 1rem;
  font-size: 14px;
  color: #d1cec5;
}

.button.button-secondary.full-width {
  width: 100%;
}

.button.button-secondary:focus, .button.button-secondary:hover {
  background: #d1cec5;
  color: #111111;
}

.button.button-secondary:hover:after {
  color: #111111;
}

/**
 * Foundation for Sites by ZURB
 * Version 6.3.0
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0;
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

.foundation-mq {
  font-family: "small=0em&xsmall=30em&medium=40em&large=64em&xlarge=75em&xxlarge=100em";
}

html {
  box-sizing: border-box;
  font-size: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  background: #ffffff;
  font-family: "neuzeit-grotesk", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #2f3132;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0;
}

select {
  width: 100%;
  border-radius: 0;
}

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important;
}

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
}

[data-whatinput='mouse'] button {
  outline: 0;
}

.is-visible {
  display: block !important;
}

.is-hidden {
  display: none !important;
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

@media screen and (max-width: 29.9375em) {
  .hide-for-small-only {
    display: none !important;
  }
}

@media screen and (max-width: 0em), screen and (min-width: 30em) {
  .show-for-small-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 30em) {
  .hide-for-xsmall {
    display: none !important;
  }
}

@media screen and (max-width: 29.9375em) {
  .show-for-xsmall {
    display: none !important;
  }
}

@media screen and (min-width: 30em) and (max-width: 39.9375em) {
  .hide-for-xsmall-only {
    display: none !important;
  }
}

@media screen and (max-width: 29.9375em), screen and (min-width: 40em) {
  .show-for-xsmall-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important;
  }
}

@media screen and (min-width: 75em) {
  .hide-for-xlarge {
    display: none !important;
  }
}

@media screen and (max-width: 74.9375em) {
  .show-for-xlarge {
    display: none !important;
  }
}

@media screen and (min-width: 75em) and (max-width: 99.9375em) {
  .hide-for-xlarge-only {
    display: none !important;
  }
}

@media screen and (max-width: 74.9375em), screen and (min-width: 100em) {
  .show-for-xlarge-only {
    display: none !important;
  }
}

@media screen and (min-width: 100em) {
  .hide-for-xxlarge {
    display: none !important;
  }
}

@media screen and (max-width: 99.9375em) {
  .show-for-xxlarge {
    display: none !important;
  }
}

@media screen and (min-width: 100em) {
  .hide-for-xxlarge-only {
    display: none !important;
  }
}

@media screen and (max-width: 99.9375em) {
  .show-for-xxlarge-only {
    display: none !important;
  }
}

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
}

.show-for-landscape,
.hide-for-portrait {
  display: block !important;
}

@media screen and (orientation: landscape) {
  .show-for-landscape,
  .hide-for-portrait {
    display: block !important;
  }
}

@media screen and (orientation: portrait) {
  .show-for-landscape,
  .hide-for-portrait {
    display: none !important;
  }
}

.hide-for-landscape,
.show-for-portrait {
  display: none !important;
}

@media screen and (orientation: landscape) {
  .hide-for-landscape,
  .show-for-portrait {
    display: none !important;
  }
}

@media screen and (orientation: portrait) {
  .hide-for-landscape,
  .show-for-portrait {
    display: block !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.clearfix::before, .clearfix::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.clearfix::after {
  clear: both;
}

.align-right {
  justify-content: flex-end;
}

.align-center {
  justify-content: center;
}

.align-justify {
  justify-content: space-between;
}

.align-spaced {
  justify-content: space-around;
}

.align-top {
  align-items: flex-start;
}

.align-self-top {
  align-self: flex-start;
}

.align-bottom {
  align-items: flex-end;
}

.align-self-bottom {
  align-self: flex-end;
}

.align-middle {
  align-items: center;
}

.align-self-middle {
  align-self: center;
}

.align-stretch {
  align-items: stretch;
}

.align-self-stretch {
  align-self: stretch;
}

.small-order-1 {
  order: 1;
}

.small-order-2 {
  order: 2;
}

.small-order-3 {
  order: 3;
}

.small-order-4 {
  order: 4;
}

.small-order-5 {
  order: 5;
}

.small-order-6 {
  order: 6;
}

@media print, screen and (min-width: 30em) {
  .xsmall-order-1 {
    order: 1;
  }
  .xsmall-order-2 {
    order: 2;
  }
  .xsmall-order-3 {
    order: 3;
  }
  .xsmall-order-4 {
    order: 4;
  }
  .xsmall-order-5 {
    order: 5;
  }
  .xsmall-order-6 {
    order: 6;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1;
  }
  .medium-order-2 {
    order: 2;
  }
  .medium-order-3 {
    order: 3;
  }
  .medium-order-4 {
    order: 4;
  }
  .medium-order-5 {
    order: 5;
  }
  .medium-order-6 {
    order: 6;
  }
}

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1;
  }
  .large-order-2 {
    order: 2;
  }
  .large-order-3 {
    order: 3;
  }
  .large-order-4 {
    order: 4;
  }
  .large-order-5 {
    order: 5;
  }
  .large-order-6 {
    order: 6;
  }
}

@media screen and (min-width: 75em) {
  .xlarge-order-1 {
    order: 1;
  }
  .xlarge-order-2 {
    order: 2;
  }
  .xlarge-order-3 {
    order: 3;
  }
  .xlarge-order-4 {
    order: 4;
  }
  .xlarge-order-5 {
    order: 5;
  }
  .xlarge-order-6 {
    order: 6;
  }
}

@media screen and (min-width: 100em) {
  .xxlarge-order-1 {
    order: 1;
  }
  .xxlarge-order-2 {
    order: 2;
  }
  .xxlarge-order-3 {
    order: 3;
  }
  .xxlarge-order-4 {
    order: 4;
  }
  .xxlarge-order-5 {
    order: 5;
  }
  .xxlarge-order-6 {
    order: 6;
  }
}

.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}

.row .row {
  margin-right: -1.25rem;
  margin-left: -1.25rem;
}

@media print, screen and (min-width: 30em) {
  .row .row {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }
}

@media print, screen and (min-width: 40em) {
  .row .row {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }
}

@media print, screen and (min-width: 64em) {
  .row .row {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }
}

@media screen and (min-width: 75em) {
  .row .row {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }
}

@media screen and (min-width: 100em) {
  .row .row {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
  }
}

.row.expanded {
  max-width: none;
}

.row.collapse > .column, .row.collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}

.row.is-collapse-child,
.row.collapse > .column > .row,
.row.collapse > .columns > .row {
  margin-right: 0;
  margin-left: 0;
}

.column, .columns {
  flex: 1 1 0px;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  min-width: initial;
}

@media print, screen and (min-width: 40em) {
  .column, .columns {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.column.row.row, .row.row.columns {
  display: flex;
}

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.flex-container {
  display: flex;
}

.flex-child-auto {
  flex: 1 1 auto;
}

.flex-child-grow {
  flex: 1 0 auto;
}

.flex-child-shrink {
  flex: 0 1 auto;
}

.flex-dir-row {
  flex-direction: row;
}

.flex-dir-row-reverse {
  flex-direction: row-reverse;
}

.flex-dir-column {
  flex-direction: column;
}

.flex-dir-column-reverse {
  flex-direction: column-reverse;
}

.small-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.small-offset-0 {
  margin-left: 0%;
}

.small-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.small-offset-1 {
  margin-left: 8.33333%;
}

.small-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-offset-2 {
  margin-left: 16.66667%;
}

.small-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.small-offset-3 {
  margin-left: 25%;
}

.small-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.small-offset-4 {
  margin-left: 33.33333%;
}

.small-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-offset-5 {
  margin-left: 41.66667%;
}

.small-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.small-offset-6 {
  margin-left: 50%;
}

.small-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.small-offset-7 {
  margin-left: 58.33333%;
}

.small-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.small-offset-8 {
  margin-left: 66.66667%;
}

.small-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.small-offset-9 {
  margin-left: 75%;
}

.small-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.small-offset-10 {
  margin-left: 83.33333%;
}

.small-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-offset-11 {
  margin-left: 91.66667%;
}

.small-order-1 {
  order: 1;
}

.small-order-2 {
  order: 2;
}

.small-order-3 {
  order: 3;
}

.small-order-4 {
  order: 4;
}

.small-order-5 {
  order: 5;
}

.small-order-6 {
  order: 6;
}

.small-up-1 {
  flex-wrap: wrap;
}

.small-up-1 > .column, .small-up-1 > .columns {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-up-2 {
  flex-wrap: wrap;
}

.small-up-2 > .column, .small-up-2 > .columns {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-up-3 {
  flex-wrap: wrap;
}

.small-up-3 > .column, .small-up-3 > .columns {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.small-up-4 {
  flex-wrap: wrap;
}

.small-up-4 > .column, .small-up-4 > .columns {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-up-5 {
  flex-wrap: wrap;
}

.small-up-5 > .column, .small-up-5 > .columns {
  flex: 0 0 20%;
  max-width: 20%;
}

.small-up-6 {
  flex-wrap: wrap;
}

.small-up-6 > .column, .small-up-6 > .columns {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.small-up-7 {
  flex-wrap: wrap;
}

.small-up-7 > .column, .small-up-7 > .columns {
  flex: 0 0 14.28571%;
  max-width: 14.28571%;
}

.small-up-8 {
  flex-wrap: wrap;
}

.small-up-8 > .column, .small-up-8 > .columns {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}

@media print, screen and (min-width: 30em) {
  .xsmall-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .xsmall-offset-0 {
    margin-left: 0%;
  }
  .xsmall-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .xsmall-offset-1 {
    margin-left: 8.33333%;
  }
  .xsmall-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xsmall-offset-2 {
    margin-left: 16.66667%;
  }
  .xsmall-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .xsmall-offset-3 {
    margin-left: 25%;
  }
  .xsmall-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .xsmall-offset-4 {
    margin-left: 33.33333%;
  }
  .xsmall-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xsmall-offset-5 {
    margin-left: 41.66667%;
  }
  .xsmall-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .xsmall-offset-6 {
    margin-left: 50%;
  }
  .xsmall-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .xsmall-offset-7 {
    margin-left: 58.33333%;
  }
  .xsmall-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xsmall-offset-8 {
    margin-left: 66.66667%;
  }
  .xsmall-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .xsmall-offset-9 {
    margin-left: 75%;
  }
  .xsmall-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .xsmall-offset-10 {
    margin-left: 83.33333%;
  }
  .xsmall-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xsmall-offset-11 {
    margin-left: 91.66667%;
  }
  .xsmall-order-1 {
    order: 1;
  }
  .xsmall-order-2 {
    order: 2;
  }
  .xsmall-order-3 {
    order: 3;
  }
  .xsmall-order-4 {
    order: 4;
  }
  .xsmall-order-5 {
    order: 5;
  }
  .xsmall-order-6 {
    order: 6;
  }
  .xsmall-up-1 {
    flex-wrap: wrap;
  }
  .xsmall-up-1 > .column, .xsmall-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xsmall-up-2 {
    flex-wrap: wrap;
  }
  .xsmall-up-2 > .column, .xsmall-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xsmall-up-3 {
    flex-wrap: wrap;
  }
  .xsmall-up-3 > .column, .xsmall-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .xsmall-up-4 {
    flex-wrap: wrap;
  }
  .xsmall-up-4 > .column, .xsmall-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xsmall-up-5 {
    flex-wrap: wrap;
  }
  .xsmall-up-5 > .column, .xsmall-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xsmall-up-6 {
    flex-wrap: wrap;
  }
  .xsmall-up-6 > .column, .xsmall-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .xsmall-up-7 {
    flex-wrap: wrap;
  }
  .xsmall-up-7 > .column, .xsmall-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }
  .xsmall-up-8 {
    flex-wrap: wrap;
  }
  .xsmall-up-8 > .column, .xsmall-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}

@media print, screen and (min-width: 30em) and (min-width: 30em) {
  .xsmall-expand {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 30em) {
  .xsmall-flex-dir-row {
    flex-direction: row;
  }
  .xsmall-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .xsmall-flex-dir-column {
    flex-direction: column;
  }
  .xsmall-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
  .xsmall-flex-child-auto {
    flex: 1 1 auto;
  }
  .xsmall-flex-child-grow {
    flex: 1 0 auto;
  }
  .xsmall-flex-child-shrink {
    flex: 0 1 auto;
  }
}

.row.xsmall-unstack > .column, .row.xsmall-unstack > .columns {
  flex: 0 0 100%;
}

@media print, screen and (min-width: 30em) {
  .row.xsmall-unstack > .column, .row.xsmall-unstack > .columns {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 30em) {
  .xsmall-collapse > .column, .xsmall-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .xsmall-uncollapse > .column, .xsmall-uncollapse > .columns {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .medium-offset-0 {
    margin-left: 0%;
  }
  .medium-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .medium-offset-1 {
    margin-left: 8.33333%;
  }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-offset-2 {
    margin-left: 16.66667%;
  }
  .medium-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .medium-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .medium-offset-4 {
    margin-left: 33.33333%;
  }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-offset-5 {
    margin-left: 41.66667%;
  }
  .medium-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .medium-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .medium-offset-7 {
    margin-left: 58.33333%;
  }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .medium-offset-8 {
    margin-left: 66.66667%;
  }
  .medium-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .medium-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .medium-offset-10 {
    margin-left: 83.33333%;
  }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-offset-11 {
    margin-left: 91.66667%;
  }
  .medium-order-1 {
    order: 1;
  }
  .medium-order-2 {
    order: 2;
  }
  .medium-order-3 {
    order: 3;
  }
  .medium-order-4 {
    order: 4;
  }
  .medium-order-5 {
    order: 5;
  }
  .medium-order-6 {
    order: 6;
  }
  .medium-up-1 {
    flex-wrap: wrap;
  }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-up-2 {
    flex-wrap: wrap;
  }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-up-3 {
    flex-wrap: wrap;
  }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .medium-up-4 {
    flex-wrap: wrap;
  }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-up-5 {
    flex-wrap: wrap;
  }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .medium-up-6 {
    flex-wrap: wrap;
  }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .medium-up-7 {
    flex-wrap: wrap;
  }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }
  .medium-up-8 {
    flex-wrap: wrap;
  }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .medium-expand {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-flex-dir-row {
    flex-direction: row;
  }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .medium-flex-dir-column {
    flex-direction: column;
  }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
  .medium-flex-child-auto {
    flex: 1 1 auto;
  }
  .medium-flex-child-grow {
    flex: 1 0 auto;
  }
  .medium-flex-child-shrink {
    flex: 0 1 auto;
  }
}

.row.medium-unstack > .column, .row.medium-unstack > .columns {
  flex: 0 0 100%;
}

@media print, screen and (min-width: 40em) {
  .row.medium-unstack > .column, .row.medium-unstack > .columns {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media print, screen and (min-width: 64em) {
  .large-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .large-offset-0 {
    margin-left: 0%;
  }
  .large-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .large-offset-1 {
    margin-left: 8.33333%;
  }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-offset-2 {
    margin-left: 16.66667%;
  }
  .large-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .large-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .large-offset-4 {
    margin-left: 33.33333%;
  }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-offset-5 {
    margin-left: 41.66667%;
  }
  .large-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .large-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .large-offset-7 {
    margin-left: 58.33333%;
  }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .large-offset-8 {
    margin-left: 66.66667%;
  }
  .large-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .large-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .large-offset-10 {
    margin-left: 83.33333%;
  }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-offset-11 {
    margin-left: 91.66667%;
  }
  .large-order-1 {
    order: 1;
  }
  .large-order-2 {
    order: 2;
  }
  .large-order-3 {
    order: 3;
  }
  .large-order-4 {
    order: 4;
  }
  .large-order-5 {
    order: 5;
  }
  .large-order-6 {
    order: 6;
  }
  .large-up-1 {
    flex-wrap: wrap;
  }
  .large-up-1 > .column, .large-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-up-2 {
    flex-wrap: wrap;
  }
  .large-up-2 > .column, .large-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-up-3 {
    flex-wrap: wrap;
  }
  .large-up-3 > .column, .large-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .large-up-4 {
    flex-wrap: wrap;
  }
  .large-up-4 > .column, .large-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-up-5 {
    flex-wrap: wrap;
  }
  .large-up-5 > .column, .large-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .large-up-6 {
    flex-wrap: wrap;
  }
  .large-up-6 > .column, .large-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .large-up-7 {
    flex-wrap: wrap;
  }
  .large-up-7 > .column, .large-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }
  .large-up-8 {
    flex-wrap: wrap;
  }
  .large-up-8 > .column, .large-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}

@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .large-expand {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 64em) {
  .large-flex-dir-row {
    flex-direction: row;
  }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .large-flex-dir-column {
    flex-direction: column;
  }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
  .large-flex-child-auto {
    flex: 1 1 auto;
  }
  .large-flex-child-grow {
    flex: 1 0 auto;
  }
  .large-flex-child-shrink {
    flex: 0 1 auto;
  }
}

.row.large-unstack > .column, .row.large-unstack > .columns {
  flex: 0 0 100%;
}

@media print, screen and (min-width: 64em) {
  .row.large-unstack > .column, .row.large-unstack > .columns {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 64em) {
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media screen and (min-width: 75em) {
  .xlarge-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .xlarge-offset-0 {
    margin-left: 0%;
  }
  .xlarge-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .xlarge-offset-1 {
    margin-left: 8.33333%;
  }
  .xlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xlarge-offset-2 {
    margin-left: 16.66667%;
  }
  .xlarge-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .xlarge-offset-3 {
    margin-left: 25%;
  }
  .xlarge-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .xlarge-offset-4 {
    margin-left: 33.33333%;
  }
  .xlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xlarge-offset-5 {
    margin-left: 41.66667%;
  }
  .xlarge-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .xlarge-offset-6 {
    margin-left: 50%;
  }
  .xlarge-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .xlarge-offset-7 {
    margin-left: 58.33333%;
  }
  .xlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xlarge-offset-8 {
    margin-left: 66.66667%;
  }
  .xlarge-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .xlarge-offset-9 {
    margin-left: 75%;
  }
  .xlarge-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .xlarge-offset-10 {
    margin-left: 83.33333%;
  }
  .xlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xlarge-offset-11 {
    margin-left: 91.66667%;
  }
  .xlarge-order-1 {
    order: 1;
  }
  .xlarge-order-2 {
    order: 2;
  }
  .xlarge-order-3 {
    order: 3;
  }
  .xlarge-order-4 {
    order: 4;
  }
  .xlarge-order-5 {
    order: 5;
  }
  .xlarge-order-6 {
    order: 6;
  }
  .xlarge-up-1 {
    flex-wrap: wrap;
  }
  .xlarge-up-1 > .column, .xlarge-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xlarge-up-2 {
    flex-wrap: wrap;
  }
  .xlarge-up-2 > .column, .xlarge-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xlarge-up-3 {
    flex-wrap: wrap;
  }
  .xlarge-up-3 > .column, .xlarge-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .xlarge-up-4 {
    flex-wrap: wrap;
  }
  .xlarge-up-4 > .column, .xlarge-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xlarge-up-5 {
    flex-wrap: wrap;
  }
  .xlarge-up-5 > .column, .xlarge-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xlarge-up-6 {
    flex-wrap: wrap;
  }
  .xlarge-up-6 > .column, .xlarge-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .xlarge-up-7 {
    flex-wrap: wrap;
  }
  .xlarge-up-7 > .column, .xlarge-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }
  .xlarge-up-8 {
    flex-wrap: wrap;
  }
  .xlarge-up-8 > .column, .xlarge-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}

@media screen and (min-width: 75em) and (min-width: 75em) {
  .xlarge-expand {
    flex: 1 1 0px;
  }
}

@media screen and (min-width: 75em) {
  .xlarge-flex-dir-row {
    flex-direction: row;
  }
  .xlarge-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .xlarge-flex-dir-column {
    flex-direction: column;
  }
  .xlarge-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
  .xlarge-flex-child-auto {
    flex: 1 1 auto;
  }
  .xlarge-flex-child-grow {
    flex: 1 0 auto;
  }
  .xlarge-flex-child-shrink {
    flex: 0 1 auto;
  }
}

.row.xlarge-unstack > .column, .row.xlarge-unstack > .columns {
  flex: 0 0 100%;
}

@media screen and (min-width: 75em) {
  .row.xlarge-unstack > .column, .row.xlarge-unstack > .columns {
    flex: 1 1 0px;
  }
}

@media screen and (min-width: 75em) {
  .xlarge-collapse > .column, .xlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media screen and (min-width: 100em) {
  .xxlarge-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .xxlarge-offset-0 {
    margin-left: 0%;
  }
  .xxlarge-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .xxlarge-offset-1 {
    margin-left: 8.33333%;
  }
  .xxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-offset-2 {
    margin-left: 16.66667%;
  }
  .xxlarge-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .xxlarge-offset-3 {
    margin-left: 25%;
  }
  .xxlarge-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .xxlarge-offset-4 {
    margin-left: 33.33333%;
  }
  .xxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-offset-5 {
    margin-left: 41.66667%;
  }
  .xxlarge-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .xxlarge-offset-6 {
    margin-left: 50%;
  }
  .xxlarge-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .xxlarge-offset-7 {
    margin-left: 58.33333%;
  }
  .xxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xxlarge-offset-8 {
    margin-left: 66.66667%;
  }
  .xxlarge-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .xxlarge-offset-9 {
    margin-left: 75%;
  }
  .xxlarge-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .xxlarge-offset-10 {
    margin-left: 83.33333%;
  }
  .xxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-offset-11 {
    margin-left: 91.66667%;
  }
  .xxlarge-order-1 {
    order: 1;
  }
  .xxlarge-order-2 {
    order: 2;
  }
  .xxlarge-order-3 {
    order: 3;
  }
  .xxlarge-order-4 {
    order: 4;
  }
  .xxlarge-order-5 {
    order: 5;
  }
  .xxlarge-order-6 {
    order: 6;
  }
  .xxlarge-up-1 {
    flex-wrap: wrap;
  }
  .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-up-2 {
    flex-wrap: wrap;
  }
  .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-up-3 {
    flex-wrap: wrap;
  }
  .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .xxlarge-up-4 {
    flex-wrap: wrap;
  }
  .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-up-5 {
    flex-wrap: wrap;
  }
  .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xxlarge-up-6 {
    flex-wrap: wrap;
  }
  .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .xxlarge-up-7 {
    flex-wrap: wrap;
  }
  .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
  }
  .xxlarge-up-8 {
    flex-wrap: wrap;
  }
  .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}

@media screen and (min-width: 100em) and (min-width: 100em) {
  .xxlarge-expand {
    flex: 1 1 0px;
  }
}

@media screen and (min-width: 100em) {
  .xxlarge-flex-dir-row {
    flex-direction: row;
  }
  .xxlarge-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .xxlarge-flex-dir-column {
    flex-direction: column;
  }
  .xxlarge-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
  .xxlarge-flex-child-auto {
    flex: 1 1 auto;
  }
  .xxlarge-flex-child-grow {
    flex: 1 0 auto;
  }
  .xxlarge-flex-child-shrink {
    flex: 0 1 auto;
  }
}

.row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
  flex: 0 0 100%;
}

@media screen and (min-width: 100em) {
  .row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
    flex: 1 1 0px;
  }
}

@media screen and (min-width: 100em) {
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.shrink {
  flex: 0 0 auto;
  max-width: 100%;
}

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
}

em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: 900;
  line-height: inherit;
}

small {
  font-size: 80%;
  line-height: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "neuzeit-grotesk", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  line-height: 0;
  color: #d1cec5;
}

h1 {
  font-size: 2.375rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h2 {
  font-size: 2rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h3 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h4 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

@media print, screen and (min-width: 40em) {
  h1 {
    font-size: 3.75rem;
  }
  h2 {
    font-size: 2.375rem;
  }
  h3 {
    font-size: 1.625rem;
  }
  h4 {
    font-size: 1rem;
  }
  h5 {
    font-size: 1.25rem;
  }
  h6 {
    font-size: 1rem;
  }
}

a {
  line-height: inherit;
  color: #111111;
  text-decoration: none;
  cursor: pointer;
}

a:hover, a:focus {
  color: #0f0f0f;
}

a img {
  border: 0;
}

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #d1cec5;
  border-left: 0;
}

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.4;
}

li {
  font-size: inherit;
}

ul {
  margin-left: 1.25rem;
  list-style-type: disc;
}

ol {
  margin-left: 1.25rem;
}

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
}

dl {
  margin-bottom: 1rem;
}

dl dt {
  margin-bottom: 0.3rem;
  font-weight: bold;
}

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #d1cec5;
}

blockquote, blockquote p {
  line-height: 1.4;
  color: #212121;
}

cite {
  display: block;
  font-size: 0.8125rem;
  color: #212121;
}

cite:before {
  content: "— ";
}

abbr {
  border-bottom: 1px dotted #111111;
  color: #2f3132;
  cursor: help;
}

figure {
  margin: 0;
}

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #d1cec5;
  background-color: #f4f3f0;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #111111;
}

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #f4f3f0;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #111111;
}

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #212121;
}

.lead {
  font-size: 125%;
  line-height: 1.3;
}

.stat {
  font-size: 2.5rem;
  line-height: 1;
}

p + .stat {
  margin-top: -1rem;
}

.no-bullet {
  margin-left: 0;
  list-style: none;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media print, screen and (min-width: 30em) {
  .xsmall-text-left {
    text-align: left;
  }
  .xsmall-text-right {
    text-align: right;
  }
  .xsmall-text-center {
    text-align: center;
  }
  .xsmall-text-justify {
    text-align: justify;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left;
  }
  .medium-text-right {
    text-align: right;
  }
  .medium-text-center {
    text-align: center;
  }
  .medium-text-justify {
    text-align: justify;
  }
}

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left;
  }
  .large-text-right {
    text-align: right;
  }
  .large-text-center {
    text-align: center;
  }
  .large-text-justify {
    text-align: justify;
  }
}

@media screen and (min-width: 75em) {
  .xlarge-text-left {
    text-align: left;
  }
  .xlarge-text-right {
    text-align: right;
  }
  .xlarge-text-center {
    text-align: center;
  }
  .xlarge-text-justify {
    text-align: justify;
  }
}

@media screen and (min-width: 100em) {
  .xxlarge-text-left {
    text-align: left;
  }
  .xxlarge-text-right {
    text-align: right;
  }
  .xxlarge-text-center {
    text-align: center;
  }
  .xxlarge-text-justify {
    text-align: justify;
  }
}

.show-for-print {
  display: none !important;
}

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important;
  }
  .show-for-print {
    display: block !important;
  }
  .hide-for-print {
    display: none !important;
  }
  table.show-for-print {
    display: table !important;
  }
  thead.show-for-print {
    display: table-header-group !important;
  }
  tbody.show-for-print {
    display: table-row-group !important;
  }
  tr.show-for-print {
    display: table-row !important;
  }
  td.show-for-print {
    display: table-cell !important;
  }
  th.show-for-print {
    display: table-cell !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  pre,
  blockquote {
    border: 1px solid #212121;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  color: #a49e8c;
}

[data-whatinput='mouse'] .button {
  outline: 0;
}

.button:hover, .button:focus {
  background-color: #d1cec5;
  color: #a49e8c;
}

.button.tiny {
  font-size: 0.6rem;
}

.button.small {
  font-size: 0.75rem;
}

.button.large {
  font-size: 1.25rem;
}

.button.expanded {
  display: block;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

.button.primary {
  background-color: #111111;
  color: #a49e8c;
}

.button.primary:hover, .button.primary:focus {
  background-color: #0e0e0e;
  color: #a49e8c;
}

.button.secondary {
  background-color: #e2001a;
  color: #212121;
}

.button.secondary:hover, .button.secondary:focus {
  background-color: #b50015;
  color: #212121;
}

.button.success {
  background-color: #3adb76;
  color: #212121;
}

.button.success:hover, .button.success:focus {
  background-color: #22bb5b;
  color: #212121;
}

.button.warning {
  background-color: #ffae00;
  color: #212121;
}

.button.warning:hover, .button.warning:focus {
  background-color: #cc8b00;
  color: #212121;
}

.button.alert {
  background-color: #e2001a;
  color: #212121;
}

.button.alert:hover, .button.alert:focus {
  background-color: #b50015;
  color: #212121;
}

.button.hollow {
  border: 1px solid #111111;
  color: #111111;
}

.button.hollow, .button.hollow:hover, .button.hollow:focus {
  background-color: transparent;
}

.button.hollow:hover, .button.hollow:focus {
  border-color: #090909;
  color: #090909;
}

.button.hollow.primary {
  border: 1px solid #111111;
  color: #111111;
}

.button.hollow.primary:hover, .button.hollow.primary:focus {
  border-color: #090909;
  color: #090909;
}

.button.hollow.secondary {
  border: 1px solid #e2001a;
  color: #e2001a;
}

.button.hollow.secondary:hover, .button.hollow.secondary:focus {
  border-color: #71000d;
  color: #71000d;
}

.button.hollow.success {
  border: 1px solid #3adb76;
  color: #3adb76;
}

.button.hollow.success:hover, .button.hollow.success:focus {
  border-color: #157539;
  color: #157539;
}

.button.hollow.warning {
  border: 1px solid #ffae00;
  color: #ffae00;
}

.button.hollow.warning:hover, .button.hollow.warning:focus {
  border-color: #805700;
  color: #805700;
}

.button.hollow.alert {
  border: 1px solid #e2001a;
  color: #e2001a;
}

.button.hollow.alert:hover, .button.hollow.alert:focus {
  border-color: #71000d;
  color: #71000d;
}

.button.disabled, .button[disabled] {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled:hover, .button.disabled:focus, .button[disabled]:hover, .button[disabled]:focus {
  background-color: #111111;
  color: #a49e8c;
}

.button.disabled.primary, .button[disabled].primary {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary:hover, .button[disabled].primary:focus {
  background-color: #111111;
  color: #a49e8c;
}

.button.disabled.secondary, .button[disabled].secondary {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
  background-color: #e2001a;
  color: #a49e8c;
}

.button.disabled.success, .button[disabled].success {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success:hover, .button[disabled].success:focus {
  background-color: #3adb76;
  color: #a49e8c;
}

.button.disabled.warning, .button[disabled].warning {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning:hover, .button[disabled].warning:focus {
  background-color: #ffae00;
  color: #a49e8c;
}

.button.disabled.alert, .button[disabled].alert {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert:hover, .button[disabled].alert:focus {
  background-color: #e2001a;
  color: #a49e8c;
}

.button.dropdown::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4em;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #ffffff transparent transparent;
  position: relative;
  top: 0.4em;
  display: inline-block;
  float: right;
  margin-left: 1em;
}

.button.arrow-only::after {
  top: -0.1em;
  float: none;
  margin-left: 0;
}

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 0 solid #d1cec5;
  border-radius: 0;
  background-color: #e9e7e1;
  box-shadow: inset 0 1px 2px rgba(17, 17, 17, 0);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  color: #111111;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none;
}

[type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
textarea:focus {
  outline: none;
  border: 0 solid #212121;
  background-color: #dedbd3;
  box-shadow: 0 0 0 #d1cec5;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

textarea {
  max-width: 100%;
}

textarea[rows] {
  height: auto;
}

input::placeholder,
textarea::placeholder {
  color: #212121;
}

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #f4f3f0;
  cursor: not-allowed;
}

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0;
}

input[type='search'] {
  box-sizing: border-box;
}

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem;
}

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0;
}

[type='checkbox'] + label[for],
[type='radio'] + label[for] {
  cursor: pointer;
}

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem;
}

[type='file'] {
  width: 100%;
}

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #111111;
}

label.middle {
  margin: 0 0 1rem;
  padding: 0.5rem 0;
}

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #111111;
}

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  align-items: stretch;
}

.input-group > :first-child {
  border-radius: 0 0 0 0;
}

.input-group > :last-child > * {
  border-radius: 0 0 0 0;
}

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap;
}

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #d1cec5;
  background: #f4f3f0;
  color: #111111;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
}

.input-group-label:first-child {
  border-right: 0;
}

.input-group-label:last-child {
  border-left: 0;
}

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0;
}

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  flex: 0 0 auto;
}

.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  height: 2.5rem;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1rem;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  max-width: 100%;
  margin-bottom: 0.5rem;
}

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #d1cec5;
}

.fieldset legend {
  margin: 0;
  margin-left: -0.1875rem;
  padding: 0 0.1875rem;
  background: #ffffff;
}

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 0 solid #d1cec5;
  border-radius: 0;
  background-color: #e9e7e1;
  font-family: inherit;
  font-size: 1rem;
  line-height: normal;
  color: #111111;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%2833, 33, 33%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

@media screen and (min-width: 0\0) {
  select {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg==");
  }
}

select:focus {
  outline: none;
  border: 0 solid #212121;
  background-color: #dedbd3;
  box-shadow: 0 0 0 #d1cec5;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

select:disabled {
  background-color: #f4f3f0;
  cursor: not-allowed;
}

select::-ms-expand {
  display: none;
}

select[multiple] {
  height: auto;
  background-image: none;
}

.is-invalid-input:not(:focus) {
  border-color: #e2001a;
  background-color: #fce6e8;
}

.is-invalid-input:not(:focus)::placeholder {
  color: #e2001a;
}

.is-invalid-label {
  color: #e2001a;
}

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #e2001a;
}

.form-error.is-visible {
  display: block;
}

.accordion {
  margin-left: 0;
  background: #ffffff;
  list-style-type: none;
}

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0;
}

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0;
}

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #f4f3f0;
  border-bottom: 0;
  font-size: 1rem;
  line-height: 1;
  color: #111111;
}

:last-child:not(.is-active) > .accordion-title {
  border-bottom: 1px solid #f4f3f0;
  border-radius: 0 0 0 0;
}

.accordion-title:hover, .accordion-title:focus {
  background-color: #f4f3f0;
}

.accordion-title::before {
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-top: -0.5rem;
  content: '+';
}

.is-active > .accordion-title::before {
  content: '–';
}

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #f4f3f0;
  border-bottom: 0;
  background-color: #ffffff;
  color: #2f3132;
}

:last-child > .accordion-content:last-child {
  border-bottom: 1px solid #f4f3f0;
}

.is-accordion-submenu-parent > a {
  position: relative;
}

.is-accordion-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #111111 transparent transparent;
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: 1rem;
}

.is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%;
}

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(17, 17, 17, 0.25);
  border-radius: 0;
  background-color: white;
  color: #2f3132;
}

.callout > :first-child {
  margin-top: 0;
}

.callout > :last-child {
  margin-bottom: 0;
}

.callout.primary {
  background-color: #dbdbdb;
  color: #2f3132;
}

.callout.secondary {
  background-color: #ffd4d9;
  color: #2f3132;
}

.callout.success {
  background-color: #e1faea;
  color: #2f3132;
}

.callout.warning {
  background-color: #fff3d9;
  color: #2f3132;
}

.callout.alert {
  background-color: #ffd4d9;
  color: #2f3132;
}

.callout.small {
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
}

.callout.large {
  padding-top: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  padding-left: 3rem;
}

.close-button {
  position: absolute;
  color: #212121;
  cursor: pointer;
}

[data-whatinput='mouse'] .close-button {
  outline: 0;
}

.close-button:hover, .close-button:focus {
  color: #111111;
}

.close-button.small {
  right: 0.66rem;
  top: 0.33em;
  font-size: 1.5em;
  line-height: 1;
}

.close-button, .close-button.medium {
  right: 1rem;
  top: 0.5rem;
  font-size: 2em;
  line-height: 1;
}

.menu {
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
}

.menu > li {
  flex: 0 0 auto;
}

[data-whatinput='mouse'] .menu > li {
  outline: 0;
}

.menu > li > a {
  display: block;
  padding: 0.7rem 1rem;
  line-height: 1;
}

.menu input,
.menu select,
.menu a,
.menu button {
  margin-bottom: 0;
}

.menu > li > a {
  display: flex;
}

.menu > li > a {
  flex-flow: row nowrap;
}

.menu > li > a img,
.menu > li > a i,
.menu > li > a svg {
  margin-right: 0.25rem;
}

.menu, .menu.horizontal {
  flex-wrap: nowrap;
}

.menu > li, .menu.horizontal > li {
  flex: 0 0 auto;
}

.menu.expanded > li {
  flex: 1 1 0px;
}

.menu.expanded > li:first-child:last-child {
  width: 100%;
}

.menu.vertical {
  flex-wrap: wrap;
}

.menu.vertical > li {
  flex: 0 0 100%;
  max-width: 100%;
}

.menu.vertical > li > a {
  justify-content: flex-start;
  align-items: flex-start;
}

@media print, screen and (min-width: 30em) {
  .menu.xsmall-horizontal {
    flex-wrap: nowrap;
  }
  .menu.xsmall-horizontal > li {
    flex: 0 0 auto;
  }
  .menu.xsmall-expanded > li {
    flex: 1 1 0px;
  }
  .menu.xsmall-expanded > li:first-child:last-child {
    width: 100%;
  }
  .menu.xsmall-vertical {
    flex-wrap: wrap;
  }
  .menu.xsmall-vertical > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .menu.xsmall-vertical > li > a {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media print, screen and (min-width: 40em) {
  .menu.medium-horizontal {
    flex-wrap: nowrap;
  }
  .menu.medium-horizontal > li {
    flex: 0 0 auto;
  }
  .menu.medium-expanded > li {
    flex: 1 1 0px;
  }
  .menu.medium-expanded > li:first-child:last-child {
    width: 100%;
  }
  .menu.medium-vertical {
    flex-wrap: wrap;
  }
  .menu.medium-vertical > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .menu.medium-vertical > li > a {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media print, screen and (min-width: 64em) {
  .menu.large-horizontal {
    flex-wrap: nowrap;
  }
  .menu.large-horizontal > li {
    flex: 0 0 auto;
  }
  .menu.large-expanded > li {
    flex: 1 1 0px;
  }
  .menu.large-expanded > li:first-child:last-child {
    width: 100%;
  }
  .menu.large-vertical {
    flex-wrap: wrap;
  }
  .menu.large-vertical > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .menu.large-vertical > li > a {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media screen and (min-width: 75em) {
  .menu.xlarge-horizontal {
    flex-wrap: nowrap;
  }
  .menu.xlarge-horizontal > li {
    flex: 0 0 auto;
  }
  .menu.xlarge-expanded > li {
    flex: 1 1 0px;
  }
  .menu.xlarge-expanded > li:first-child:last-child {
    width: 100%;
  }
  .menu.xlarge-vertical {
    flex-wrap: wrap;
  }
  .menu.xlarge-vertical > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .menu.xlarge-vertical > li > a {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media screen and (min-width: 100em) {
  .menu.xxlarge-horizontal {
    flex-wrap: nowrap;
  }
  .menu.xxlarge-horizontal > li {
    flex: 0 0 auto;
  }
  .menu.xxlarge-expanded > li {
    flex: 1 1 0px;
  }
  .menu.xxlarge-expanded > li:first-child:last-child {
    width: 100%;
  }
  .menu.xxlarge-vertical {
    flex-wrap: wrap;
  }
  .menu.xxlarge-vertical > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .menu.xxlarge-vertical > li > a {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.menu.simple li {
  display: inline-block;
  margin-right: 1rem;
  line-height: 1;
}

.menu.simple a {
  padding: 0;
}

.menu.align-right {
  justify-content: flex-end;
}

.menu.icon-top > li > a {
  flex-flow: column nowrap;
}

.menu.icon-top > li > a img,
.menu.icon-top > li > a i,
.menu.icon-top > li > a svg {
  align-self: stretch;
  margin-bottom: 0.25rem;
  text-align: center;
}

.menu.icon-top.vertical a > span {
  margin: auto;
}

.menu.nested {
  margin-left: 1rem;
}

.menu .active > a {
  background: #111111;
  color: #212121;
}

.menu.menu-bordered li {
  border: 1px solid #f4f3f0;
}

.menu.menu-bordered li:not(:first-child) {
  border-top: 0;
}

.menu.menu-hover li:hover {
  background-color: #f4f3f0;
}

.menu-text {
  padding-top: 0;
  padding-bottom: 0;
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit;
}

.menu-centered {
  text-align: center;
}

.menu-centered > .menu {
  display: inline-block;
}

.no-js [data-responsive-menu] ul {
  display: none;
}

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer;
}

.menu-icon::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #ffffff;
  box-shadow: 0 7px 0 #ffffff, 0 14px 0 #ffffff;
  content: '';
}

.menu-icon:hover::after {
  background: #d1cec5;
  box-shadow: 0 7px 0 #d1cec5, 0 14px 0 #d1cec5;
}

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer;
}

.menu-icon.dark::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #111111;
  box-shadow: 0 7px 0 #111111, 0 14px 0 #111111;
  content: '';
}

.menu-icon.dark:hover::after {
  background: #212121;
  box-shadow: 0 7px 0 #212121, 0 14px 0 #212121;
}

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto;
}

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0;
}

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem;
}

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #111111 transparent transparent;
  right: 5px;
  margin-top: -3px;
}

[data-whatinput='mouse'] .dropdown.menu a {
  outline: 0;
}

.no-js .dropdown.menu ul {
  display: none;
}

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0;
}

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
}

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%;
}

.dropdown.menu.vertical > li > a::after {
  right: 14px;
}

.dropdown.menu.vertical > li.opens-left > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #111111 transparent transparent;
}

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #111111;
}

@media print, screen and (min-width: 30em) {
  .dropdown.menu.xsmall-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.xsmall-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.xsmall-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem;
  }
  .dropdown.menu.xsmall-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #111111 transparent transparent;
    right: 5px;
    margin-top: -3px;
  }
  .dropdown.menu.xsmall-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.xsmall-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
  }
  .dropdown.menu.xsmall-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
  .dropdown.menu.xsmall-vertical > li > a::after {
    right: 14px;
  }
  .dropdown.menu.xsmall-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #111111 transparent transparent;
  }
  .dropdown.menu.xsmall-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #111111;
  }
}

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem;
  }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #111111 transparent transparent;
    right: 5px;
    margin-top: -3px;
  }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
  }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px;
  }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #111111 transparent transparent;
  }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #111111;
  }
}

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem;
  }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #111111 transparent transparent;
    right: 5px;
    margin-top: -3px;
  }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
  }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px;
  }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #111111 transparent transparent;
  }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #111111;
  }
}

@media screen and (min-width: 75em) {
  .dropdown.menu.xlarge-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.xlarge-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.xlarge-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem;
  }
  .dropdown.menu.xlarge-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #111111 transparent transparent;
    right: 5px;
    margin-top: -3px;
  }
  .dropdown.menu.xlarge-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.xlarge-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
  }
  .dropdown.menu.xlarge-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
  .dropdown.menu.xlarge-vertical > li > a::after {
    right: 14px;
  }
  .dropdown.menu.xlarge-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #111111 transparent transparent;
  }
  .dropdown.menu.xlarge-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #111111;
  }
}

@media screen and (min-width: 100em) {
  .dropdown.menu.xxlarge-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.xxlarge-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.xxlarge-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem;
  }
  .dropdown.menu.xxlarge-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #111111 transparent transparent;
    right: 5px;
    margin-top: -3px;
  }
  .dropdown.menu.xxlarge-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.xxlarge-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
  }
  .dropdown.menu.xxlarge-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
  .dropdown.menu.xxlarge-vertical > li > a::after {
    right: 14px;
  }
  .dropdown.menu.xxlarge-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #111111 transparent transparent;
  }
  .dropdown.menu.xxlarge-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #111111;
  }
}

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto;
}

.is-dropdown-menu.vertical {
  width: 100px;
}

.is-dropdown-menu.vertical.align-right {
  float: right;
}

.is-dropdown-submenu-parent {
  position: relative;
}

.is-dropdown-submenu-parent a::after {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -6px;
}

.is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
  top: 100%;
  left: auto;
}

.is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
}

.is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%;
}

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 0 solid #d1cec5;
  background: #ffffff;
}

.is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
  right: 14px;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #111111 transparent transparent;
}

.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #111111;
}

.is-dropdown-submenu .is-dropdown-submenu {
  margin-top: 0;
}

.is-dropdown-submenu > li {
  width: 100%;
}

.is-dropdown-submenu.js-dropdown-active {
  display: block;
}

.orbit {
  position: relative;
}

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
}

.orbit-slide {
  width: 100%;
}

.orbit-slide.no-motionui.is-active {
  top: 0;
  left: 0;
}

.orbit-figure {
  margin: 0;
}

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0;
}

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(17, 17, 17, 0.5);
  color: #ffffff;
}

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #ffffff;
}

[data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
  outline: 0;
}

.orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
  background-color: rgba(17, 17, 17, 0.5);
}

.orbit-previous {
  left: 0;
}

.orbit-next {
  left: auto;
  right: 0;
}

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center;
}

[data-whatinput='mouse'] .orbit-bullets {
  outline: 0;
}

.orbit-bullets button {
  width: 1.2rem;
  height: 1.2rem;
  margin: 0.1rem;
  border-radius: 50%;
  background-color: #d1cec5;
}

.orbit-bullets button:hover {
  background-color: #212121;
}

.orbit-bullets button.is-active {
  background-color: #212121;
}

body.is-reveal-open {
  overflow: hidden;
}

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  user-select: none;
}

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(17, 17, 17, 0.45);
  overflow-y: scroll;
}

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 0 solid #d1cec5;
  border-radius: 0;
  background-color: #ffffff;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto;
}

[data-whatinput='mouse'] .reveal {
  outline: 0;
}

@media print, screen and (min-width: 40em) {
  .reveal {
    min-height: 0;
  }
}

.reveal .column, .reveal .columns,
.reveal .columns {
  min-width: 0;
}

.reveal > :last-child {
  margin-bottom: 0;
}

@media print, screen and (min-width: 40em) {
  .reveal {
    width: 600px;
    max-width: 75rem;
  }
}

@media print, screen and (min-width: 40em) {
  .reveal .reveal {
    right: auto;
    left: auto;
    margin: 0 auto;
  }
}

.reveal.collapse {
  padding: 0;
}

@media print, screen and (min-width: 40em) {
  .reveal.tiny {
    width: 30%;
    max-width: 75rem;
  }
}

@media print, screen and (min-width: 40em) {
  .reveal.small {
    width: 50%;
    max-width: 75rem;
  }
}

@media print, screen and (min-width: 40em) {
  .reveal.large {
    width: 90%;
    max-width: 75rem;
  }
}

.reveal.full {
  top: 0;
  left: 0;
  width: 100%;
  max-width: none;
  height: 100%;
  height: 100vh;
  min-height: 100vh;
  margin-left: 0;
  border: 0;
  border-radius: 0;
}

@media screen and (max-width: 29.9375em) {
  .reveal {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0;
  }
}

.reveal.without-overlay {
  position: fixed;
}

.tabs {
  margin: 0;
  border: 1px solid #f4f3f0;
  background: #ffffff;
  list-style-type: none;
}

.tabs::before, .tabs::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.tabs::after {
  clear: both;
}

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto;
}

.tabs.simple > li > a {
  padding: 0;
}

.tabs.simple > li > a:hover {
  background: transparent;
}

.tabs.primary {
  background: #111111;
}

.tabs.primary > li > a {
  color: #ffffff;
}

.tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
  background: #1d1d1d;
}

.tabs-title {
  float: left;
}

.tabs-title > a {
  display: block;
  padding: 1.25rem 1.5rem;
  font-size: 1rem;
  line-height: 1;
  color: #111111;
}

.tabs-title > a:hover {
  background: #ffffff;
  color: #0f0f0f;
}

.tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
  background: #f4f3f0;
  color: #111111;
}

.tabs-content {
  border: 1px solid #f4f3f0;
  border-top: 0;
  background: #ffffff;
  color: #2f3132;
  transition: all 0.5s ease;
}

.tabs-content.vertical {
  border: 1px solid #f4f3f0;
  border-left: 0;
}

.tabs-panel {
  display: none;
  padding: 1rem;
}

.tabs-panel[aria-hidden="false"] {
  display: block;
}

.title-bar {
  padding: 0.5rem;
  background: #111111;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-bar .menu-icon {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.title-bar-left,
.title-bar-right {
  flex: 1 1 0px;
}

.title-bar-right {
  text-align: right;
}

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
}

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap;
}

.top-bar,
.top-bar ul {
  background-color: #f4f3f0;
}

.top-bar input {
  max-width: 200px;
  margin-right: 1rem;
}

.top-bar .input-group-field {
  width: 100%;
  margin-right: 0;
}

.top-bar input.button {
  width: auto;
}

.top-bar .top-bar-left,
.top-bar .top-bar-right {
  flex: 0 0 100%;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .top-bar {
    flex-wrap: nowrap;
  }
  .top-bar .top-bar-left {
    flex: 1 1 auto;
  }
  .top-bar .top-bar-right {
    flex: 0 1 auto;
  }
}

@media screen and (max-width: 39.9375em) {
  .top-bar.stacked-for-xsmall {
    flex-wrap: wrap;
  }
  .top-bar.stacked-for-xsmall .top-bar-left,
  .top-bar.stacked-for-xsmall .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 63.9375em) {
  .top-bar.stacked-for-medium {
    flex-wrap: wrap;
  }
  .top-bar.stacked-for-medium .top-bar-left,
  .top-bar.stacked-for-medium .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 74.9375em) {
  .top-bar.stacked-for-large {
    flex-wrap: wrap;
  }
  .top-bar.stacked-for-large .top-bar-left,
  .top-bar.stacked-for-large .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 99.9375em) {
  .top-bar.stacked-for-xlarge {
    flex-wrap: wrap;
  }
  .top-bar.stacked-for-xlarge .top-bar-left,
  .top-bar.stacked-for-xlarge .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.top-bar.stacked-for-xxlarge {
  flex-wrap: wrap;
}

.top-bar.stacked-for-xxlarge .top-bar-left,
.top-bar.stacked-for-xxlarge .top-bar-right {
  flex: 0 0 100%;
  max-width: 100%;
}

.top-bar-title {
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0;
}

.top-bar-left,
.top-bar-right {
  flex: 0 0 auto;
}

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0);
}

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0);
}

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0);
}

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0);
}

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%);
}

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%);
}

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%);
}

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%);
}

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity;
}

.fade-in.mui-enter.mui-enter-active {
  opacity: 1;
}

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity;
}

.fade-out.mui-leave.mui-leave-active {
  opacity: 0;
}

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0;
}

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0;
}

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0;
}

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0;
}

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0;
}

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0;
}

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0;
}

.scale-in-up.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1;
}

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0;
}

.scale-in-down.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1;
}

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1;
}

.scale-out-up.mui-leave.mui-leave-active {
  transform: scale(1.5);
  opacity: 0;
}

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1;
}

.scale-out-down.mui-leave.mui-leave-active {
  transform: scale(0.5);
  opacity: 0;
}

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0;
}

.spin-in.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1;
}

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1;
}

.spin-out.mui-leave.mui-leave-active {
  transform: rotate(0.75turn);
  opacity: 0;
}

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0;
}

.spin-in-ccw.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1;
}

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1;
}

.spin-out-ccw.mui-leave.mui-leave-active {
  transform: rotate(-0.75turn);
  opacity: 0;
}

.slow {
  transition-duration: 750ms !important;
}

.fast {
  transition-duration: 250ms !important;
}

.linear {
  transition-timing-function: linear !important;
}

.ease {
  transition-timing-function: ease !important;
}

.ease-in {
  transition-timing-function: ease-in !important;
}

.ease-out {
  transition-timing-function: ease-out !important;
}

.ease-in-out {
  transition-timing-function: ease-in-out !important;
}

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important;
}

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important;
}

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important;
}

.short-delay {
  transition-delay: 300ms !important;
}

.long-delay {
  transition-delay: 700ms !important;
}

.shake {
  animation-name: shake-7;
}

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%);
  }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%);
  }
}

.spin-cw {
  animation-name: spin-cw-1turn;
}

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn);
  }
  100% {
    transform: rotate(0);
  }
}

.spin-ccw {
  animation-name: spin-cw-1turn;
}

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1turn);
  }
}

.wiggle {
  animation-name: wiggle-7deg;
}

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg);
  }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg);
  }
  0%, 30%, 70%, 100% {
    transform: rotate(0);
  }
}

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms;
}

.infinite {
  animation-iteration-count: infinite;
}

.slow {
  animation-duration: 750ms !important;
}

.fast {
  animation-duration: 250ms !important;
}

.linear {
  animation-timing-function: linear !important;
}

.ease {
  animation-timing-function: ease !important;
}

.ease-in {
  animation-timing-function: ease-in !important;
}

.ease-out {
  animation-timing-function: ease-out !important;
}

.ease-in-out {
  animation-timing-function: ease-in-out !important;
}

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important;
}

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important;
}

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important;
}

.short-delay {
  animation-delay: 300ms !important;
}

.long-delay {
  animation-delay: 700ms !important;
}

p.small {
  font-size: 80%;
}

.color {
  color: #e2001a;
}

.page_content p a, .page_footer p a {
  text-decoration: underline;
}

.page_content p a.button, .page_footer p a.button {
  text-decoration: none;
}

.float_above {
  margin-bottom: 20px;
}

.float_left {
  margin-bottom: 20px;
}

@media print, screen and (min-width: 64em) {
  .float_left {
    float: left;
    margin-right: 20px;
  }
}

.float_right {
  margin-bottom: 20px;
}

@media print, screen and (min-width: 64em) {
  .float_right {
    float: right;
    margin-left: 20px;
  }
}

.expanded .row {
  margin-left: auto;
  margin-right: auto;
  max-width: 75rem;
}

.page_content .row {
  margin-bottom: 1.5em;
}

.bg-color-lightgrey {
  padding-top: 2rem;
  background-color: #f4f3f0;
}

.bg-color-white {
  padding-top: 2rem;
  background-color: #ffffff;
}

.bg-color-primary {
  padding-top: 2rem;
  background-color: #111111;
}

.text-center .row-one-column, .text-center.row-one-column,
.row-one-column.text-center {
  justify-content: center;
}

.row-one-column > * {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .row-one-column > * {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media print, screen and (min-width: 64em) {
  .row-one-column > * {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    max-width: 83.33333%;
  }
}

@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .row-one-column > * {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.row-two-columns > * {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .row-two-columns > * {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media print, screen and (min-width: 64em) {
  .row-two-columns > * {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    max-width: 50%;
  }
}

@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .row-two-columns > * {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.row-three-columns > * {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .row-three-columns > * {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media print, screen and (min-width: 40em) {
  .row-three-columns > * {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    max-width: 50%;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .row-three-columns > * {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media print, screen and (min-width: 64em) {
  .row-three-columns > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    max-width: 33.33333%;
  }
}

@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .row-three-columns > * {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.row-four-columns > * {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .row-four-columns > * {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media print, screen and (min-width: 40em) {
  .row-four-columns > * {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    max-width: 50%;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .row-four-columns > * {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media print, screen and (min-width: 64em) {
  .row-four-columns > * {
    flex: 0 0 25%;
    max-width: 25%;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    max-width: 25%;
  }
}

@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .row-four-columns > * {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.row-big-small > *:nth-child(odd) {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .row-big-small > *:nth-child(odd) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media print, screen and (min-width: 64em) {
  .row-big-small > *:nth-child(odd) {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    max-width: 66.66667%;
  }
}

@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .row-big-small > *:nth-child(odd) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.row-big-small > *:nth-child(even) {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .row-big-small > *:nth-child(even) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media print, screen and (min-width: 64em) {
  .row-big-small > *:nth-child(even) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    max-width: 33.33333%;
  }
}

@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .row-big-small > *:nth-child(even) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.row-small-big > *:nth-child(odd) {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .row-small-big > *:nth-child(odd) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media print, screen and (min-width: 64em) {
  .row-small-big > *:nth-child(odd) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    max-width: 33.33333%;
  }
}

@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .row-small-big > *:nth-child(odd) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.row-small-big > *:nth-child(even) {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .row-small-big > *:nth-child(even) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media print, screen and (min-width: 64em) {
  .row-small-big > *:nth-child(even) {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    max-width: 66.66667%;
  }
}

@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .row-small-big > *:nth-child(even) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.remove-grid {
  width: 100% !important;
  flex: 0 0 100% !important;
  max-width: none !important;
  margin: 0 auto;
}

.ce_gallery ul {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  -moz-box-pack: center;
  justify-content: center;
}

.row .ce_gallery {
  padding-left: 0;
  padding-right: 0;
}

.ce_gallery li {
  list-style: none;
}

.ce_gallery img {
  width: 100%;
}

.ce_gallery .cols_1 li {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .ce_gallery .cols_1 li {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.ce_gallery .cols_2 li {
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 50%;
}

@media print, screen and (min-width: 40em) {
  .ce_gallery .cols_2 li {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.ce_gallery .cols_3 li {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .ce_gallery .cols_3 li {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media print, screen and (min-width: 40em) {
  .ce_gallery .cols_3 li {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    max-width: 33.33333%;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .ce_gallery .cols_3 li {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.ce_gallery .cols_4 li {
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 50%;
}

@media print, screen and (min-width: 40em) {
  .ce_gallery .cols_4 li {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media print, screen and (min-width: 40em) {
  .ce_gallery .cols_4 li {
    flex: 0 0 25%;
    max-width: 25%;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    max-width: 25%;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .ce_gallery .cols_4 li {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.ce_gallery .cols_6 li {
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 50%;
}

@media print, screen and (min-width: 40em) {
  .ce_gallery .cols_6 li {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media print, screen and (min-width: 40em) {
  .ce_gallery .cols_6 li {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    max-width: 16.66667%;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .ce_gallery .cols_6 li {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.page_content .ce_download a[href$=".pdf"],
.page_content .ce_download a[href$=".doc"],
.page_content .ce_download a[href$=".docx"],
.page_content .ce_download a[href$=".word"], .page_content .ce_downloads a[href$=".pdf"],
.page_content .ce_downloads a[href$=".doc"],
.page_content .ce_downloads a[href$=".docx"],
.page_content .ce_downloads a[href$=".word"] {
  margin-left: 20px;
  display: inline-block;
}

.page_content .ce_download a[href$=".pdf"]:before,
.page_content .ce_download a[href$=".doc"]:before,
.page_content .ce_download a[href$=".docx"]:before,
.page_content .ce_download a[href$=".word"]:before, .page_content .ce_downloads a[href$=".pdf"]:before,
.page_content .ce_downloads a[href$=".doc"]:before,
.page_content .ce_downloads a[href$=".docx"]:before,
.page_content .ce_downloads a[href$=".word"]:before {
  content: "";
  margin-right: 10px;
}

.page_content .ce_download a[href$=".png"],
.page_content .ce_download a[href$=".jpg"],
.page_content .ce_download a[href$=".svg"],
.page_content .ce_download a[href$=".bmp"],
.page_content .ce_download a[href$=".gif"],
.page_content .ce_download a[href$=".tif"], .page_content .ce_downloads a[href$=".png"],
.page_content .ce_downloads a[href$=".jpg"],
.page_content .ce_downloads a[href$=".svg"],
.page_content .ce_downloads a[href$=".bmp"],
.page_content .ce_downloads a[href$=".gif"],
.page_content .ce_downloads a[href$=".tif"] {
  margin-left: 20px;
  display: inline-block;
}

.page_content .ce_download a[href$=".png"]:before,
.page_content .ce_download a[href$=".jpg"]:before,
.page_content .ce_download a[href$=".svg"]:before,
.page_content .ce_download a[href$=".bmp"]:before,
.page_content .ce_download a[href$=".gif"]:before,
.page_content .ce_download a[href$=".tif"]:before, .page_content .ce_downloads a[href$=".png"]:before,
.page_content .ce_downloads a[href$=".jpg"]:before,
.page_content .ce_downloads a[href$=".svg"]:before,
.page_content .ce_downloads a[href$=".bmp"]:before,
.page_content .ce_downloads a[href$=".gif"]:before,
.page_content .ce_downloads a[href$=".tif"]:before {
  content: "";
  margin-right: 10px;
}

.page_content .ce_download a[href$=".zip"], .page_content .ce_downloads a[href$=".zip"] {
  margin-left: 20px;
  display: inline-block;
}

.page_content .ce_download a[href$=".zip"]:before, .page_content .ce_downloads a[href$=".zip"]:before {
  content: "";
  margin-right: 10px;
}

.page_content .ce_download a[href$=".xl"],
.page_content .ce_download a[href$=".xls"],
.page_content .ce_download a[href$=".xlsx"], .page_content .ce_downloads a[href$=".xl"],
.page_content .ce_downloads a[href$=".xls"],
.page_content .ce_downloads a[href$=".xlsx"] {
  margin-left: 20px;
  display: inline-block;
}

.page_content .ce_download a[href$=".xl"]:before,
.page_content .ce_download a[href$=".xls"]:before,
.page_content .ce_download a[href$=".xlsx"]:before, .page_content .ce_downloads a[href$=".xl"]:before,
.page_content .ce_downloads a[href$=".xls"]:before,
.page_content .ce_downloads a[href$=".xlsx"]:before {
  content: "";
  margin-right: 10px;
}

.page_content .ce_download a[href$=".mp4"], .page_content .ce_downloads a[href$=".mp4"] {
  margin-left: 20px;
  display: inline-block;
}

.page_content .ce_download a[href$=".mp4"]:before, .page_content .ce_downloads a[href$=".mp4"]:before {
  content: "";
  margin-right: 10px;
}

.page_content .ce_download a[href$=".mov"],
.page_content .ce_download a[href$=".avi"],
.page_content .ce_download a[href$=".wmv"], .page_content .ce_downloads a[href$=".mov"],
.page_content .ce_downloads a[href$=".avi"],
.page_content .ce_downloads a[href$=".wmv"] {
  margin-left: 20px;
  display: inline-block;
}

.page_content .ce_download a[href$=".mov"]:before,
.page_content .ce_download a[href$=".avi"]:before,
.page_content .ce_download a[href$=".wmv"]:before, .page_content .ce_downloads a[href$=".mov"]:before,
.page_content .ce_downloads a[href$=".avi"]:before,
.page_content .ce_downloads a[href$=".wmv"]:before {
  content: "";
  margin-right: 10px;
}

.page_content .ce_download a[href$=".pdf"]:before,
.page_content .ce_download a[href$=".doc"]:before,
.page_content .ce_download a[href$=".docx"]:before,
.page_content .ce_download a[href$=".word"]:before,
.page_content .ce_download a[href$=".jpg"]:before,
.page_content .ce_download a[href$=".png"]:before,
.page_content .ce_download a[href$=".jpg"]:before,
.page_content .ce_download a[href$=".svg"]:before,
.page_content .ce_download a[href$=".bmp"]:before,
.page_content .ce_download a[href$=".gif"]:before,
.page_content .ce_download a[href$=".zip"]:before,
.page_content .ce_download a[href$=".xl"]:before,
.page_content .ce_download a[href$=".xls"]:before,
.page_content .ce_download a[href$=".xlsx"]:before,
.page_content .ce_download a[href$=".mp4"]:before,
.page_content .ce_download a[href$=".wmv"]:before,
.page_content .ce_download a[href$=".mov"]:before,
.page_content .ce_download a[href$=".avi"]:before, .page_content .ce_downloads a[href$=".pdf"]:before,
.page_content .ce_downloads a[href$=".doc"]:before,
.page_content .ce_downloads a[href$=".docx"]:before,
.page_content .ce_downloads a[href$=".word"]:before,
.page_content .ce_downloads a[href$=".jpg"]:before,
.page_content .ce_downloads a[href$=".png"]:before,
.page_content .ce_downloads a[href$=".jpg"]:before,
.page_content .ce_downloads a[href$=".svg"]:before,
.page_content .ce_downloads a[href$=".bmp"]:before,
.page_content .ce_downloads a[href$=".gif"]:before,
.page_content .ce_downloads a[href$=".zip"]:before,
.page_content .ce_downloads a[href$=".xl"]:before,
.page_content .ce_downloads a[href$=".xls"]:before,
.page_content .ce_downloads a[href$=".xlsx"]:before,
.page_content .ce_downloads a[href$=".mp4"]:before,
.page_content .ce_downloads a[href$=".wmv"]:before,
.page_content .ce_downloads a[href$=".mov"]:before,
.page_content .ce_downloads a[href$=".avi"]:before {
  margin-left: -20px;
  margin-top: 3px;
  text-decoration: none !important;
  position: absolute;
}

iframe {
  width: 1px;
  min-width: 100%;
  border: none;
}

/* ===================================================================================================================
   Background Images
   ================================================================================================================ */
.pagebg-container {
  background-position: 40% center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  background-image: url("../img/background_unternemen.jpg");
  transition: filter 0.5s linear;
  animation-name: fade-0-to-100;
  animation-duration: 1s;
}

@media print, screen and (min-width: 40em) {
  .pagebg-container {
    background-position: center center;
  }
}

@keyframes fade-0-to-100 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blur .pagebg-container {
  filter: blur(8px);
}

.page_type-form-feedback .pagebg-container,
.page_type-imprint .pagebg-container {
  background-image: none;
}

.page_type-home .pagebg-container {
  background-image: url("../../beilharz/Home/rotator.php");
}

.page_type-contact .pagebg-container {
  background-image: url("../img/background_leaf.jpg");
}

.page_type-philosophy .pagebg-container {
  background-image: url("../img/background-philosophy.jpg");
}

.page_type-system .pagebg-container {
  background-image: url("../img/background-bausystem2.jpg");
}

.page_type-voehringen .pagebg-container {
  background-image: url("../img/background_musterhaus_avance_150.jpg");
}

.page_type-fellbach .pagebg-container {
  background-image: url("../img/background_fellbach.jpg");
}

.page_type-architect-house-overview .pagebg-container {
  background-image: url("../img/background_architektenhaeuser.jpg");
  background-position: 64% center;
}

.page_type-architect-house-772419 .pagebg-container {
  background-image: url("../img/background_772419.jpg");
}

.page_type-architect-house-772413 .pagebg-container {
  background-image: url("../img/background_772413.jpg");
}

.page_type-architect-house-772411 .pagebg-container {
  background-image: url("../img/background_772411.jpg");
}

.page_type-architect-house-772367 .pagebg-container {
  background-image: url("../img/background_772367.jpg");
  background-position: 82% center;
}

.page_type-architect-house-772342 .pagebg-container {
  background-image: url("../img/background_772342.jpg");
  background-position: 26% center;
}

.page_type-architect-house-772330 .pagebg-container {
  background-image: url("../img/background_772330.jpg");
}

.page_type-architect-house-772321 .pagebg-container {
  background-image: url("../img/background_772321.jpg");
}

.page_type-architect-house-772313 .pagebg-container {
  background-image: url("../img/background_772313.jpg");
}

.page_type-architect-house-772306 .pagebg-container {
  background-image: url("../img/background_772306.jpg");
  background-position: 15% center;
}

.page_type-architect-house-772214 .pagebg-container {
  background-image: url("../img/background_772214.jpg");
}

.page_type-architect-house-772140 .pagebg-container {
  background-image: url("../img/background_772140.jpg");
}

.page_type-architect-house-772083 .pagebg-container {
  background-image: url("../img/background_772083.jpg");
}

.page_type-architect-house-772051 .pagebg-container {
  background-image: url("../img/background_772051.jpg");
  background-position: 15% center;
}

.page_type-architect-house-772032 .pagebg-container {
  background-image: url("../img/background_772032.jpg");
  background-position: 83% center;
}

.page_type-architect-house-771795 .pagebg-container {
  background-image: url("../img/background_771795.jpg");
  background-position: 52% center;
}

.page_type-architect-house-772576 .pagebg-container {
  background-image: url("../img/background_772576.jpg");
}

.page_type-architect-house-772398 .pagebg-container {
  background-image: url("../img/background_772398.jpg");
}

.page_type-architect-house-772453 .pagebg-container {
  background-image: url("../img/background_772453.jpg");
}

.page_type-architect-house-772460 .pagebg-container {
  background-image: url("../img/background_772460.jpg");
}

.page_type-architect-house-772597 .pagebg-container {
  background-image: url("../img/background_772597.jpg");
}

.page_type-architect-house-772473 .pagebg-container {
  background-image: url("../img/background_772473.jpg");
}

.page_type-architect-house-772606 .pagebg-container {
  background-image: url("../img/background_772606.jpg");
}

.page_type-architect-house-772614 .pagebg-container {
  background-image: url("../img/background_772614.jpg");
}

.page_type-architect-house-772638 .pagebg-container {
  background-image: url("../img/background_772638.jpg");
}

.page_type-architect-house-772651 .pagebg-container {
  background-image: url("../img/background_772651.jpg");
}

.page_type-architect-house-772440 .pagebg-container {
  background-image: url("../img/background_772440.jpg");
}

.page_type-architect-house-772593 .pagebg-container {
  background-image: url("../img/background_772593.jpg");
}

.page_type-architect-house-772630 .pagebg-container {
  background-image: url("../img/background_772630.jpg");
}

.page_type-architect-house-772634 .pagebg-container {
  background-image: url("../img/background_772634.jpg");
}

.page_type-module-house-772586 .pagebg-container {
  background-image: url("../img/background_772586.jpg");
  background-position: 56% center;
}

.page_type-module-house-pure .pagebg-container {
  background-image: url("../img/background_pure.jpg");
  background-position: 56% center;
}

.page_type-module-house-balance .pagebg-container {
  background-image: url("../img/background_balance.jpg");
  background-position: 56% center;
}

.page_type-module-house-overview .pagebg-container {
  background-image: url("../img/background_modulhaeuser.jpg");
  background-position: 56% center;
}

.page_type-module-house-772337 .pagebg-container {
  background-image: url("../img/background_772337.jpg");
}

.page_type-module-house-772369 .pagebg-container {
  background-image: url("../img/background_772369.jpg");
  background-position: 52% center;
}

.page_type-module-house-772340 .pagebg-container {
  background-image: url("../img/background_772340.jpg");
}

.page_type-module-house-772311 .pagebg-container {
  background-image: url("../img/background_772311.jpg");
  background-position: 76% center;
}

.page_type-module-house-772242 .pagebg-container {
  background-image: url("../img/background_772242.jpg");
}

.page_type-module-house-772241 .pagebg-container {
  background-image: url("../img/background_772241.jpg");
}

.page_type-module-house-772226 .pagebg-container {
  background-image: url("../img/background_772226.jpg");
  background-position: 97% center;
}

.page_type-module-house-772153 .pagebg-container {
  background-image: url("../img/background_772153.jpg");
  background-position: 76% center;
}

.page_type-module-house-772145 .pagebg-container {
  background-image: url("../img/background_772145.jpg");
  background-position: 65% center;
}

.page_type-module-house-772465 .pagebg-container {
  background-image: url("../img/background_772465.jpg");
}

.page_type-module-house-772565 .pagebg-container {
  background-image: url("../img/background_772565.jpg");
}

.page_type-module-house-772529 .pagebg-container {
  background-image: url("../img/background_772529.jpg");
}

.page_type-module-house-772545 .pagebg-container {
  background-image: url("../img/background_772545.jpg");
}

.page_type-modulbox .pagebg-container {
  background-image: url("../img/background-modulbox.jpg");
}

.page_type-module-house-772648 .pagebg-container {
  background-image: url("../img/background_772648.jpg");
}

.page_type-module-house-772457 .pagebg-container {
  background-image: url("../img/background_772457.jpg");
}

.page_type-module-house-772446 .pagebg-container {
  background-image: url("../img/background_772446.jpg");
}

.page_type-module-house-772558 .pagebg-container {
  background-image: url("../img/background_772558.jpg");
}

.page_type-module-house-772454 .pagebg-container {
  background-image: url("../img/background_772454.jpg");
}

.page_type-care .pagebg-container {
  background-image: url("../img/background-pflegetipps.jpg");
}

.page_type-landingpage-1 .pagebg-container {
  background-image: url("../img/background_lp_oekologischbauen.jpg");
}

.page_type-landingpage-2 .pagebg-container {
  background-image: url("../img/background_lp_individuellerhausbau.jpg");
}

.page_type-landingpage-3 .pagebg-container {
  background-image: url("../img/background_lp_musterhauspark.jpg");
}

.page_type-landingpage-4 .pagebg-container {
  background-image: url("../img/background_lp_zimmerer.jpg");
}

/* ===================================================================================================================
   Page Layout
   ================================================================================================================ */
html {
  height: 100%;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

body {
  height: 100%;
  overflow-x: hidden;
}

body.overflow-hidden {
  overflow: hidden;
}

/* ===================================================================================================================
   Typography - Global
   ================================================================================================================ */
h1, h2, h3 {
  line-height: 1;
  hyphens: none;
  font-weight: 300;
}

h1 {
  padding-top: 0.5rem;
  font-weight: 400;
}

.ce_text.item-lp h1 {
  max-width: 45rem;
  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 3rem;
  font-size: 2rem;
  text-transform: uppercase;
}

@media print, screen and (min-width: 40em) {
  .ce_text.item-lp h1 {
    font-size: 2.375rem;
  }
}

.ce_text.item-lp h1:after {
  content: '';
  border-bottom: 2px solid #e2001a;
  width: 60px;
  height: 2px;
  display: block;
  padding-top: 12px;
  padding-bottom: 0.25rem;
  margin: 0 auto;
}

h2 {
  text-transform: uppercase;
  padding-bottom: 3rem;
  max-width: 45rem;
}

.text-center h2 {
  margin: 0 auto;
}

h3 {
  font-weight: 400;
  padding-bottom: 0;
}

.bg-color-lightgrey h3 {
  text-transform: uppercase;
}

h4 {
  letter-spacing: 0.075em;
  width: 100%;
  margin-top: 80px;
  text-align: center;
  padding-bottom: 3.5rem;
  color: #bab6a9;
  text-transform: uppercase;
  display: table;
  white-space: nowrap;
}

h4:before, h4:after {
  border-top: 1px solid #bab6a9;
  content: '';
  display: table-cell;
  position: relative;
  top: 0.6em;
  width: 45%;
}

h4:before {
  right: 1.2%;
}

h4:after {
  left: 1.2%;
}

.bg-color-lightgrey h4 {
  margin-top: 0;
}

h3 {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.page_content .ce_headline h2:after,
.page_content .ce_text h2:after {
  content: '';
  border-bottom: 2px solid #e2001a;
  width: 60px;
  height: 2px;
  display: block;
  padding-top: 12px;
  padding-bottom: 0.25rem;
  margin: 0 auto;
}

.item_imprint h2,
.item_privacy-policy h2 {
  padding-bottom: 1rem;
  padding-top: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: none;
}

@media print, screen and (min-width: 40em) {
  .item_imprint h2,
  .item_privacy-policy h2 {
    font-size: 1.625rem;
  }
}

.item_imprint h2:after,
.item_privacy-policy h2:after {
  display: none !important;
  visibility: hidden !important;
}

.item_imprint a,
.item_privacy-policy a {
  word-break: break-all;
  word-wrap: break-word;
}

p {
  hyphens: auto;
}

@media print, screen and (min-width: 40em) {
  p {
    hyphens: none;
  }
}

.button,
a.button {
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #d1cec5;
}

.button:hover,
a.button:hover {
  color: #ffffff;
}

cite:before {
  content: '';
}

cite, p.small {
  color: #bab6a9;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
}

.text-center ul {
  list-style-position: inside;
  margin-left: 0;
}

ul.lead {
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
  margin-left: 0;
}

@media print, screen and (min-width: 64em) {
  ul.lead {
    margin-bottom: 1.5em;
    padding-left: 15px;
    font-size: 125%;
    line-height: 1.6;
  }
}

ul.lead li {
  position: relative;
  margin-bottom: 1em;
  padding-bottom: 1rem;
  border-bottom: 1px solid #f4f3f0;
}

@media print, screen and (min-width: 64em) {
  ul.lead li {
    padding-bottom: 0;
    border-bottom: 0 solid #f4f3f0;
    margin-bottom: 0.5em;
    margin-left: 5px;
  }
}

ul.lead li:before {
  content: "";
  position: relative;
  color: #e2001a;
  display: block;
  padding-bottom: 0.25rem;
}

@media print, screen and (min-width: 64em) {
  ul.lead li:before {
    display: inline;
    position: absolute;
    margin-left: -25px;
    top: 6px;
    padding-bottom: 0;
  }
}

.anchor-list {
  list-style: none;
}

.anchor-list li a:before {
  list-style: none;
  content: "";
  font-size: 0.75rem;
  padding-right: 5px;
}

.anchor-list li a:hover {
  color: #e2001a;
}

.page_wrapper {
  display: table;
  width: 100%;
  height: 100%;
}

.page_header,
.page_teaser,
.page_footer,
.page_content-wrapper-outside {
  display: table-row;
}

.page_content-wrapper-outside {
  height: 100%;
}

.page_column-wrapper {
  position: relative;
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
}

.page_layout-0-1-0 .page_column-1 {
  width: 100%;
  height: 100%;
}

@media print, screen and (min-width: 64em) {
  .page_layout-3-1-0 .page_column-1 {
    float: right;
    width: 75%;
  }
  .page_layout-3-1-0 .page_column-3 {
    margin: 0 75% 0 0;
  }
  .page_layout-3-1-0 .page_content {
    margin-left: 1.25rem;
  }
  .page_layout-3-1-0 .page_sidebar {
    margin-right: 1.25rem;
  }
}

@media print, screen and (min-width: 64em) {
  .page_layout-1-3-0 .page_column-1 {
    float: left;
    width: 25%;
  }
  .page_layout-1-3-0 .page_column-3 {
    margin: 0 0 0 25%;
  }
  .page_layout-1-3-0 .page_content {
    margin-left: 1.25rem;
  }
  .page_layout-1-3-0 .page_sidebar {
    margin-right: 1.25rem;
  }
}

/* ===================================================================================================================
   Page Main Elements - Header, Teaser, Content, Sidebar, Footer
   ================================================================================================================ */
.page_header {
  background-color: #ffffff;
  z-index: 1000 !important;
  position: absolute;
  width: 100%;
  height: 4.875rem;
}

@media print, screen and (min-width: 64em) {
  .page_header {
    position: relative;
  }
}

.page_header .columns {
  padding-left: 0;
  padding-right: 0;
}

@media print, screen and (min-width: 64em) {
  .page_header .columns {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.page_header .mod_boxes4ward {
  position: relative;
}

.page_header .boxes4ward_article.first {
  box-shadow: 2px 2px 4px rgba(17, 17, 17, 0.3);
  z-index: 1000;
  background-color: #ffffff;
}

.page_header .boxes4ward_article.first {
  position: relative;
  width: 100%;
}

@media print, screen and (min-width: 64em) {
  .page_header .boxes4ward_article.first {
    position: fixed;
  }
}

.page_header .logo_company {
  margin: 20px 0;
  text-align: center;
}

@media print, screen and (min-width: 64em) {
  .page_header .logo_company {
    text-align: left;
  }
}

.page_header .logo_company img {
  max-width: 180px;
  width: 100%;
}

.page_content .ce_image figcaption {
  margin-top: 0.5em;
  margin-bottom: 2em;
}

.page_footer {
  display: block;
  color: #2f3132;
  background: #dedbd3;
  position: relative;
  z-index: 2;
}

.page_type-home .page_footer,
.page_type-company-10-steps .page_footer,
.page_type-concept .page_footer,
.page_type-show-house .page_footer,
.page_type-balance .page_footer {
  margin-top: 0;
}

.page_footer .boxes4ward_article .row:first-of-type {
  padding-top: 50px;
  padding-bottom: 30px;
}

.page_footer .boxes4ward_article .row:first-of-type .ce_text {
  margin-bottom: 30px;
}

.page_footer .boxes4ward_article .row:last-of-type {
  padding-bottom: 15px;
}

.page_footer .boxes4ward_article h2 {
  font-size: 1.375rem;
  font-weight: 400;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
}

.page_footer .boxes4ward_article li {
  list-style: none;
  position: relative;
  padding-bottom: 0.25rem;
}

.page_footer .boxes4ward_article li a {
  color: #2f3132;
  text-decoration: none;
  margin-left: 0;
}

.page_footer .boxes4ward_article li a:before {
  content: "";
  position: absolute;
  margin-left: -18px;
  top: 4px;
  color: #e2001a;
  display: inline;
  font-size: 0.75rem;
}

.page_footer .boxes4ward_article li a[href$=".pdf"]:before {
  content: "";
  top: 2px;
}

.page_footer .boxes4ward_article .row.footerbar {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.page_footer .boxes4ward_article .row.footerbar .ce_text {
  margin-bottom: 0;
}

.page_footer .boxes4ward_article .footerbar p,
.page_footer .boxes4ward_article .footerbar a {
  font-size: 0.875rem;
  text-align: center;
}

.page_footer .boxes4ward_article .ce_row img {
  margin-bottom: 20px;
}

.page_footer .boxes4ward_article a.button {
  background-color: transparent;
  border: 2px solid #bab6a9;
  transition: background-color 0.25s ease-out 0s, border 0.25s ease-out 0s;
}

.page_footer .boxes4ward_article a.button:hover {
  background-color: #e2001a;
  border: 2px solid #e2001a;
}

.page_footer .footerbar {
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
}

.page_footer .boxes4ward_article .footerbar p {
  margin-bottom: 0;
}

.page_footer .boxes4ward_article .footerbar a {
  text-decoration: underline;
  position: relative;
}

.page_footer .boxes4ward_article .icon-map {
  text-decoration: none;
  margin-top: 0.75em;
  position: relative;
  display: block;
}

.page_footer .boxes4ward_article .icon-map:before {
  content: "";
  margin-right: 2px;
  color: #e2001a;
}

.toplink-wrapper {
  width: 100%;
  position: fixed;
  padding-top: 1rem;
  z-index: 99;
  background-color: #e2001a;
}

a.toplink, a.kontaktlink {
  font-size: 1.125rem;
  height: 36px;
  width: 36px;
  position: fixed;
  color: #ffffff;
  display: block;
  z-index: 50;
  right: 20px;
  bottom: -10%;
  padding-top: 8px;
  padding-left: 9px;
  background-color: #e2001a;
  transition: bottom 0.5s ease;
}

a.toplink:before, a.kontaktlink:before {
  content: "";
  margin-right: 10px;
  margin-right: 0;
}

a.toplink:hover, a.toplink:focus, a.kontaktlink:hover, a.kontaktlink:focus {
  color: #ffffff;
}

a.kontaktlink:before {
  content: "";
  margin-right: 10px;
  margin-right: 0;
}

.start-scroll .toplink {
  bottom: 20px;
}

.start-scroll .kontaktlink {
  bottom: 60px;
}

.page_type-company-10-steps .toplink {
  display: none;
}

.social {
  margin-bottom: 30px;
}

.social .icon:after {
  color: #111111;
  font-size: 32px;
}

.social .icon.facebook:after {
  content: "";
}

.social .icon.twitter:after {
  content: "";
}

.social .icon.instagram:after {
  content: "";
}

.social .icon.linkedin:after {
  content: "";
}

.social .icon.pinterest:after {
  content: "";
}

.social .icon.xing:after {
  content: "";
}

.social .icon.youtube:after {
  content: "";
}

/* ===================================================================================================================
   Content Type Elements
   ================================================================================================================ */
.content_type-icon-text .float_above {
  margin-bottom: 0;
  margin-top: 80px;
}

.content_type-icon-text h3 {
  margin-bottom: 0;
  padding-bottom: 0;
  text-transform: uppercase;
}

.content_type-icon-text h3:after {
  border-bottom: 0;
  padding-top: 0;
}

.content_type-icon-text figure {
  position: relative;
}

.content_type-icon-text img {
  background-color: #ffffff;
  position: relative;
  z-index: 2;
}

.content_type-icon-text figure:before {
  content: '';
  position: absolute;
  border-bottom: 1px solid #bab6a9;
  width: 100%;
  left: 0;
  top: 40px;
  z-index: 1;
}

.content_type-icon-text .img-circle figure:before {
  content: none;
}

.content_type-icon-text .img-circle .float_above {
  margin-bottom: 20px;
  margin-top: 20px;
}

.content_type-icon-small h3 {
  display: none;
}

@media print, screen and (min-width: 40em) {
  .content_type-icon-small h3 {
    display: block;
  }
}

.content_type-icon-small p:first-of-type {
  display: none;
}

@media print, screen and (min-width: 40em) {
  .content_type-icon-small p:first-of-type {
    display: block;
  }
}

.content_type-icon-small figure {
  float: left;
  width: 10%;
  margin-top: 4px;
}

@media print, screen and (min-width: 40em) {
  .content_type-icon-small figure {
    float: none;
    width: auto;
    margin-top: 0;
  }
}

.content_type-icon-small a {
  width: 87%;
  float: right;
}

@media print, screen and (min-width: 40em) {
  .content_type-icon-small a {
    width: auto;
    float: none;
  }
}

.content_type-logos figure {
  opacity: 0.3;
}

@media print, screen and (min-width: 40em) {
  .content_type-logos figure {
    width: 80%;
    margin-left: 10%;
  }
}

.content_type-logos figure:before {
  content: none;
}

/* ===================================================================================================================
   Beilharz 10 Steps
   ================================================================================================================ */
.page_type-company-10-steps .mod_breadcrumb {
  text-align: center;
}

.page_type-company-10-steps .page_content-wrapper-outside {
  background-image: url("../img/bg_page-company-10-steps_light.png");
  background-repeat: repeat;
}

.page_type-company-10-steps .pagebg-container {
  background-image: none !important;
}

.page_type-company-10-steps .page_content .ce_image .image_container {
  text-align: center;
}

.page_type-company-10-steps .ce_item-content-wrapper .row {
  padding-top: 30px;
  padding-bottom: 30px;
  max-width: 57rem !important;
}

@media print, screen and (min-width: 64em) {
  .page_type-company-10-steps .ce_item-content-wrapper .row {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.page_type-company-10-steps #section1.ce_item-content-wrapper .row {
  padding-top: 80px;
}

@media print, screen and (min-width: 64em) {
  .page_type-company-10-steps #section1.ce_item-content-wrapper .row {
    padding-top: 180px;
  }
}

.arrow-10-steps-right,
.arrow-10-steps-left {
  display: none;
}

@media print, screen and (min-width: 64em) {
  .arrow-10-steps-right,
  .arrow-10-steps-left {
    display: block;
  }
}

.arrow-10-steps-right img {
  float: right;
  position: relative;
  right: 20%;
  width: 90px;
  margin-top: 0;
  margin-bottom: 80px;
}

.arrow-10-steps-left img {
  float: left;
  position: relative;
  left: 20%;
  width: 90px;
  margin-top: 0;
  margin-bottom: 80px;
}

.page_type-company-10-steps #section10 {
  padding-bottom: 120px;
}

/* ===================================================================================================================
   Sonstiges
   ================================================================================================================ */
a.map {
  text-decoration: none !important;
}

a.map:before {
  content: "";
  margin-right: 10px;
}

a.map:before {
  font-size: 1.6875rem;
  margin-right: 5px;
}

a[href$=".pdf"] {
  margin-left: 20px;
  display: inline-block;
}

a[href$=".pdf"]:before {
  content: "";
  margin-right: 10px;
}

a[href$=".pdf"]:before {
  margin-left: -20px;
  margin-top: 3px;
  text-decoration: none !important;
  position: absolute;
}

#google_map, #google_map2, #google_map3 {
  height: 250px;
}

@media print, screen and (min-width: 40em) {
  #google_map, #google_map2, #google_map3 {
    height: 350px;
  }
}

.mod_article .content_box-below h2 {
  font-size: 1.5rem !important;
  font-weight: 900;
}

.page_type-concept .expanded {
  background-color: #f4f3f0;
  padding-top: 80px;
  padding-bottom: 30px;
}

.cta-teaser-box {
  margin-bottom: 30px;
}

.cta-teaser-box h2 {
  font-weight: 700;
  font-size: 2rem;
  padding-top: 0;
  margin-top: 0;
}

.cta-teaser-box h3 {
  font-size: 1.875rem;
  font-weight: 400;
  padding-top: 0;
  margin-top: 0;
}

.cta-teaser-box h3:after {
  content: none;
}

.cta-teaser-box a {
  background-color: #f4f3f0;
  background-color: #e2001a;
  color: #ffffff;
  display: block;
  padding: 15px;
  height: 100%;
  width: 100%;
  position: relative;
  transition: background-color 0.25s ease-out 0s;
}

.cta-teaser-box a:hover {
  background-color: #c90017;
}

.cta-teaser-box a:after {
  content: "";
  position: absolute;
  right: 10px;
  bottom: 15px;
  color: #ffffff;
  display: inline;
  font-size: 1.375rem;
}

.cta-teaser-box p {
  font-weight: 700;
}

.teaser_box {
  display: none;
  visibility: hidden;
  color: #ffffff;
  text-align: right;
  position: relative;
  top: 150px;
  right: -5px;
  z-index: 999;
  height: 0;
  overflow: visible;
}

@media print, screen and (min-width: 40em) {
  .teaser_box {
    display: block;
    visibility: visible;
  }
}

@media print, screen and (min-width: 64em) {
  .teaser_box {
    margin-bottom: 0 !important;
    top: 100px;
  }
}

.teaser-box-inner {
  max-width: 300px;
  display: inline-block;
  position: relative;
  top: 0;
  transform: rotate(-3deg);
}

@media screen and (min-width: 75em) {
  .teaser-box-inner {
    position: absolute;
    right: 0;
  }
}

.teaser-box-inner p {
  background-color: #e2001a;
  font-size: 1.25rem;
  color: #ffffff;
  border: 0 solid #111111;
  padding: 15px 15px 15px 15px;
  z-index: 1000;
  line-height: 1;
  text-transform: uppercase;
  z-index: 99;
  transition: background-color 0.2s linear;
  box-shadow: 3px 3px 4px rgba(33, 33, 33, 0.3);
}

.teaser-box-inner p:hover {
  background-color: #b90015;
}

.teaser-box-inner a {
  color: #ffffff;
  text-decoration: none !important;
  display: inline-block;
}

.teaser-box-inner a:last-child {
  padding-bottom: 0;
}

.teaser_box.teaser_musterhaus .teaser-box-inner {
  max-width: none;
  width: 360px;
}

.teaser_box.teaser_musterhaus .teaser-box-inner a {
  display: block;
  text-align: left;
}

.teaser_box.teaser_musterhaus .teaser-box-inner a:before {
  content: '';
  background-image: url(../img/musterhauspark.svg);
  background-repeat: no-repeat;
  background-size: 90px;
  height: 90px;
  width: 90px;
  display: inline-block;
  position: relative;
  float: left;
  margin-right: 5px;
}

.teaser_box.teaser_musterhaus strong {
  display: block;
  font-weight: 700;
}

/* ===================================================================================================================
   Navigation - Onepage Vertical Fixed
   ================================================================================================================ */
#cd-vertical-nav ul {
  margin: 0;
  list-style: none;
}

.cd-section {
  min-height: 100%;
  position: relative;
}

.cd-nav-trigger {
  display: block;
  z-index: 2;
  position: fixed;
  bottom: 35px;
  right: 0;
  height: 44px;
  width: 50px;
  background: #111111;
  color: #ffffff;
}

.cd-nav-trigger:after {
  content: "";
  margin-left: 10px;
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 11px);
  margin: 0;
  font-size: 22px;
}

.cd-nav-trigger:hover {
  color: #ffffff;
}

.cd-nav-trigger.open:after {
  content: "";
  margin-left: 10px;
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 11px);
  margin: 0;
  font-size: 22px;
}

#cd-vertical-nav {
  position: fixed;
  z-index: 99999;
  right: 50px;
  bottom: 35px;
  width: 90%;
  max-width: 250px;
  max-height: 90%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  transform-origin: right bottom;
  transform: scale(0);
  transition-property: transform;
  transition-duration: 0.2s;
  background-color: rgba(255, 255, 255, 0.9);
}

#cd-vertical-nav a {
  display: block;
  padding: .5em 1em;
  border-bottom: 1px solid rgba(62, 57, 71, 0.1);
  text-transform: uppercase;
}

#cd-vertical-nav.open {
  transform: scale(1);
}

#cd-vertical-nav li:last-child a {
  border-bottom: none;
}

@media screen and (min-width: 75em) {
  .cd-nav-trigger {
    display: none;
    visibility: hidden;
  }
  .is-selected {
    position: relative;
  }
  .cd-dot-wrapper {
    text-transform: uppercase;
  }
  .cd-dot-wrapper span {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 1rem 0;
    padding: 0.85em 1em;
    -webkit-appearance: none;
    border: 1px solid transparent;
    border-radius: 0;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    font-size: 0.9rem;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    background-color: transparent;
    color: #a49e8c;
    padding: 0.5em;
    margin-bottom: 0.15em;
    background-color: #212121;
    color: #ffffff;
  }
  [data-whatinput='mouse'] .cd-dot-wrapper span {
    outline: 0;
  }
  .cd-dot-wrapper span:hover, .cd-dot-wrapper span:focus {
    background-color: #d1cec5;
    color: #a49e8c;
  }
  .is-selected > .cd-dot-wrapper span {
    background-color: #111111;
  }
  #cd-vertical-nav {
    position: fixed;
    right: -2px;
    top: 50%;
    bottom: auto;
    width: auto;
    max-width: none;
    max-height: none;
    overflow-y: visible;
    transform: translateY(-50%);
    background-color: transparent;
    border-radius: 0;
  }
  #cd-vertical-nav li {
    text-align: right;
  }
  #cd-vertical-nav a {
    display: inline-block;
    padding: 0;
    /* prevent weird movements on hover when you use a CSS3 transformation - webkit browsers */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-bottom: 0;
  }
  #cd-vertical-nav a:after {
    content: "";
    display: table;
    clear: both;
  }
  #cd-vertical-nav a span:first-child {
    display: inline-block;
  }
  #cd-vertical-nav a:hover span {
    color: #ffffff;
    background-color: #e2001a;
  }
  #cd-vertical-nav a:hover .cd-label {
    opacity: 1;
  }
  #cd-vertical-nav .cd-dot {
    position: relative;
    /* we set a top value in order to align the dot with the label. If you change label's font, you may need to change this top value*/
    top: 4px;
    left: -2px;
    font-size: 0.875rem;
  }
  #cd-vertical-nav li:nth-of-type(10) .cd-dot {
    left: 1px;
  }
  #cd-vertical-nav .cd-label {
    display: block;
    position: relative;
    margin-top: -25px;
    margin-right: 25px;
    padding: .3em .5em;
    color: #ffffff;
    font-size: 0.8125rem;
    transition: transform 0.2s, opacity 0.2s;
    opacity: 0;
    transform-origin: 100% 50%;
    background-color: #111111;
  }
}

/* ===================================================================================================================
   Navigation - Foundation
   ================================================================================================================ */
@media screen and (max-width: 29.9375em) {
  .no-js .top-bar {
    display: none;
  }
}

@media print, screen and (min-width: 40em) {
  .no-js .title-bar {
    display: none;
  }
}

.title-bar .menu-icon {
  margin: 0 auto;
}

.menu-icon:after {
  box-shadow: 0 6px 0 #ffffff, 0 12px 0 #ffffff;
}

.menu-icon:hover:after {
  box-shadow: 0 6px 0 #cacaca, 0 12px 0 #cacaca;
}

.title-bar {
  position: relative;
  z-index: 999;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  display: block;
}

.title-bar-title {
  font-weight: 400;
}

.title-bar-title:hover {
  cursor: pointer;
}

.nav-wrapper {
  width: 100%;
  transition: position ease 0.5s;
}

.top-bar {
  background-color: #111111;
  position: relative;
  z-index: 999;
  padding: 0;
}

@media print, screen and (min-width: 64em) {
  .top-bar {
    background-color: #ffffff;
    padding-top: 20px;
    float: right;
  }
}

.top-bar .top-bar-right {
  width: 100%;
}

@media print, screen and (min-width: 64em) {
  .top-bar .top-bar-right {
    position: relative;
  }
}

.top-bar a {
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  padding: 0.7rem 1rem;
}

@media print, screen and (min-width: 64em) {
  .top-bar a {
    color: #111111;
    font-size: 0.9375rem;
  }
}

@media screen and (min-width: 75em) {
  .top-bar a {
    font-size: 1rem;
  }
}

.top-bar a.active, .top-bar a.trail {
  font-weight: 900;
  background-color: transparent;
}

@media print, screen and (min-width: 64em) {
  .top-bar a.active, .top-bar a.trail {
    color: #111111;
  }
}

.start-scroll .nav-wrapper {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
}

@media print, screen and (min-width: 64em) {
  .start-scroll .nav-wrapper {
    position: relative;
  }
}

.top-bar .menu.nested {
  background: #111111;
  margin-left: 0;
}

@media print, screen and (min-width: 64em) {
  .top-bar .menu.nested {
    box-shadow: 2px 2px 7px 0 rgba(17, 17, 17, 0.2);
    background: #212121;
  }
}

.top-bar ul ul a {
  color: #ffffff;
  text-transform: none;
  letter-spacing: 0.002em;
  padding: 0.7rem 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

@media print, screen and (min-width: 64em) {
  .top-bar ul ul a {
    border-bottom: 0px solid rgba(255, 255, 255, 0.05);
  }
}

.top-bar ul ul a.active,
.top-bar ul ul a.trail {
  background-color: #e2001a;
  font-weight: 900;
  color: #ffffff;
}

@media print, screen and (min-width: 64em) {
  .top-bar ul ul a.active,
  .top-bar ul ul a.trail {
    background-color: #080808;
  }
}

@media print, screen and (min-width: 64em) {
  .top-bar ul ul a:hover,
  .top-bar ul ul a:focus {
    background-color: black;
  }
}

.top-bar ul.vertical.dropdown {
  background: transparent;
}

.is-dropdown-submenu {
  min-width: 270px;
}

.dropdown.menu.vertical.dropdown > li.is-dropdown-submenu-parent > a:after,
.is-accordion-submenu-parent > a:after {
  border-color: #ffffff transparent transparent;
  border: 0 solid;
}

.dropdown.menu.vertical.dropdown > li.is-dropdown-submenu-parent > a:after,
.is-accordion-submenu-parent > a:after {
  content: "";
  color: #ffffff;
  font-size: .5rem;
  right: 10px;
  top: calc(50% - 4px);
  position: absolute;
  width: 8px;
  height: 8px;
}

@media print, screen and (min-width: 64em) {
  .dropdown.menu.vertical.dropdown > li.is-dropdown-submenu-parent > a:after,
  .is-accordion-submenu-parent > a:after {
    color: #111111;
    top: calc(50% - 2px);
  }
}

.top-bar .accordion-menu {
  background-color: black;
  color: #ffffff;
}

.top-bar .accordion-menu li a {
  color: #ffffff;
}

.top-bar .accordion-menu .is-accordion-submenu-item a {
  padding-left: 25px;
}

/* ===================================================================================================================
   Navigation - Breadcrumb
   ================================================================================================================ */
.mod_breadcrumb {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .mod_breadcrumb {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.mod_breadcrumb ul {
  margin: 0;
  list-style: none;
}

.mod_breadcrumb li {
  display: inline-block;
  font-size: 0.875rem;
}

.mod_breadcrumb li.first {
  display: none;
}

.mod_breadcrumb li:after {
  padding-left: 5px;
  content: '\00BB';
}

.mod_breadcrumb li:last-child:after {
  padding: 0;
  content: '';
}

.mod_breadcrumb a {
  color: #111111;
}

.mod_breadcrumb a:focus,
.mod_breadcrumb a:hover {
  color: #e2001a;
}

body.overflow-hidden {
  overflow: hidden;
}

.page_type-privacy-policy.lightbox .ce_item-content-wrapper {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.nivo-lightbox-theme-default.nivo-lightbox-overlay {
  background: rgba(17, 17, 17, 0.9);
}

.nivo-lightbox-theme-default .nivo-lightbox-nav:focus,
.nivo-lightbox-theme-default .nivo-lightbox-nav:hover {
  background-color: transparent;
}

.nivo-lightbox-theme-default .nivo-lightbox-prev {
  background-size: 32px 32px;
  background-repeat: no-repeat;
  background-image: url("../img/icon_arrow-left-white-1.svg");
  border-radius: 0;
}

.nivo-lightbox-theme-default .nivo-lightbox-next {
  background-size: 32px 32px;
  background-repeat: no-repeat;
  background-image: url("../img/icon_arrow-right-white-1.svg");
  border-radius: 0;
}

.nivo-lightbox-close {
  position: absolute;
  top: 2%;
  right: 2%;
}

.nivo-lightbox-theme-default .nivo-lightbox-close {
  display: block;
  width: 32px;
  height: 32px;
  padding: 0;
  background-size: 32px 32px;
  background-repeat: no-repeat;
  background-image: url("../img/icon_close-white-1.svg");
}

.nivo-lightbox-theme-default .nivo-lightbox-title {
  display: inline-block;
  padding: 5px;
  color: #ffffff;
  background: #111111;
  border-radius: 0;
}

.nivo-lightbox-content iframe {
  background: #ffffff;
}

/* ===================================================================================================================
   Slider - Caroufredsel (Erweiterung)
   ================================================================================================================ */
.no-js .ce_caroufredsel_gallery {
  height: 225px;
}

.js .ce_caroufredsel_gallery {
  height: auto;
}

.no-js .ce_caroufredsel_gallery .caroufredsel_controls,
.no-js .ce_caroufredsel_gallery .image_container {
  display: none;
}

.js .ce_caroufredsel_gallery .caroufredsel_controls,
.js .ce_caroufredsel_gallery .image_container {
  display: block;
}

.ce_caroufredsel_gallery {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}

.ce_caroufredsel_gallery .caroufredsel_skin {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .ce_caroufredsel_gallery .caroufredsel_skin {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.ce_caroufredsel_gallery .caroufredsel_skin {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
}

.ce_caroufredsel_gallery {
  margin-bottom: 30px;
}

.ce_caroufredsel_gallery .image_container {
  margin: 1px 15px 1px 15px;
  box-shadow: 2px 2px 7px rgba(33, 33, 33, 0.4);
  background-color: #ffffff;
}

@media screen and (min-width: 81.25em) {
  .ce_caroufredsel_gallery .caroufredsel_controls {
    position: absolute;
    top: calc(50% - 32px);
    left: 0;
    width: 100%;
  }
}

.ce_caroufredsel_gallery .caroufredsel_navi {
  position: relative;
  height: 42px;
}

@media screen and (min-width: 81.25em) {
  .ce_caroufredsel_gallery .caroufredsel_navi {
    height: 0;
  }
}

.ce_caroufredsel_gallery .caroufredsel_navi a.caroufredsel_prev,
.ce_caroufredsel_gallery .caroufredsel_navi a.caroufredsel_next {
  position: absolute;
  top: 10px;
  width: 32px;
  height: 32px;
  font-size: 32px;
  color: #f4f3f0;
}

.ce_caroufredsel_gallery .caroufredsel_navi a.caroufredsel_prev:hover,
.ce_caroufredsel_gallery .caroufredsel_navi a.caroufredsel_next:hover {
  color: #ffffff;
}

.ce_caroufredsel_gallery .caroufredsel_navi a.caroufredsel_prev.disabled,
.ce_caroufredsel_gallery .caroufredsel_navi a.caroufredsel_next.disabled,
.ce_caroufredsel_gallery .caroufredsel_navi a.caroufredsel_prev.disabled:hover,
.ce_caroufredsel_gallery .caroufredsel_navi a.caroufredsel_next.disabled:hover {
  color: rgba(244, 243, 240, 0.3);
  cursor: default;
}

.ce_caroufredsel_gallery .caroufredsel_navi a.caroufredsel_prev {
  left: calc(50% - 40px);
}

.ce_caroufredsel_gallery .caroufredsel_navi a.caroufredsel_prev:before {
  content: "";
  margin-right: 10px;
}

@media screen and (min-width: 81.25em) {
  .ce_caroufredsel_gallery .caroufredsel_navi a.caroufredsel_prev {
    left: -46px;
  }
}

.ce_caroufredsel_gallery .caroufredsel_navi a.caroufredsel_next {
  right: calc(50% - 40px);
}

.ce_caroufredsel_gallery .caroufredsel_navi a.caroufredsel_next:before {
  content: "";
  margin-right: 10px;
}

@media screen and (min-width: 81.25em) {
  .ce_caroufredsel_gallery .caroufredsel_navi a.caroufredsel_next {
    right: -38px;
  }
}

.ce_caroufredsel_gallery .caption {
  display: none;
}

.ce_caroufredsel_gallery .image_container a {
  display: block;
  position: relative;
  height: 100%;
}

.ce_caroufredsel_gallery .image_container a:after {
  background: rgba(17, 17, 17, 0) none no-repeat scroll center center/2rem 2rem;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: background 0.3s ease 0s;
  width: 100%;
  z-index: 2;
}

.ce_caroufredsel_gallery .image_container a:hover:after {
  background: rgba(17, 17, 17, 0.7) no-repeat scroll center center/2rem 2rem;
}

.ce_caroufredsel_gallery .image_container a:before {
  content: "";
  color: #ffffff;
  font-size: 20px;
  left: 0;
  top: 48%;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 99;
  opacity: 0;
  transition: opacity 0.3s ease 0s;
  align-self: center;
}

.ce_caroufredsel_gallery .image_container a:hover:before {
  opacity: 1;
}

.ce_gallery > ul {
  margin: 0;
  list-style-type: none;
}

.ajax_gallery > ul {
  margin: 0;
  list-style-type: none;
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}

.ce_gallery {
  padding-bottom: 30px;
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.ce_gallery .ajax_gallery > ul li,
.ce_gallery > ul li {
  padding-bottom: 30px;
}

.ce_gallery a, .ce_gallery span {
  display: block;
  position: relative;
  height: 100%;
}

.ce_gallery a:after {
  background: rgba(17, 17, 17, 0) none no-repeat scroll center center/2rem 2rem;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: background 0.3s ease 0s;
  width: 100%;
  z-index: 2;
}

.ce_gallery .image_container a:hover:after {
  background: rgba(17, 17, 17, 0.7) no-repeat scroll center center/2rem 2rem;
}

.ce_gallery .image_container a:before {
  content: "";
  color: #ffffff;
  font-size: 20px;
  left: 0;
  top: 45%;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 99;
  opacity: 0;
  transition: opacity 0.3s ease 0s;
  align-self: center;
}

.ce_gallery .image_container a:hover:before {
  opacity: 1;
}

.ce_gallery .pagination a:after,
.ce_gallery .pagination span:after {
  background: rgba(17, 17, 17, 0) none no-repeat scroll center center/2rem 2rem;
}

.ce_gallery .pagination a:before,
.ce_gallery .pagination span:before {
  content: none;
  opacity: 0;
}

.ce_gallery a img {
  box-shadow: 2px 2px 7px rgba(33, 33, 33, 0.2);
}

.ajax_gallery .pagination {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .ajax_gallery .pagination {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.ajax_gallery .ajax_gallery__wrapper-image {
  border: 1px solid #f4f3f0;
  box-shadow: 2px 2px 7px 0 rgba(230, 230, 230, 0.75);
}

.ajax_gallery .image_container {
  position: relative;
}

.ajax_gallery .ajax_gallery__wrapper-figcaption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  max-width: 100%;
  padding: 10px;
  text-transform: uppercase;
  background-color: rgba(255, 255, 255, 0.65);
}

.ce_gallery figcaption,
.ajax_gallery figcaption {
  font-size: 0.75rem;
  line-height: 1.25;
  display: none;
}

/* ===================================================================================================================
   News
   ================================================================================================================ */
.mod_newslist {
  max-width: 100%;
  flex: 0 0 100%;
}

.mod_newslist {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}

.mod_newslist .more a {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  color: #a49e8c;
  text-decoration: none;
}

[data-whatinput='mouse'] .mod_newslist .more a {
  outline: 0;
}

.mod_newslist .more a:hover, .mod_newslist .more a:focus {
  background-color: #d1cec5;
  color: #a49e8c;
}

.mod_newslist .pagination {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .mod_newslist .pagination {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.page_type-home .mod_newslist h4 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .page_type-home .mod_newslist h4 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.page_type-home .mod_newslist .layout_short {
  transition: background-color 0.25s ease-out 0s;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.page_type-home .mod_newslist .layout_short:hover {
  background: #e2001a;
  color: #ffffff;
}

.mod_newslist .layout_short a {
  display: block;
  height: 100%;
}

.mod_newslist .layout_short .more {
  text-decoration: underline;
}

.mod_newslist .layout_short h2, .mod_newslist .layout_short h2 a {
  font-size: 1.5rem;
  position: relative;
  font-weight: 300;
  padding-bottom: 0;
}

.mod_newslist .layout_short h2 a:hover,
.mod_newslist .layout_short:hover h2,
.mod_newslist .layout_short:hover .teaser {
  color: #ffffff;
}

.mod_newslist .layout_short:nth-child(2),
.mod_newslist .layout_short:nth-child(3) {
  margin-bottom: 15px;
  margin-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #d1cec5;
}

@media print, screen and (min-width: 40em) {
  .mod_newslist .layout_short:nth-child(2),
  .mod_newslist .layout_short:nth-child(3) {
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.content_type-news .mod_newslist {
  padding: 0;
}

.content_type-news {
  padding-top: 15px;
  padding-bottom: 15px;
}

@media print, screen and (min-width: 40em) {
  .content_type-news {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

p.more {
  font-weight: 300;
}

.more a {
  font-weight: 400;
  font-size: 1rem;
}

.button-back {
  margin-top: 2em;
}

.news_list.layout-1 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
  margin-bottom: 2em;
}

@media print, screen and (min-width: 40em) {
  .news_list.layout-1 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media print, screen and (min-width: 40em) {
  .news_list.layout-1 {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    max-width: 50%;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .news_list.layout-1 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media print, screen and (min-width: 64em) {
  .news_list.layout-1 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    max-width: 33.33333%;
  }
}

@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .news_list.layout-1 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.news_list.layout-1 .news_list__wrapper-outside {
  background-color: #f4f3f0;
  height: 100%;
  box-shadow: 2px 2px 7px rgba(33, 33, 33, 0.2);
  position: relative;
}

.news_list.layout-1 .image_container {
  position: relative;
  display: block;
  height: 100%;
}

.news_list.layout-1 .news_list__content {
  position: relative;
  padding: 0 30px 30px 30px;
}

.news_list__metadata {
  position: absolute;
  z-index: 2;
  background-color: #e2001a;
  top: -35px;
  box-shadow: 2px 2px 7px rgba(33, 33, 33, 0.4);
  color: #ffffff;
  display: inline-block;
  font-size: 1.125rem;
  font-weight: 400;
  padding: 0.15rem 0.5rem;
  right: -6px;
  text-transform: uppercase;
  transform: rotate(-3deg);
}

.news_list.layout-1 .hover {
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  background-color: rgba(17, 17, 17, 0);
  transition: background-color 0.2s ease;
}

.news_list.layout-1:hover .hover {
  background-color: rgba(17, 17, 17, 0.2);
}

.news_list .more a {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  color: #a49e8c;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #d1cec5;
  margin-bottom: 0;
}

[data-whatinput='mouse'] .news_list .more a {
  outline: 0;
}

.news_list .more a:hover, .news_list .more a:focus {
  background-color: #d1cec5;
  color: #a49e8c;
}

.news_list .more a:hover {
  color: #ffffff;
}

.news_reader.layout-1 .caroufredsel_skin {
  padding-left: 0;
  padding-right: 0;
}

.ce_text.bg-color-lightgrey {
  padding: 30px;
}

.ce_text.bg-color-lightgrey h3 {
  text-transform: none;
}

/* ===================================================================================================================
   Formulare
   ================================================================================================================ */
.page_type-contact .ce_form {
  margin: 1em 0;
  background-color: #f4f3f0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 2px 2px 7px rgba(33, 33, 33, 0.4);
}

.page_type-contact .formbody,
.page_type-module-house-balance .formbody {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}

.page_type-contact .formbody .widget {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
  text-align: left;
}

@media print, screen and (min-width: 40em) {
  .page_type-contact .formbody .widget {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media print, screen and (min-width: 40em) {
  .page_type-contact .formbody .widget {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    max-width: 50%;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .page_type-contact .formbody .widget {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.page_type-contact .formbody .widget-explanation {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .page_type-contact .formbody .widget-explanation {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.page_type-contact .formbody .widget:nth-child(9),
.page_type-contact .formbody .widget:nth-child(10),
.page_type-contact .formbody .widget:nth-child(11),
.page_type-contact .formbody .widget:nth-child(12),
.page_type-contact .formbody .widget:nth-child(13),
.page_type-contact .formbody .widget:nth-child(15),
.page_type-contact .formbody .widget:nth-child(16),
.page_type-contact .formbody .submit_container {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .page_type-contact .formbody .widget:nth-child(9),
  .page_type-contact .formbody .widget:nth-child(10),
  .page_type-contact .formbody .widget:nth-child(11),
  .page_type-contact .formbody .widget:nth-child(12),
  .page_type-contact .formbody .widget:nth-child(13),
  .page_type-contact .formbody .widget:nth-child(15),
  .page_type-contact .formbody .widget:nth-child(16),
  .page_type-contact .formbody .submit_container {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media print, screen and (min-width: 40em) {
  .page_type-contact .formbody .widget:nth-child(9),
  .page_type-contact .formbody .widget:nth-child(10),
  .page_type-contact .formbody .widget:nth-child(11),
  .page_type-contact .formbody .widget:nth-child(12),
  .page_type-contact .formbody .widget:nth-child(13),
  .page_type-contact .formbody .widget:nth-child(15),
  .page_type-contact .formbody .widget:nth-child(16),
  .page_type-contact .formbody .submit_container {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    max-width: 100%;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .page_type-contact .formbody .widget:nth-child(9),
  .page_type-contact .formbody .widget:nth-child(10),
  .page_type-contact .formbody .widget:nth-child(11),
  .page_type-contact .formbody .widget:nth-child(12),
  .page_type-contact .formbody .widget:nth-child(13),
  .page_type-contact .formbody .widget:nth-child(15),
  .page_type-contact .formbody .widget:nth-child(16),
  .page_type-contact .formbody .submit_container {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.formbody a {
  text-decoration: underline;
}

p.error {
  font-size: 0.75rem;
  font-weight: 700;
  color: #e2001a;
  margin-bottom: 0.25em;
}

.submit {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  color: #a49e8c;
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #d1cec5;
}

[data-whatinput='mouse'] .submit {
  outline: 0;
}

.submit:hover, .submit:focus {
  background-color: #d1cec5;
  color: #a49e8c;
}

.submit:hover {
  color: #ffffff;
}

legend, .widget-select label {
  font-weight: 900;
  width: 100%;
  font-size: 100%;
}

.widget-checkbox span,
.widget-radio span {
  display: block;
}

@media print, screen and (min-width: 40em) {
  .widget-checkbox span,
  .widget-radio span {
    display: inline-block;
  }
}

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline;
}

.mandatory .mandatory {
  display: inline;
}

textarea {
  min-height: 150px;
}

.content_type-modulbox-form .toggler {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  color: #a49e8c;
  display: block;
  width: 300px;
  margin: 0.5em auto;
  margin-bottom: 2.5em;
  overflow: hidden;
  font-size: 1rem;
  padding: 0.5em 1em 0.85em 1em;
  text-transform: uppercase;
}

[data-whatinput='mouse'] .content_type-modulbox-form .toggler {
  outline: 0;
}

.content_type-modulbox-form .toggler:hover, .content_type-modulbox-form .toggler:focus {
  background-color: #d1cec5;
  color: #a49e8c;
}

.content_type-modulbox-form .ui-accordion-header-icon:before {
  display: inline-block;
  content: "";
  position: relative;
  color: #ffffff;
  top: 3px;
  margin-right: 2px;
  font-size: 1.25rem;
}

.content_type-modulbox-form .active .ui-accordion-header-icon:before {
  content: "";
}

.content_type-modulbox-form [type="text"],
.content_type-modulbox-form [type="email"],
.content_type-modulbox-form [type="tel"],
.content_type-modulbox-form textarea {
  background-color: #f8f8f6;
}

.content_type-modulbox-form textarea {
  height: 150px;
}

.form_data-protection {
  text-align: left;
}

.form_mandatory-note-1 p,
.form_mandatory-note-2 p {
  font-size: 90%;
}

.form_contact-mini .formbody {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}

.form_contact-mini .formbody .form_division-1-12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .form_contact-mini .formbody .form_division-1-12 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.form_contact-mini .formbody .form_division-2-66 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .form_contact-mini .formbody .form_division-2-66 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media print, screen and (min-width: 40em) {
  .form_contact-mini .formbody .form_division-2-66 {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    max-width: 50%;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .form_contact-mini .formbody .form_division-2-66 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.form_contact-mini .formbody textarea {
  min-height: 203px;
}

.form_contact-mini .formbody .widget-radio {
  text-align: left;
}

.form_contact-mini .formbody .widget-radio span {
  display: block;
}

.form_contact-mini .formbody .submit {
  width: 100%;
  margin: 0;
  padding: 0.66em 1em;
}

.agreement_data_storage {
  text-align: left;
}

.agreement_data_storage [type='checkbox'] {
  margin-bottom: 0;
  line-height: 1;
}

.form_mandatory-note-1 p,
.form_mandatory-note-2 p {
  margin: 1rem 0;
  font-size: 90%;
  text-align: left;
}

.widget-upload .file-upload {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  color: #a49e8c;
  position: relative;
  padding-right: 2.875rem;
  background: transparent;
  border: 1px solid #d1cec5;
  color: #d1cec5;
  font-size: 1rem;
  text-align: left;
  text-decoration: none;
  width: 100%;
  margin-bottom: 1rem;
  display: block;
  line-height: 21px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
}

[data-whatinput='mouse'] .widget-upload .file-upload {
  outline: 0;
}

.widget-upload .file-upload:hover, .widget-upload .file-upload:focus {
  background-color: #d1cec5;
  color: #a49e8c;
}

.widget-upload .file-upload:after {
  content: "";
  position: absolute;
  top: calc(50% - 7px);
  right: 1rem;
  font-size: 14px;
  color: #d1cec5;
}

.widget-upload .file-upload.full-width {
  width: 100%;
}

.widget-upload .file-upload:focus, .widget-upload .file-upload:hover {
  background: #d1cec5;
  color: #111111;
}

.widget-upload .file-upload:hover:after {
  color: #111111;
}

.widget-upload .file-upload:after {
  content: "";
}

/* ===================================================================================================================
   Booknav
   ================================================================================================================ */
.mod_booknav {
  background: #ffffff;
  font-size: 1.125rem;
  text-transform: uppercase;
}

.mod_booknav .row {
  margin-bottom: 0;
}

.mod_booknav ul {
  margin-bottom: 0;
}

.mod_booknav li {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
  border-bottom: 1px solid #f4f3f0;
  list-style: none;
  text-align: center;
  transition: background 0.25s ease-out 0s;
}

@media print, screen and (min-width: 40em) {
  .mod_booknav li {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media print, screen and (min-width: 64em) {
  .mod_booknav li {
    border-bottom: 0 solid #f4f3f0;
    text-align: left;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    max-width: 33.33333%;
  }
}

@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .mod_booknav li {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.mod_booknav li.next {
  border-bottom: 0 solid #f4f3f0;
}

@media print, screen and (min-width: 64em) {
  .mod_booknav li.next {
    text-align: right;
  }
}

@media print, screen and (min-width: 64em) {
  .mod_booknav li.up {
    border-left: 1px solid #f4f3f0;
    border-right: 1px solid #f4f3f0;
  }
}

.mod_booknav a {
  padding-top: 15px;
  padding-bottom: 15px;
  display: block;
  width: 100%;
  max-width: 100%;
  float: right;
}

@media print, screen and (min-width: 64em) {
  .mod_booknav a {
    max-width: 18rem;
  }
}

.mod_booknav li.next a {
  float: left;
}

.mod_booknav li.up a {
  float: none;
  text-align: center;
  width: 100%;
  max-width: 100%;
}

.mod_booknav li.prev a:before {
  content: "";
  position: relative;
  margin-right: 5px;
  display: inline-block;
  font-size: 0.875rem;
}

.mod_booknav li.next a:after {
  content: "";
  position: relative;
  margin-left: 5px;
  display: inline-block;
  font-size: 0.875rem;
}

.mod_booknav li.active:hover {
  background: #e2001a;
}

.mod_booknav li:hover a {
  color: #ffffff;
}

.mod_booknav.booknav-back li {
  border: 0;
}

/* ===================================================================================================================
   Pagination
   ================================================================================================================ */
.pagination p,
.pagination ul li.next,
.pagination ul li.last,
.pagination ul li.first,
.pagination ul li.previous {
  display: none !important;
  visibility: hidden;
}

.pagination {
  text-align: right;
}

.pagination ul {
  display: inline-block;
}

.pagination li {
  display: inline-block;
  margin-left: 5px;
}

.pagination li:first-child {
  margin-left: 0;
}

.pagination li a,
.pagination li span {
  border: 1px solid #111111;
  padding: 5px 10px;
}

.pagination li a {
  text-decoration: none;
}

.pagination li span {
  color: #ffffff;
  background: #111111;
}

.pagination li a:hover,
.pagination li a:focus {
  color: #ffffff;
  background: #e2001a;
}

a.nav-modal-button {
  text-align: right;
  text-transform: uppercase;
  display: block;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  margin-right: 1.5em;
}

@media print, screen and (min-width: 40em) {
  a.nav-modal-button {
    float: right;
    text-align: right;
    margin-top: 1.5em;
    margin-bottom: 0;
  }
}

a.nav-modal-button span {
  letter-spacing: 0.05em;
}

.modal-nav-wrapper .menu-icon {
  margin-left: 0.5em;
}

.modal-nav-wrapper .menu-icon:after, .modal-nav-wrapper .menu-icon:hover:after {
  box-shadow: 0 6px 0 #2f3132, 0 12px 0 #2f3132;
  background: #2f3132;
}

#nav-modal {
  height: 100%;
  width: 100%;
  top: 0 !important;
}

#nav-modal.reveal {
  background: rgba(244, 243, 240, 0.95);
  max-width: none;
}

#nav-modal ul {
  list-style: none;
  width: 100%;
  margin-left: 0;
  margin-bottom: 0;
}

#nav-modal li {
  display: block;
  text-align: center;
}

#nav-modal a {
  color: #2f3132;
}

#nav-modal .primary-menu {
  padding-top: 7%;
}

@media print, screen and (min-width: 64em) {
  #nav-modal .primary-menu {
    padding-top: 15%;
  }
}

#nav-modal .primary-menu li {
  margin-bottom: 1em;
}

@media print, screen and (min-width: 64em) {
  #nav-modal .primary-menu li {
    margin-bottom: 1.5em;
  }
}

#nav-modal .primary-menu a {
  font-size: 1.25rem;
}

@media print, screen and (min-width: 64em) {
  #nav-modal .primary-menu a {
    font-size: 1.625rem;
  }
}

#nav-modal .primary-menu img {
  width: 40px;
  margin-left: -20px;
  margin-right: 10px;
}

@media print, screen and (min-width: 64em) {
  #nav-modal .primary-menu img {
    width: 60px;
  }
}

.nav-modal-logo .columns {
  border-top: 1px solid #212121;
  padding-top: 2rem;
  margin-top: 2rem;
}

@media print, screen and (min-width: 64em) {
  .nav-modal-logo .columns {
    padding-top: 3rem;
    margin-top: 4rem;
  }
}

#nav-modal .secondary-menu {
  padding-top: 2rem;
}

@media print, screen and (min-width: 64em) {
  #nav-modal .secondary-menu {
    padding-top: 2rem;
  }
}

#nav-modal .secondary-menu li {
  padding-bottom: 0.5rem;
}

@media print, screen and (min-width: 64em) {
  #nav-modal .secondary-menu li {
    padding-bottom: 0;
  }
}

#nav-modal .secondary-menu li a:before {
  content: "";
  top: 3px;
  margin-right: 5px;
  color: #e2001a;
  display: inline;
  font-size: 0.75rem;
}

#nav-modal a:hover,
#nav-modal a:focus,
#nav-modal a:active,
#nav-modal .active a,
#nav-modal a.trail {
  color: #111111;
}

/* ===================================================================================================================
   Special Styles Landingpages
   ================================================================================================================ */
.page_type-landingpage #enter {
  position: relative;
}

.page_type-landingpage .page_header .logo_company {
  text-align: left;
  padding-left: 1.25rem;
}

@media print, screen and (min-width: 40em) {
  .page_type-landingpage .page_header .logo_company {
    text-align: left;
  }
}

.page_type-landingpage .ce_gallery {
  margin-top: 3em;
  margin-bottom: 3em;
}

.img-frame-left img {
  border: 5px solid #ffffff;
  box-shadow: 2px 2px 7px rgba(33, 33, 33, 0.2);
  transform: rotate(-3deg);
  margin-bottom: 3em;
}

.img-frame-right img {
  border: 5px solid #ffffff;
  box-shadow: 2px 2px 7px rgba(33, 33, 33, 0.2);
  transform: rotate(3deg);
  margin-bottom: 3em;
}

.page_type-landingpage legend {
  font-weight: 400;
}

.page_type-landingpage .ce_form {
  margin-top: 30px;
}

.page_type-landingpage .slogan .image_container {
  margin: 0 auto;
}

@media print, screen and (min-width: 30em) {
  .page_type-landingpage .slogan .image_container {
    width: 50%;
  }
}

@media print, screen and (min-width: 40em) {
  .page_type-landingpage-1 .nature-section-1 .sustainability-text {
    order: 2;
  }
}

@media print, screen and (min-width: 64em) {
  .page_type-landingpage-1 .nature-section-1 .sustainability-text {
    order: 1;
  }
}

@media print, screen and (min-width: 40em) {
  .page_type-landingpage-1 .nature-section-1 .sustainability-image {
    order: 1;
  }
}

@media print, screen and (min-width: 64em) {
  .page_type-landingpage-1 .nature-section-1 .sustainability-image {
    order: 2;
  }
}

@media print, screen and (min-width: 64em) {
  .page_type-landingpage-1 .nature-section-2 .stability-text {
    order: 2;
  }
}

@media print, screen and (min-width: 64em) {
  .page_type-landingpage-1 .nature-section-2 .stability-image {
    order: 1;
  }
}

@media print, screen and (min-width: 40em) {
  .page_type-landingpage-1 .nature-section-2 .quality-text {
    order: 2;
  }
}

@media print, screen and (min-width: 64em) {
  .page_type-landingpage-1 .nature-section-2 .quality-text {
    order: 4;
  }
}

@media print, screen and (min-width: 40em) {
  .page_type-landingpage-1 .nature-section-2 .quality-image {
    order: 1;
  }
}

@media print, screen and (min-width: 64em) {
  .page_type-landingpage-1 .nature-section-2 .quality-image {
    order: 3;
  }
}

@media print, screen and (min-width: 40em) {
  .sustainability-image,
  .stability-image,
  .quality-image,
  .health-image {
    margin-top: 20px;
  }
}

@media print, screen and (min-width: 64em) {
  .sustainability-image,
  .stability-image,
  .quality-image,
  .health-image {
    margin-top: 0;
  }
}

/* ===================================================================================================================
   RSCE 1-001 - CE Item - Intro Text (Template: rsce_1_001_intro_text)
   ================================================================================================================ */
.ce_item-intro-text {
  height: calc(80vh - 119px);
  position: relative;
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}

@media print, screen and (min-width: 64em) {
  .ce_item-intro-text {
    height: calc(95vh - 78px);
  }
}

@media print, screen and (min-width: 64em) {
  .ios .ce_item-intro-text {
    height: calc(85vh - 78px);
  }
}

.page_type-intro-small .ce_item-intro-text {
  height: calc(65vh - 119px);
  position: relative;
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}

@media print, screen and (min-width: 64em) {
  .page_type-intro-small .ce_item-intro-text {
    height: calc(45vh - 78px);
  }
}

.ce_item-intro-text__headline {
  font-weight: 300;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .ce_item-intro-text__headline {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.ce_item-intro-text__headline {
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 13%;
  display: block;
  opacity: 1;
}

.start-scroll .ce_item-intro-text__headline h1 {
  opacity: 0;
  transition: opacity 1s linear;
}

.ce_item-intro-text__headline h1 {
  hyphens: none;
  color: #ffffff;
  text-shadow: 0 0 30px rgba(17, 17, 17, 0.5);
}

@media print, screen and (min-width: 64em) {
  .ce_item-intro-text__headline h1 {
    position: relative;
    margin: 0 auto;
    width: 600px;
  }
}

.ce_item-intro-text__headline h1 span {
  font-size: 1.875rem;
  display: block;
}

.ce_item-intro-text .link_enter-wrapper {
  display: block;
  position: absolute;
  bottom: -13px;
  width: 100%;
}

@media print, screen and (min-width: 64em) {
  .ce_item-intro-text .link_enter-wrapper {
    bottom: -23px;
  }
}

.page_type-intro-small .link_enter-wrapper {
  display: none;
}

.ce_item-intro-text a.link_enter {
  font-size: 1.5625rem;
  height: 35px;
  width: 36px;
  background: #e2001a;
  margin: 0 auto;
  padding: 2px 6px;
  color: #ffffff;
  display: block;
  z-index: 50;
}

.ce_item-intro-text a.link_enter:before {
  content: "";
  margin-right: 10px;
}

@media print, screen and (min-width: 40em) {
  .ce_item-intro-text a.link_enter {
    font-size: 2.25rem;
    height: 45px;
    width: 46px;
    padding: 0 5px;
  }
}

/* ===================================================================================================================
   RSCE 1-001 - CE Item - Intro Text (Template: rsce_1_001_intro_text)
   ================================================================================================================ */
.ce_item-intro-text2 {
  position: relative;
  height: calc(100vh - 78px);
}

.ce_item-intro-text2 .inside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ce_item-intro-text2 .inside .headline,
.ce_item-intro-text2 .inside .addition {
  max-width: 75rem;
  width: 100%;
  padding: 0 1.25rem;
  color: #ffffff;
  text-shadow: 0 0 30px rgba(17, 17, 17, 0.5);
}

.ce_item-intro-text2 .inside .addition p {
  line-height: 1;
  font-size: 1.875rem;
}

.ce_item-intro-text2 .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../beilharz/lp/musterhauspark/silhouette.svg");
  background-size: contain;
  background-position: 50% 100%;
  background-repeat: no-repeat;
}

/* ===================================================================================================================
   RSCE 1-001 - CE Item - Intro Text (Template: rsce_1_001_intro_text-box)
   ================================================================================================================ */
.ce_item-intro-text-box-wrapper {
  height: calc(100vh - 78px);
}

.ce_item-intro-text-box {
  height: 80vh;
  position: relative;
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}

@media print, screen and (min-width: 64em) {
  .ce_item-intro-text-box {
    height: 95vh;
  }
}

@media print, screen and (min-width: 64em) {
  .ios .ce_item-intro-text-box {
    height: 85vh;
  }
}

.page_type-intro-small .ce_item-intro-text-box {
  height: 65vh;
  position: relative;
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}

@media print, screen and (min-width: 64em) {
  .page_type-intro-small .ce_item-intro-text-box {
    height: 45vh;
  }
}

.ce_item-intro-text-box__addition {
  color: #ffffff;
  font-size: 1.625rem;
}

.ce_item-intro-text-box__headline {
  font-weight: 300;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .ce_item-intro-text-box__headline {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.ce_item-intro-text-box__headline {
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 13%;
  display: block;
  opacity: 1;
}

@media print, screen and (min-width: 64em) {
  .ce_item-intro-text-box__headline {
    margin-top: 25%;
    position: relative;
    text-align: left;
    bottom: 0;
  }
}

.start-scroll .ce_item-intro-text-box__headline {
  opacity: 0;
  transition: opacity 1s linear;
}

.ce_item-intro-text-box__headline h1 {
  font-family: 'Freehand521W01-Regular', cursive;
  letter-spacing: normal;
  letter-spacing: -0.02em;
  hyphens: none;
  color: #ffffff;
  text-shadow: 0 0 30px rgba(17, 17, 17, 0.5);
}

@media print, screen and (min-width: 64em) {
  .ce_item-intro-text-box__headline h1 {
    position: relative;
    width: 600px;
  }
}

.ce_item-intro-text-box__subheadline {
  background-color: #e2001a;
  color: #ffffff;
  display: inline-block;
  padding: 3px 10px;
  font-weight: 700;
  margin-top: 0.5em;
}

.ce_item-intro-text-box .link_enter-wrapper {
  display: block;
  position: absolute;
  bottom: -13px;
  width: 100%;
}

@media print, screen and (min-width: 64em) {
  .ce_item-intro-text-box .link_enter-wrapper {
    bottom: -23px;
  }
}

.ce_item-intro-text-box__boxwrapper {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
}

@media print, screen and (min-width: 64em) {
  .ce_item-intro-text-box__boxwrapper {
    position: relative;
    background-color: transparent;
    margin-top: -425px;
  }
}

.ce_item-intro-text-box__contentwrapper {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .ce_item-intro-text-box__contentwrapper {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media print, screen and (min-width: 64em) {
  .ce_item-intro-text-box__contentwrapper {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    max-width: 50%;
    margin-left: 50%;
  }
}

@media print, screen and (min-width: 64em) and (min-width: 40em) {
  .ce_item-intro-text-box__contentwrapper {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.ce_item-intro-text-box__contentwrapper .image_container {
  margin-top: 2em;
  text-align: center;
  position: relative;
}

@media print, screen and (min-width: 64em) {
  .ce_item-intro-text-box__contentwrapper .image_container {
    text-align: right;
  }
}

.ce_item-intro-text-box__contentwrapper img {
  width: 150px;
  border-radius: 50%;
}

@media print, screen and (min-width: 64em) {
  .ce_item-intro-text-box__contentwrapper img {
    position: absolute;
    top: -80px;
    right: -30px;
  }
}

.ce_item-intro-text-box__content {
  background-color: #ffffff;
  text-align: center;
}

@media print, screen and (min-width: 64em) {
  .ce_item-intro-text-box__content {
    text-align: left;
    padding: 1rem 4rem 1rem 2rem;
  }
}

.ce_item-intro-text-box__content h3 {
  font-family: 'Freehand521W01-Regular', cursive;
  letter-spacing: normal;
  letter-spacing: -0.02em;
}

.ce_item-intro-text-box__content h3:after {
  content: none;
}

.page_type-intro-small .link_enter-wrapper {
  display: none;
}

.ce_item-intro-text-box a.link_enter {
  font-size: 1.5625rem;
  height: 35px;
  width: 36px;
  background: #e2001a;
  margin-right: 0;
  margin: 0 auto;
  padding: 2px 6px;
  color: #ffffff;
  display: block;
  position: relative;
  z-index: 50;
}

.ce_item-intro-text-box a.link_enter:before {
  content: "";
  margin-right: 10px;
}

@media print, screen and (min-width: 40em) {
  .ce_item-intro-text-box a.link_enter {
    font-size: 2.25rem;
    height: 45px;
    width: 46px;
    padding: 0 5px;
  }
}

/* ===================================================================================================================
   RSCE 1-010 - CE Item - Teaser (Template: rsce_1_001_teaser)
   ================================================================================================================ */
.ce_item-teaser {
  padding-top: 120px;
  padding-bottom: 120px;
}

.ce_item-teaser .columns {
  margin-bottom: 30px;
}

.ce_item-teaser .columns > div, .ce_item-teaser .columns > a {
  height: 100%;
  background-color: #f4f3f0;
  position: relative;
  text-align: center;
  box-shadow: 2px 2px 7px rgba(33, 33, 33, 0.4);
}

.ce_item-teaser .columns > a {
  display: block;
  width: 100%;
}

.ce_item-teaser .content {
  padding: 2.5rem 2rem 0.5rem 2rem;
}

.ce_item-teaser h2 {
  text-transform: uppercase;
  font-size: 1.875rem;
  font-weight: 300;
  padding-bottom: 0;
  hyphens: auto;
}

.ce_item-teaser h3 {
  font-family: 'Freehand521W01-Regular', cursive;
  letter-spacing: normal;
  letter-spacing: -0.02em;
  padding-top: 0;
  padding-bottom: 15px;
}

.ce_item-teaser h3:after {
  content: none;
}

.ce_item-teaser .image_container {
  position: relative;
}

.ce_item-teaser img {
  width: 100%;
}

.ce_item-teaser .caption {
  font-size: 1.125rem;
  display: inline-block;
  padding: 0.15rem 0.5rem;
  color: #ffffff;
  background-color: #e2001a;
  font-weight: 400;
  position: absolute;
  bottom: -8px;
  right: -6px;
  text-transform: uppercase;
  transform: rotate(-3deg);
  box-shadow: 2px 2px 7px rgba(33, 33, 33, 0.4);
}

/* ===================================================================================================================
   RSCE 1-035 - CE Item - Image Teaser (Template: rsce_1_035_image-teaser.html5)
   ================================================================================================================ */
.ce_item-image-teaser {
  position: relative;
}

.ce_item-image-teaser a {
  position: relative;
  display: block;
  height: 100%;
  box-shadow: 2px 2px 7px rgba(33, 33, 33, 0.4);
  margin-bottom: 30px;
}

.ce_item-image-teaser img {
  width: 100%;
}

@media print, screen and (min-width: 40em) {
  .ce_item-image-teaser img {
    display: block;
  }
}

.ce_item-image-teaser .title {
  display: block;
  text-transform: uppercase;
  background-color: #f4f3f0;
  position: relative;
  width: 100%;
  padding: 15px;
  font-size: 1.125rem;
  height: auto;
  margin-bottom: 0;
  transition: background-color 0.2s, color 0.2s ease;
}

.ce_item-image-teaser h3:after {
  content: none;
}

.ce_item-image-teaser:hover .title {
  background-color: #d80019;
  color: #ffffff;
}

.ce_item-image-teaser .hover {
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  background-color: rgba(17, 17, 17, 0);
  transition: background-color 0.2s ease;
}

.ce_item-image-teaser:hover .hover {
  background-color: rgba(17, 17, 17, 0.2);
}

/* ====================================================================================================================
   RSCE 2-040-01 - Parallax // Template: rsce_2_040_01_parallax
   ================================================================================================================= */
.ce_rsce-parallax.parallax-2-040-01 {
  z-index: 1;
  height: 350px;
}

@media print, screen and (min-width: 40em) {
  .ce_rsce-parallax.parallax-2-040-01 {
    height: 450px;
  }
}

@media print, screen and (min-width: 64em) {
  .ce_rsce-parallax.parallax-2-040-01 {
    height: 650px;
  }
}

.ce_rsce-parallax.parallax-2-040-01 .parallax__container {
  z-index: -100;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  clip: rect(0, auto, auto, 0);
}

.ce_rsce-parallax.parallax-2-040-01 .parallax {
  position: fixed;
  top: 0;
  width: 100%;
  transform: translate3d(0, 0, 0);
  transform-style: preserve-3d;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ce_rsce-parallax.parallax-2-040-01 .image_container {
  height: 250px;
}

@media print, screen and (min-width: 40em) {
  .ce_rsce-parallax.parallax-2-040-01 .image_container {
    height: 350px;
  }
}

@media print, screen and (min-width: 64em) {
  .ce_rsce-parallax.parallax-2-040-01 .image_container {
    height: 450px;
  }
}

.ce_rsce-parallax.parallax-2-040-01 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.ce_rsce-parallax.parallax-2-040-01 .content-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ce_rsce-parallax.parallax-2-040-01 .content-container > .inside {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 1.25rem;
}

.ce_rsce-parallax.parallax-2-040-01 .content-container > .inside .background {
  max-width: 75rem;
}

.ce_rsce-parallax.parallax-2-040-01 .content-container .headline {
  line-height: 1;
  color: #ffffff;
  font-size: 2rem;
  font-weight: 700;
}

@media print, screen and (min-width: 40em) {
  .ce_rsce-parallax.parallax-2-040-01 .content-container .headline {
    font-size: 4rem;
  }
}

.ce_rsce-parallax.parallax-2-040-01 .content-container .headline span {
  display: block;
}

@media print, screen and (min-width: 64em) {
  .ce_rsce-parallax.parallax-2-040-01 .content-container > .inside .background {
    width: 66.66667%;
  }
}

/* ===================================================================================================================
   CE Content Wrapper
   ================================================================================================================ */
.ce_item-content-wrapper {
  padding-top: 80px;
  padding-bottom: 80px;
}

@media print, screen and (min-width: 40em) {
  .ce_item-content-wrapper {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.page_content .ce_item-content-wrapper:first-child {
  padding-top: 180px;
}

.page_content .content_type-icon-small .ce_item-content-wrapper,
.page_content .content_box-below .ce_item-content-wrapper {
  padding-top: 3.5rem;
  padding-bottom: 1rem;
}

@media print, screen and (min-width: 40em) {
  .page_content .content_type-icon-small .ce_item-content-wrapper,
  .page_content .content_box-below .ce_item-content-wrapper {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.page_type-company-10-steps .ce_item-content-wrapper {
  padding-bottom: 0;
  padding-top: 0;
}

@media print, screen and (min-width: 40em) {
  .page_type-company-10-steps .ce_item-content-wrapper {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.page_type-company-10-steps .page_content {
  padding-bottom: 0;
  padding-top: 120px;
}

@media print, screen and (min-width: 40em) {
  .page_type-company-10-steps .page_content {
    padding-top: 0;
  }
}

/* ===================================================================================================================
   Tabs
   ================================================================================================================ */
.tabs-panel.is-active,
.is-active .accordion-content {
  display: block !important;
}

.tabs-title.is-active > a,
.is-active .accordion-title,
.tabs-title:hover a,
.accordion-title:hover,
.accordion-title:focus,
.accordion-title:active {
  background: #e9e7e1;
  color: #111111;
}

.ce_item-tabs {
  max-width: 100%;
  flex: 0 0 100%;
}

.tabs-content {
  padding-top: 30px;
}

.page_type-system .tabs-title {
  text-align: center;
  width: 25%;
}

/* ====================================================================================================================
   RSCE 2-000-04 - Hero // Template: rsce_2_000_04_hero
   ================================================================================================================= */
.ce_rsce-hero.hero-2-000-04 > .inside {
  position: relative;
}

.ce_rsce-hero.hero-2-000-04 > .inside.image, .ce_rsce-hero.hero-2-000-04 > .inside.video {
  height: 100vh;
}

@media print, screen and (min-width: 64em) {
  .ce_rsce-hero.hero-2-000-04 > .inside.image, .ce_rsce-hero.hero-2-000-04 > .inside.video {
    height: calc(100vh - 78px);
  }
}

.ce_rsce-hero.hero-2-000-04 > .inside .video-item {
  height: 100vh;
}

@media print, screen and (min-width: 64em) {
  .ce_rsce-hero.hero-2-000-04 > .inside .video-item {
    height: calc(100vh - 78px);
  }
}

.ce_rsce-hero.hero-2-000-04 > .inside .video-container {
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

@media print, screen and (min-width: 64em) {
  .ce_rsce-hero.hero-2-000-04 > .inside .video-container {
    height: calc(100vh - 78px);
  }
}

.ce_rsce-hero.hero-2-000-04 > .inside video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

@media print, screen and (min-width: 64em) {
  .ce_rsce-hero.hero-2-000-04 > .inside video {
    height: calc(100vh - 78px);
  }
}

.ce_rsce-hero.hero-2-000-04 > .inside .content-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ce_rsce-hero.hero-2-000-04 > .inside .content-container > .inside {
  width: 100%;
  height: 100%;
}

.ce_rsce-hero.hero-2-000-04 > .inside .content-container > .inside .section-1 {
  z-index: 1;
  position: absolute;
  top: 12.5rem;
  left: 3.75rem;
  display: block;
  width: 12.5rem;
}

.ce_rsce-hero.hero-2-000-04 > .inside .content-container > .inside .section-1 .image_container {
  max-width: 15.9375rem;
}

@media screen and (orientation: landscape) {
  .ce_rsce-hero.hero-2-000-04 > .inside .content-container > .inside .section-1 {
    display: none;
  }
}

@media screen and (min-height: 26.25rem) and (orientation: landscape) {
  .ce_rsce-hero.hero-2-000-04 > .inside .content-container > .inside .section-1 {
    display: block;
  }
}

@media print, screen and (min-width: 30em) {
  .ce_rsce-hero.hero-2-000-04 > .inside .content-container > .inside .section-1 {
    left: 9.375rem;
    width: 18.75rem;
  }
  .ce_rsce-hero.hero-2-000-04 > .inside .content-container > .inside .section-1 .image_container {
    max-width: 18.75rem;
  }
}

@media print, screen and (min-width: 64em) {
  .ce_rsce-hero.hero-2-000-04 > .inside .content-container > .inside .section-1 {
    top: 7.8125rem;
    left: 18.75rem;
    width: 31.25rem;
  }
  .ce_rsce-hero.hero-2-000-04 > .inside .content-container > .inside .section-1 .image_container {
    max-width: 31.25rem;
  }
}

@media screen and (min-width: 64em) and (orientation: landscape) {
  .ce_rsce-hero.hero-2-000-04 > .inside .content-container > .inside .section-1 {
    display: none;
  }
}

@media screen and (min-width: 64em) and (min-height: 32.5rem) and (orientation: landscape) {
  .ce_rsce-hero.hero-2-000-04 > .inside .content-container > .inside .section-1 {
    display: block;
  }
}

.ce_rsce-hero.hero-2-000-04 > .inside .content-container > .inside .section-2 {
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  max-width: 12.5rem;
}

@media print, screen and (min-width: 64em) {
  .ce_rsce-hero.hero-2-000-04 > .inside .content-container > .inside .section-2 {
    max-width: 28.125rem;
  }
  .ce_rsce-hero.hero-2-000-04 > .inside .content-container > .inside .section-2 .image_container {
    max-width: 28.125rem;
  }
}

.ce_rsce-hero.hero-2-000-04 > .inside .content-container .headline {
  z-index: 2;
  position: absolute;
  left: 5rem;
  bottom: 6.25rem;
  padding: 0.625rem 1.25rem;
  background: #ffffff;
  transform: rotate(-3.5deg);
  color: #e2001a;
  line-height: 1;
  font-size: 1.625rem;
  font-weight: 900;
  text-align: center;
}

.ce_rsce-hero.hero-2-000-04 > .inside .content-container .headline:focus, .ce_rsce-hero.hero-2-000-04 > .inside .content-container .headline:hover {
  background: #e2001a;
  color: #ffffff;
}

@media print, screen and (min-width: 30em) {
  .ce_rsce-hero.hero-2-000-04 > .inside .content-container .headline {
    left: 12.5rem;
  }
}

@media print, screen and (min-width: 40em) {
  .ce_rsce-hero.hero-2-000-04 > .inside .content-container .headline {
    font-size: 2.375rem;
    left: unset;
    right: 6.25rem;
  }
}

@media print, screen and (min-width: 64em) {
  .ce_rsce-hero.hero-2-000-04 > .inside .content-container .headline {
    font-size: 3rem;
  }
}

.ce_rsce-hero.hero-2-000-04 > .inside .content-container .link_enter-wrapper {
  display: block;
  position: absolute;
  bottom: -13px;
  width: 100%;
}

@media print, screen and (min-width: 64em) {
  .ce_rsce-hero.hero-2-000-04 > .inside .content-container .link_enter-wrapper {
    bottom: -23px;
  }
}

.ce_rsce-hero.hero-2-000-04 > .inside .content-container .link_enter-wrapper a.link_enter {
  font-size: 1.5625rem;
  height: 35px;
  width: 36px;
  background: #e2001a;
  margin: 0 auto;
  padding: 2px 6px;
  color: #ffffff;
  display: block;
  z-index: 50;
}

.ce_rsce-hero.hero-2-000-04 > .inside .content-container .link_enter-wrapper a.link_enter:before {
  content: "";
  margin-right: 10px;
}

@media print, screen and (min-width: 40em) {
  .ce_rsce-hero.hero-2-000-04 > .inside .content-container .link_enter-wrapper a.link_enter {
    font-size: 2.25rem;
    height: 45px;
    width: 46px;
    padding: 0 5px;
  }
}

.page_type-intro-small .link_enter-wrapper {
  display: none;
}

@media print, screen and (min-width: 64em) {
  .ios ce_rsce-hero.hero-2-000-04 {
    height: calc(85vh - 78px);
  }
  .ios ce_rsce-hero.hero-2-000-04 > .inside.image, .ios ce_rsce-hero.hero-2-000-04 > .inside.video {
    height: calc(85vh - 78px);
  }
  .ios ce_rsce-hero.hero-2-000-04 > .inside .video-item {
    height: calc(85vh - 78px);
  }
  .ios ce_rsce-hero.hero-2-000-04 > .inside .video-container {
    height: calc(85vh - 78px);
  }
  .ios ce_rsce-hero.hero-2-000-04 > .inside video {
    height: calc(85vh - 78px);
  }
}

/* ====================================================================================================================
   RSCE 2-000-05 - Hero // Template: rsce_2_000_05_hero
   ================================================================================================================= */
.ce_rsce-hero.hero-2-000-05 {
  position: relative;
}

.ce_rsce-hero.hero-2-000-05 > .inside.image, .ce_rsce-hero.hero-2-000-05 > .inside.video {
  height: calc(100vh - 78px);
}

.ce_rsce-hero.hero-2-000-05 > .inside .video-item {
  position: relative;
  height: calc(100vh - 78px);
}

.ce_rsce-hero.hero-2-000-05 > .inside .video-container {
  height: calc(100vh - 78px);
  width: 100%;
  overflow: hidden;
}

.ce_rsce-hero.hero-2-000-05 > .inside video {
  width: 100%;
  height: calc(100vh - 78px);
  object-fit: cover;
}

.ce_rsce-hero.hero-2-000-05 > .inside .content-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ce_rsce-hero.hero-2-000-05 > .inside .content-container .content-box {
  max-width: 75rem;
  width: 100%;
}

.ce_rsce-hero.hero-2-000-05 > .inside .content-container .content-box .headline,
.ce_rsce-hero.hero-2-000-05 > .inside .content-container .content-box .addition {
  color: #ffffff;
}

.ce_rsce-hero.hero-2-000-05 > .inside .content-container .content-box .headline {
  display: inline-block;
  padding: 0 1.25rem;
  background: #e2001a;
}

.ce_rsce-hero.hero-2-000-05 > .inside .content-container .content-box .headline h1 {
  text-transform: uppercase;
}

.ce_rsce-hero.hero-2-000-05 > .inside .content-container .content-box .headline h1 span {
  display: block;
  line-height: 1;
  font-size: 1.875rem;
  text-transform: none;
}

.ce_rsce-hero.hero-2-000-05 > .inside .content-container .content-box .cta-container {
  display: block;
}

.ce_rsce-hero.hero-2-000-05 > .inside .content-container .content-box .cta-container p {
  position: relative;
  display: inline-block;
  margin-top: 1.25rem;
  padding: 0.625rem 1.25rem 0.625rem 2.1875rem;
  background: #ffffff;
  line-height: 1;
  color: #111111;
  font-size: 1.625rem;
  font-weight: 400;
}

.ce_rsce-hero.hero-2-000-05 > .inside .content-container .content-box .cta-container p:before {
  content: "";
  position: absolute;
  top: 0.9375rem;
  left: 0.9375rem;
  color: #e2001a;
  font-size: 1rem;
}

.ce_rsce-hero.hero-2-000-05 .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../beilharz/lp/musterhauspark/silhouette.svg");
  background-size: contain;
  background-position: 50% 100%;
  background-repeat: no-repeat;
}

/* ====================================================================================================================
   Development
   ================================================================================================================= */
.item_youtube-button {
  position: relative;
}

.item_youtube-button a {
  position: relative;
}

.item_youtube-button a:after {
  content: "";
  color: #ffffff;
  font-size: 4rem;
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
}

#CybotCookiebotDialog {
  border: 1px solid #e2001a;
}

#CybotCookiebotDialog a,
#CybotCookiebotDialog a:focus,
#CybotCookiebotDialog a:hover {
  color: #e2001a !important;
}

#CybotCookiebotDialog h2 {
  font-family: sans-serif;
  text-transform: none;
}

#CybotCookiebotDialog #CybotCookiebotDialogHeader,
#CybotCookiebotDialog #CybotCookiebotDialogPoweredByText {
  display: none !important;
}

#CybotCookiebotDialog #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.active {
  border-bottom: 1px solid #e2001a !important;
}

#CybotCookiebotDialog #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
  border-bottom: 3px solid #e2001a !important;
}

#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSliderWrapper input:focus-visible + span,
#CybotCookiebotDialog :focus-visible {
  outline: 0 !important;
}

#CybotCookiebotDialog #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
  border: 2px solid #e2001a !important;
}

#CybotCookiebotDialog #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept,
#CybotCookiebotDialog #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept,
#CybotCookiebotDialog #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  color: #ffffff;
  background-color: #e2001a !important;
}

#CybotCookiebotDialog #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:hover,
#CybotCookiebotDialog #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentIABv2Tab:hover,
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonsSelectPane label:not([for=CybotCookiebotDialogBodyLevelButtonNecessary]) .CybotCookiebotDialogBodyLevelButtonDescription:hover,
#CybotCookiebotDialog #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider:not(.CybotCookiebotDialogDetailBodyContentCookieInfoCount):hover {
  color: #e2001a !important;
}

.page_type-privacy-policy .CookieDeclarationDialogText a {
  display: none;
  visibility: hidden;
}

.remove-margin {
  margin-left: -20px;
  margin-right: -20px;
}

@media print, screen and (min-width: 40em) {
  .remove-margin {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.invisible {
  display: none !important;
  visibility: hidden !important;
}

table {
  margin-bottom: 1rem;
}

a,
p,
li,
span,
h1, h2, h3, h5, h6,
.ce_tabs > ul li a,
.ce_item-media-teaser__headline,
.ce_item-media-teaser__subheadline {
  letter-spacing: 0.002em;
}

figure {
  margin: 0;
}

select {
  outline: 0;
}

.page_type-care .page_content .image_container {
  margin-top: 2em;
}

.click-lazy {
  display: none;
}

.load-more-wrapper {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .load-more-wrapper {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.load-more-wrapper .button {
  display: inline-block;
  text-align: center;
  margin: 0 auto;
}

.page_content .ce_gallery .row {
  margin-bottom: 0;
}

.ce_rsce-hero.hero-2-000-06 {
  position: relative;
}

.ce_rsce-hero.hero-2-000-06 > .inside.image {
  height: 100vh;
}

@media print, screen and (min-width: 64em) {
  .ce_rsce-hero.hero-2-000-06 > .inside.image {
    height: calc(100vh - 78px);
  }
}

.ce_rsce-hero.hero-2-000-06 > .inside .bg-container {
  height: 100vh;
  width: 100%;
}

@media print, screen and (min-width: 64em) {
  .ce_rsce-hero.hero-2-000-06 > .inside .bg-container {
    height: calc(100vh - 78px);
  }
}

.ce_rsce-hero.hero-2-000-06 > .inside .bg-container .image_container {
  height: 100%;
}

.ce_rsce-hero.hero-2-000-06 > .inside .bg-container img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

@media print, screen and (min-width: 64em) {
  .ce_rsce-hero.hero-2-000-06 > .inside .bg-container img {
    height: calc(100vh - 78px);
  }
}

.ce_rsce-hero.hero-2-000-06 > .inside .content-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ce_rsce-hero.hero-2-000-06 > .inside .content-container .wrapper {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
}

.ce_rsce-hero.hero-2-000-06 > .inside .content-container .section-1 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .ce_rsce-hero.hero-2-000-06 > .inside .content-container .section-1 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media print, screen and (min-width: 40em) {
  .ce_rsce-hero.hero-2-000-06 > .inside .content-container .section-1 {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    max-width: 50%;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .ce_rsce-hero.hero-2-000-06 > .inside .content-container .section-1 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.ce_rsce-hero.hero-2-000-06 > .inside .content-container .section-1 .logo-container {
  max-width: 28.125rem;
  margin-bottom: 1.25rem;
}

.ce_rsce-hero.hero-2-000-06 > .inside .content-container .section-1 .headline,
.ce_rsce-hero.hero-2-000-06 > .inside .content-container .section-1 .addition {
  color: #ffffff;
}

.ce_rsce-hero.hero-2-000-06 > .inside .content-container .section-1 .headline {
  display: inline-block;
  padding: 0 1.25rem;
  background: #8dae35;
}

.ce_rsce-hero.hero-2-000-06 > .inside .content-container .section-1 .headline h1 {
  text-transform: uppercase;
  font-size: 2.125rem;
}

.ce_rsce-hero.hero-2-000-06 > .inside .content-container .section-1 .subheadline {
  display: inline-block;
  margin-top: 1.25rem;
  padding: 0 1.25rem;
  color: #ffffff;
}

.ce_rsce-hero.hero-2-000-06 > .inside .content-container .section-1 .cta-container {
  display: block;
}

.ce_rsce-hero.hero-2-000-06 > .inside .content-container .section-1 .cta-container p {
  position: relative;
  display: inline-block;
  margin-top: 1.25rem;
  padding: 0.625rem 1.25rem 0.625rem 2.1875rem;
  background: #ffffff;
  line-height: 1;
  color: #111111;
  font-size: 1.625rem;
  font-weight: 400;
}

.ce_rsce-hero.hero-2-000-06 > .inside .content-container .section-1 .cta-container p:before {
  content: "";
  position: absolute;
  top: 0.9375rem;
  left: 0.9375rem;
  color: #8dae35;
  font-size: 1rem;
}

.ce_rsce-hero.hero-2-000-06 > .inside .content-container .section-2 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  max-width: 100%;
}

@media print, screen and (min-width: 40em) {
  .ce_rsce-hero.hero-2-000-06 > .inside .content-container .section-2 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media print, screen and (min-width: 40em) {
  .ce_rsce-hero.hero-2-000-06 > .inside .content-container .section-2 {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    max-width: 50%;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .ce_rsce-hero.hero-2-000-06 > .inside .content-container .section-2 {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}
