/* ===================================================================================================================
   Navigation - Onepage Vertical Fixed
   ================================================================================================================ */
// Version: n/a
// Licence: https://codyhouse.co/terms/
// Copyright: CodyHouse
// Link: https://codyhouse.co/gem/vertical-fixed-navigation/
// -------------------------------------------------------------------------------------------------------------------

#cd-vertical-nav ul {
	margin     : 0;
	list-style : none;
}

.cd-section {
	min-height : 100%;
	//min-height : 100vh;
	position   : relative;
}

.cd-nav-trigger {
	display       : block;
	z-index       : 2;
	position      : fixed;
	bottom        : 35px;
	right         : 0;
	height        : 44px;
	width         : 50px;
	//border-radius : 0.25em;
	background    : $black;
	color: $white;
	
	@include icon(arrow-up-left3, after) {
		position  : absolute;
		top       : calc(50% - 11px);
		left      : calc(50% - 11px);
		margin    : 0;
		font-size : 22px;
	}
;
}


.cd-nav-trigger:hover {
	color: $white;
	
}
.cd-nav-trigger.open{
	
	@include icon(arrow-down-right3, after){
		position  : absolute;
		top       : calc(50% - 11px);
		left      : calc(50% - 11px);
		margin    : 0;
		font-size : 22px;
	}
;
}

#cd-vertical-nav {
	position                   : fixed;
	z-index                    : 99999;
	right                      : 50px;
	bottom                     : 35px;
	width                      : 90%;
	max-width                  : 250px;
	max-height                 : 90%;
	overflow-y                 : scroll;
	-webkit-overflow-scrolling : touch;
	transform-origin           : right bottom;
	transform                  : scale(0);
	transition-property        : transform;
	transition-duration        : 0.2s;
	//border-radius              : 0.25em;
	background-color           : rgba($white, 0.9);
}

#cd-vertical-nav a {
	display       : block;
	padding       : .5em 1em;
	border-bottom : 1px solid rgba(62, 57, 71, 0.1);
	text-transform: uppercase;
}

#cd-vertical-nav a span:first-child {
	//display : none;
}

#cd-vertical-nav.open {
	transform : scale(1);
}

#cd-vertical-nav li:last-child a {
	border-bottom : none;
}

// -------------------------------------------------------------------------------------------------------------------

@include breakpoint(xlarge) {
	
	.cd-nav-trigger {
		display    : none;
		visibility : hidden;
	}
	
	.is-selected {
		position : relative;
	}
	
	.cd-dot-wrapper {
		
		text-transform: uppercase;
		
	}
	
	
	
	.cd-dot-wrapper span{
		@include button();
		padding: 0.5em;
		margin-bottom: 0.15em;
		background-color: $dark-gray;
		color: $white;
	}
	
	.is-selected > .cd-dot-wrapper span{
		background-color: $primary-color;
	}
	//.is-selected > .cd-dot-wrapper:after {
	//	position  : absolute;
	//	top       : 5px;
	//	right     : -5px;
	//	display   : inline-block;
	//	width     : 20px;
	//	height    : 20px;
	//	padding   : 10px;
	//	border    : 1px solid #191919;
	//	transform : rotate(45deg);
	//	content   : '';
	//
	//}
	
	//
	// .cd-dot-wrapper:after {
	//	position  : absolute;
	//	//top       : 5px;
	//	right     : -5px;
	//	display   : inline-block;
	//	width     : 20px;
	//	height    : 20px;
	//	padding   : 10px;
	//	//border    : 1px solid #191919;
	//	//transform : rotate(45deg);
	//	background-color: $primary-color;
	//	content   : '';
	//	border-radius: 50%;
	//}
	
	
	#cd-vertical-nav {
		position         : fixed;
		right            : -2px;
		top              : 50%;
		bottom           : auto;
		width            : auto;
		max-width        : none;
		max-height       : none;
		overflow-y       : visible;
		transform        : translateY(-50%);
		background-color : transparent;
		border-radius    : 0;
	}
	
	#cd-vertical-nav li {
		text-align : right;
	}
	
	#cd-vertical-nav a {
		display                     : inline-block;
		padding                     : 0;
		/* prevent weird movements on hover when you use a CSS3 transformation - webkit browsers */
		-webkit-backface-visibility : hidden;
		backface-visibility         : hidden;
		border-bottom               : 0;
	}
	
	#cd-vertical-nav a:after {
		content : "";
		display : table;
		clear   : both;
	}
	
	#cd-vertical-nav a span { }
	
	#cd-vertical-nav a span:first-child {
		display : inline-block;
	}
	
	#cd-vertical-nav a:hover span {
		color : $white;
		background-color: $secondary-color;
	}
	
	#cd-vertical-nav a:hover .cd-label {
		opacity : 1;
	}
	
	#cd-vertical-nav a.is-selected .cd-dot {
		//background-color : white;
	}
	
	#cd-vertical-nav .cd-dot {
		position  : relative;
		/* we set a top value in order to align the dot with the label. If you change label's font, you may need to change this top value*/
		top       : 4px;
		left      : -2px;
		font-size : rem-calc(14);
	}
	
	#cd-vertical-nav li:nth-of-type(10) .cd-dot {
		left : 1px;
	}
	
	#cd-vertical-nav .cd-label {
		display          : block;
		position         : relative;
		margin-top       : -25px;
		margin-right     : 25px;
		padding          : .3em .5em;
		color            : $white;
		font-size        : rem-calc(13);
		transition       : transform 0.2s, opacity 0.2s;
		opacity          : 0;
		transform-origin : 100% 50%;
		//border-radius    : 5px;
		background-color : $primary-color;
	}
	
}
