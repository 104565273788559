/* ====================================================================================================================
   RSCE 2-000-05 - Hero // Template: rsce_2_000_05_hero
   ================================================================================================================= */

.ce_rsce-hero.hero-2-000-05 {
	
	position : relative;
	
	> .inside {
		
		&.image,
		&.video {
			height : calc(100vh - 78px);
		}
		
		.video-item {
			position : relative;
			height   : calc(100vh - 78px);
		}
		
		.video-container {
			height   : calc(100vh - 78px);
			width    : 100%;
			overflow : hidden;
		}
		
		video {
			width      : 100%;
			height     : calc(100vh - 78px);
			object-fit : cover;
		}
		
		// --------------------------------------------------------------------------------------------------------------------
		
		.content-container {
			position        : absolute;
			top             : 0;
			left            : 0;
			width           : 100%;
			height          : 100%;
			display         : flex;
			flex-direction  : column;
			justify-content : center;
			align-items     : center;
			
			//padding-bottom  : 25vh;
			
			.content-box {
				max-width : rem-calc(1200);
				width     : 100%;
				
				.headline,
				.addition { color : $white; }
				
				.headline {
					display    : inline-block;
					padding    : 0 rem-calc(20);
					background : $secondary-color;
					
					h1 {
						text-transform : uppercase;
						
						span {
							display        : block;
							line-height    : 1;
							font-size      : rem-calc(30);
							text-transform : none;
						}
					}
				}
				
				.cta-container {
					display : block;
					
					p {
						position    : relative;
						display     : inline-block;
						margin-top  : rem-calc(20);
						padding     : rem-calc(10) rem-calc(20) rem-calc(10) rem-calc(35);
						background  : $white;
						line-height : 1;
						color       : $black;
						font-size   : rem-calc(26);
						font-weight : $font-weight-2;
						
						&:before {
							@include icon(arrow-right2);
							position  : absolute;
							top       : rem-calc(15);
							left      : rem-calc(15);
							color     : $secondary-color;
							font-size : rem-calc(16);
						}
					}
				}
			}
		}
	}
	
	// --------------------------------------------------------------------------------------------------------------------
	
	.overlay {
		position            : absolute;
		bottom              : 0;
		left                : 0;
		width               : 100%;
		height              : 100%;
		background-image    : url("../../beilharz/lp/musterhauspark/silhouette.svg");
		background-size     : contain; // cover
		background-position : 50% 100%;
		background-repeat   : no-repeat;
	}
}
