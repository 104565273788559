@charset "utf-8";
/*!
======================================================================================================================
   Projekt: Beilharz Haus - WS Firma
   -------------------------------------------------------------------------------------------------------------------
   Werbeagentur Neubert GmbH - https://werbeagentur-neubert.de + Contao Service - https://contao-service.de
======================================================================================================================
*/
// -------------------------------------------------------------------------------------------------------------------
// Foundation Version 6.3.1
// -------------------------------------------------------------------------------------------------------------------
@import 'settings-fonts';
@import 'settings-foundation';
@import 'settings-motion-ui';
@import 'settings-project';
// -------------------------------------------------------------------------------------------------------------------
@import 'foundation/_foundation';
@import 'motion-ui/_motion-ui';
// -------------------------------------------------------------------------------------------------------------------
@import 'foundation/util/mixins';
// -------------------------------------------------------------------------------------------------------------------
@include foundation-global-styles;
@include foundation-visibility-classes;
@include foundation-float-classes;
// -------------------------------------------------------------------------------------------------------------------
@include foundation-flex-classes;
// -------------------------------------------------------------------------------------------------------------------
//@include foundation-grid;
@include foundation-flex-grid;
// -------------------------------------------------------------------------------------------------------------------
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
////@include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
@include foundation-dropdown-menu;
//@include foundation-flex-video;
//@include foundation-label;
//@include foundation-media-object;
//@include foundation-off-canvas;
@include foundation-orbit;
////@include foundation-pagination; // Keine Verwendung
//@include foundation-progress-bar;
////@include foundation-progress-element;
////@include foundation-meter-element;
////@include foundation-slider;
//@include foundation-sticky;
@include foundation-reveal;
//@include foundation-switch;
//@include foundation-table;
@include foundation-tabs;
//@include foundation-thumbnail;
@include foundation-title-bar;
//@include foundation-tooltip;
@include foundation-top-bar;
// -------------------------------------------------------------------------------------------------------------------
@include motion-ui-transitions;
@include motion-ui-animations;
// -------------------------------------------------------------------------------------------------------------------
@import 'theme-project/__components';
@import '_theme-development'; // Disable for production!
