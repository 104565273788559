// ===================================================================================================================
// OnePage Navigation basierend auf Foundation Reveal
// ===================================================================================================================
// Javascript für Close-Funktion bei Onepager erforderlich.

// Modal
// -------------------------------------------------------------------------------------------------------------------

body.is-reveal-open {
	//overflow-x : hidden;
}

.reveal-overlay {
	//overflow-x : hidden;
}

html.is-reveal-open body {
	//overflow-x : hidden;
}

.modal-nav-wrapper {
	//background-color : $dark-gray;
	//position         : fixed;
	//width            : 100%;
	//height           : 35px;
	//padding-top      : 5px;
	//z-index          : 2;
}

a.nav-modal-button {
	text-align     : right;
	text-transform : uppercase;
	//width          : 400px;
	//margin         : 0 auto;
	display        : block;
	margin-top     : 1.5em;
	margin-bottom  : 0.5em;
	margin-right   : 1.5em;
	@include breakpoint(medium) {
		float         : right;
		text-align    : right;
		margin-top    : 1.5em;
		margin-bottom : 0;
	}
	
}

a.nav-modal-button span {
	letter-spacing : $letter-spacing-medium;
}

.modal-nav-wrapper .menu-icon {
	margin-left : 0.5em;
	//margin-top     : -0.25em;
	
}

.modal-nav-wrapper .menu-icon:after, .modal-nav-wrapper .menu-icon:hover:after {
	box-shadow : 0 6px 0 $body-font-color, 0 12px 0 $body-font-color;
	background : $body-font-color;
}

#nav-modal {
	height : 100%;
	width  : 100%;
	top    : 0 !important;
}

#nav-modal.reveal {
	background : rgba($light-gray, 0.95);
	max-width  : none;
}

#nav-modal ul {
	list-style    : none;
	width         : 100%;
	margin-left   : 0;
	margin-bottom : 0;
	//padding-top : 20%;
}

#nav-modal li {
	display    : block;
	text-align : center;
}

#nav-modal a {
	color : $body-font-color;
}

#nav-modal .primary-menu {
	padding-top : 7%;
	@include breakpoint(large) {
		padding-top : 15%;
	}
}

#nav-modal .primary-menu li {
	margin-bottom : 1em;
	@include breakpoint(large) {
		margin-bottom : 1.5em;
	}
}

#nav-modal .primary-menu a {
	font-size : rem-calc(20);
	@include breakpoint(large) {
		font-size : rem-calc(26);
	}
}

#nav-modal .primary-menu img {
	width        : 40px;
	margin-left  : -20px;
	margin-right : 10px;
	@include breakpoint(large) {
		width : 60px;
	}
}

.nav-modal-logo .columns {
	border-top  : 1px solid $dark-gray;
	padding-top : 2rem;
	margin-top  : 2rem;
	@include breakpoint(large) {
		padding-top : 3rem;
		margin-top  : 4rem;
	}
	
}

#nav-modal .secondary-menu {
	padding-top : 2rem;
	
	@include breakpoint(large) {
		padding-top : 2rem;
	}
}

#nav-modal .secondary-menu li {
	padding-bottom : 0.5rem;
	
	@include breakpoint(large) {
		padding-bottom : 0;
	}
}

#nav-modal .secondary-menu li a:before {
	@include icon(arrow-right3);
	top          : 3px;
	margin-right : 5px;
	color        : $secondary-color;
	display      : inline;
	font-size    : rem-calc(12);
}

#nav-modal a:hover,
#nav-modal a:focus,
#nav-modal a:active,
#nav-modal .active a,
#nav-modal a.trail {
	color : $primary-color;
}



