/* ===================================================================================================================
   Formulare
   ================================================================================================================ */

.page_type-contact .ce_form {
	margin           : 1em 0;
	background-color : $light-gray;
	padding-top      : 2rem;
	padding-bottom   : 2rem;
	box-shadow       : $global-shadow;
}

.page_type-contact .formbody,
.page_type-module-house-balance .formbody {
	@include flex-grid-row();
	//max-width  : 55rem;
	//text-align : center;
}

.page_type-contact .formbody .widget {
	@include flex-grid-column(12);
	@include breakpoint(medium) {
		@include flex-grid-column(6);
	}
	text-align : left;
}

.page_type-contact .formbody .widget-explanation {
	@include flex-grid-column(12);
}

.page_type-contact .formbody .widget:nth-child(9),
.page_type-contact .formbody .widget:nth-child(10),
.page_type-contact .formbody .widget:nth-child(11),
.page_type-contact .formbody .widget:nth-child(12),
.page_type-contact .formbody .widget:nth-child(13),
.page_type-contact .formbody .widget:nth-child(15),
.page_type-contact .formbody .widget:nth-child(16),
.page_type-contact .formbody .submit_container {
	@include flex-grid-column(12);
	@include breakpoint(medium) {
		@include flex-grid-column(12);
	}
}

.formbody a {
	text-decoration : underline;
}

p.error {
	//padding     : 0.85em 0.5em;
	font-size     : rem-calc(12);
	font-weight   : $font-weight-3;
	//color       : $white;
	color         : $alert-color;
	margin-bottom : 0.25em;
}

.submit {
	@include button();
	margin-top     : $margin-small;
	text-transform : uppercase;
	//float: left;
	font-weight    : 600;
	text-transform : uppercase;
	border         : 2px solid $medium-gray;
	
	&:hover {
		color : $white;
	}
}

legend, .widget-select label {
	//padding-top: $padding-medium;
	font-weight : $font-weight-4;
	width       : 100%;
	font-size   : 100%;
	//text-align: center;
	
}

.widget-checkbox span,
.widget-radio span {
	display : block;
	//float: left;
	//text-align: left;
	@include breakpoint(medium) {
		display : inline-block;
	}
}

[type="checkbox"] + label, [type="radio"] + label {
	display : inline;
}

.mandatory .mandatory {
	display : inline;
}

textarea {
	min-height : 150px;
}

// -------------------------------------------------------------------------------------------------------------------
// Modulbox Formular
// -------------------------------------------------------------------------------------------------------------------

.content_type-modulbox-form .submit {
	//text-align : center;
}

.content_type-modulbox-form .toggler {
	@include button();
	display        : block;
	width          : 300px;
	margin         : 0.5em auto;
	margin-bottom  : 2.5em;
	//text-indent: -999px;
	overflow       : hidden;
	font-size      : rem-calc(16);
	padding        : 0.5em 1em 0.85em 1em;
	text-transform : uppercase;
}

.content_type-modulbox-form .ui-accordion-header-icon:before {
	display      : inline-block;
	@include icon(arrow-down3);
	position     : relative;
	color        : $white;
	top          : 3px;
	margin-right : 2px;
	font-size    : rem-calc(20);
}

.content_type-modulbox-form .active .ui-accordion-header-icon:before {
	@include icon(arrow-up3);
}

.content_type-modulbox-form [type="text"],
.content_type-modulbox-form [type="email"],
.content_type-modulbox-form [type="tel"],
.content_type-modulbox-form textarea {
	background-color : lighten($light-gray, 2%);
}

.content_type-modulbox-form textarea {
	height : 150px;
}

// =========================================================================

.form_data-protection {
	text-align : left;
}

.form_mandatory-note-1 p,
.form_mandatory-note-2 p {
	//margin-bottom : $global-margin;
	//text-align : left;
	font-size : 90%;
	//font-weight : $font-weight-3;
}

// =========================================================================

.form_contact-mini {
	.formbody {
		@include flex-grid-row;
		
		.form_division-1-12 {
			@include flex-grid-column(12);
		}
		
		.form_division-2-66 {
			@include flex-grid-column(12);
			//@include breakpoint(xsmall) {
			//	@include flex-grid-column(6);
			//}
			@include breakpoint(medium) {
				@include flex-grid-column(6);
			}
		}
		
		textarea {
			min-height : 203px;
		}
		
		.widget-radio {
			text-align : left;
			
			span {
				display : block;
			}
		}
		
		//[type='radio'] {
		//	margin : 0;
		//}
		
		.submit {
			width   : 100%;
			margin  : 0;
			padding : 0.66em 1em;
		}
	}
}

//.form_contact-mini .formbody .form_mandatory-note-1 {
//	@include show-for(xsmall);
//	text-align : left;
//}
//
//.form_contact-mini .formbody .form_mandatory-note-2 {
//	@include hide-for(xsmall);
//	text-align : left;
//}

//===========================================

.agreement_data_storage {
	text-align : left;
	
	[type='checkbox'] {
		margin-bottom : 0;
		line-height   : 1;
	}
}

.form_mandatory-note-1 p,
.form_mandatory-note-2 p {
	margin     : $global-margin 0;
	font-size  : 90%;
	text-align : left;
	//font-weight   : $font-weight-3;
	//color         : darken($medium-gray, 40%);
}

// -------------------------------------------------------------------------------------------------------------------
// Durch Umbau von Upload-Feldern, wir die Pflichtfeld-Fehlermeldung nicht oberhalb des Labels (anstelle überhalb Input) ausgegeben.
// Verschiebung der Position des Codes der Fehlermeldung im Template führt zu Fehlermeldung von Contao.
// Daher Einfügung Grid und Umsortierung der Reihenfolge via CSS
// -------------------------------------------------------------------------------------------------------------------

.widget-upload {
	//@include xy-grid();
	
	label {
		//@include xy-cell(shrink, $gutters : 0, $gutter-output : false);
		//order : 2;
		
	}
	
	.file-upload {
		@include button();
		@include button-secondary;
		width          : 100%;
		margin-bottom  : 1rem;
		display        : block;
		line-height    : 21px;
		padding-top    : rem-calc(8);
		padding-bottom : rem-calc(8);
		padding-left   : rem-calc(8);
		
		&:after {
			@include icon(arrow-up16);
		}
	}
	
	//span.label {
	//	display     : block;
	//	line-height : 21px;
	//}
	
	p.error {
		//@include xy-cell(12, $gutters : 0, $gutter-output : false);
		//order : 1;
	}
}
