/* ===================================================================================================================
   RSCE 1-010 - CE Item - Teaser (Template: rsce_1_001_teaser)
   ================================================================================================================ */
//.ce_item-teaser { }
// -------------------------------------------------------------------------------------------------------------------

.ce_item-teaser {
	
	padding-top: $padding-xlarge;
	padding-bottom: $padding-xlarge;
}


.ce_item-teaser .columns{
	
	margin-bottom:$margin-medium;
	
}


.ce_item-teaser .columns > div, .ce_item-teaser .columns > a{
	height: 100%;
	background-color: $light-gray;
	//padding: 1.5rem;
	position: relative;
	text-align: center;
	box-shadow: $global-shadow;


}



.ce_item-teaser .columns > a {
	
	display: block;
	width: 100%;
}

.ce_item-teaser .content {
	
	padding: 2.5rem 2rem 0.5rem 2rem;
}

.ce_item-teaser h2 {
	
	text-transform: uppercase;
	font-size: rem-calc(30);
	font-weight: $font-weight-1;
	padding-bottom: 0;
	hyphens: auto;

}

.ce_item-teaser h3 {
	
	@include secondary-font();
	padding-top: 0;
	padding-bottom: $padding-small;

}


.ce_item-teaser  h3:after {
	content: none;
	
}


.ce_item-teaser .image_container {
	position: relative;
}

.ce_item-teaser img{
	width: 100% ;
}

.ce_item-teaser .caption {
	font-size: rem-calc(18);
	display: inline-block;
	padding: 0.15rem 0.5rem;
	color: $white;
	background-color: $secondary-color;
	font-weight: $font-weight-2;
	position: absolute;
	bottom: -8px;
	right: -6px;
	text-transform: uppercase;
	transform: rotate(-3deg);
	box-shadow: $global-shadow;
	//@include secondary-font;
}

.ce_item-teaser .button {
	
	//@include flex-align-self(bottom);
}
