/* ====================================================================================================================
   Development
   ================================================================================================================= */
// Temporäre Datei während Entwicklung - Für PRO Betrieb sollten alle Inhalte in separate Module ausgelagert sein!
// Löschung/ Deaktivierung von '@import '_theme-development';' in 'project' durchführen
//
// ===================================================================================================================
// CE "Bild" mit Youtube Button zur Öffnung Video in Lightbox / Template: ce_image_video_lightbox
// ===================================================================================================================
.item_youtube-button {
	
	position : relative;
	//margin   : 0 auto;
	
	a {
		position : relative;
		//display  : block;
		//width    : 100%;
		//height   : 100%;
		//position : absolute;
		//top      : 0;
		//left     : 0;
		
		//&:hover { // Abgleich mit '.page_content .ce_image a .hover-content'
		//	background-color : rgba($dark-gray, 0.7);
		//	transition       : opacity 0.2s ease;
		//}
		
		&:after {
			@include icon(youtube); // play4 // youtube // youtube2 // custom-youtube
			color     : $white;
			font-size : rem-calc(64);
			position  : absolute;
			top       : calc(50% - 32px);
			left      : calc(50% - 32px);
		}
	}
}

// ===================================================================================================================
// Individualisierung Cookiebot
// ===================================================================================================================

#CybotCookiebotDialog {
	
	border : 1px solid #e2001a;
	
	a,
	a:focus,
	a:hover { color : #e2001a !important; }
	
	h2 {
		font-family    : sans-serif;
		text-transform : none;
	}
	
	#CybotCookiebotDialogHeader,
	#CybotCookiebotDialogPoweredByText { display : none !important; }
	
	#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.active { border-bottom : 1px solid #e2001a !important; }
	
	#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive { border-bottom : 3px solid #e2001a !important; }
	
	.CybotCookiebotDialogBodyLevelButtonSliderWrapper input:focus-visible + span,
	:focus-visible { outline : 0 !important; }
	
	#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton { border : 2px solid #e2001a !important; }
	
	#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept,
	#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept,
	#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
		color            : #ffffff;
		background-color : #e2001a !important; }
	
	#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:hover,
	#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentIABv2Tab:hover,
	#CybotCookiebotDialogBodyLevelButtonsSelectPane label:not([for=CybotCookiebotDialogBodyLevelButtonNecessary]) .CybotCookiebotDialogBodyLevelButtonDescription:hover,
	#CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider:not(.CybotCookiebotDialogDetailBodyContentCookieInfoCount):hover { color : #e2001a !important;}
}

// ===================================================================================================================
// ALT - vor Umstellung auf neues Banner-Layout 2023-02-16
// ===================================================================================================================
// TODO: Den Aufbau des Overlay-Layouts ggf. vollständig durch ein eigenes Layout (Umstellung von Float auf Grid etc.) umstellen - lohnt der Aufwand?!
//#CybotCookiebotDialog {
//
//	//background : #0a0a0a !important; // Test
//	h2 {
//		font-family    : 'neuzeit-grotesk', sans-serif;
//		font-size      : rem-calc(18);
//		text-transform : initial;
//		letter-spacing : $letter-spacing-small;
//	}
//
//	#CybotCookiebotDialogBodyContentTitle {
//		// TODO: Bei Ausblendung der H2 müssten weitere Anpassungen am Overlay durchgeführt werden.
//		//@include hide-total;
//	}
//
//	#CybotCookiebotDialogBodyContentText {
//		font-family    : 'neuzeit-grotesk', sans-serif;
//		font-size      : rem-calc(14);
//		letter-spacing : $letter-spacing-small;
//
//		a {
//			border-bottom   : 1px solid $white;
//			font-family     : 'neuzeit-grotesk', sans-serif;
//			text-decoration : initial;
//			letter-spacing  : $letter-spacing-small;
//
//			&:focus,
//			&:hover { border-bottom : none; }
//		}
//	}
//
//	#CybotCookiebotDialogBodyButtons a {
//		//margin-top     : rem-calc(10);
//		font-family    : 'neuzeit-grotesk', sans-serif;
//		letter-spacing : $letter-spacing-small;
//		font-size      : rem-calc(14);
//
//	}
//
//	a#CybotCookiebotDialogBodyButtonDecline,
//	a#CybotCookiebotDialogBodyButtonAccept {
//		padding        : rem-calc(4) rem-calc(6);
//		border         : 1px solid $black;
//		letter-spacing : $letter-spacing-small;
//
//		&:focus,
//		&:hover {
//			border          : 1px solid $white;
//			text-decoration : initial;
//		}
//
//		&:visited,
//		&:active { border : none;}
//	}
//
//	a#CybotCookiebotDialogBodyButtonAccept {
//		font-weight : 700;
//	}
//
//	a#CybotCookiebotDialogBodyButtonDetails {
//		//padding         : rem-calc(4); // "Sprengt" das Layout
//		text-decoration : none;
//		letter-spacing  : $letter-spacing-small;
//
//		a:visited,
//		a:active { text-decoration : none; }
//	}
//}

// --------------------------------------------------------------------------------------------------------------------

.page_type-privacy-policy .CookieDeclarationDialogText a {
	display    : none;
	visibility : hidden;
}

// ===================================================================================================================

// Helferklasse - Integration in Array 'row' von DMA Simple Grid Konfiguration
// SASS Nutzung via mixin 'remove-grid-row-margin' - siehe 'settings-project'
.remove-margin {
	@include grid-remove-row-margin;
}

// Contao Core Klasse (Skip Links, Formulare etc.)
// Gleichnamige Foundation Klasse ungeeignet
// Umsetzung unten nicht ideal, da Screenreader ausgeschlossen
.invisible {
	display    : none !important;
	visibility : hidden !important;
}

table {
	margin-bottom : 1rem;
}

a,
p,
li,
span,
h1, h2, h3, h5, h6,
.ce_tabs > ul li a,
.ce_item-media-teaser__headline,
.ce_item-media-teaser__subheadline {
	letter-spacing : $letter-spacing-small;
}

// Überschreibt die Normalisierung. Im Grid wahrscheinlich zumeist sinnvoll (Abhängig vom Layout)
// Könnte in anderen Fällen aber nicht sinnvoll sein (Text + Bild)
figure { margin : 0; }

select { outline : 0; }

// --------------------------------------------------------------------------------------------------------------------
// TEMP
// --------------------------------------------------------------------------------------------------------------------

.page_type-care .page_content .image_container {
	margin-top : 2em;
}

.click-lazy {
	display : none;
}

.load-more-wrapper {
	@include flex-grid-column(12);
}

.load-more-wrapper .button {
	display    : inline-block;
	text-align : center;
	margin     : 0 auto;
}

.page_content .ce_gallery .row {
	margin-bottom : 0;
}

// --------------------------------------------------------------------------------------------------------------------
// LP - Musterhaus
// --------------------------------------------------------------------------------------------------------------------

//.ce_text.image-teaser {
//
//	position : relative;
//
//	height   : 450px;
//	@include breakpoint(large) { height : 650px; }
//
//	.image-container {
//		height : 450px;
//		@include breakpoint(large) { height : 650px; }
//
//		//.image_container { height : 100%; }
//
//		img {
//			width      : 100%;
//			height     : 450px;
//			object-fit : cover;
//			@include breakpoint(large) { height : 650px; }
//		}
//	}
//
//	.text-container {
//		position : absolute;
//		top      : 0;
//		left     : 0;
//		width    : 100%;
//		height   : 100%;
//	}
//
//	h2 {
//		display         : flex;
//		flex-direction  : column;
//		width           : 100%;
//		height          : 100%;
//		max-width       : 1200px;
//		justify-content : center;
//		margin-left     : auto;
//		margin-right    : auto;
//		padding         : 0 rem-calc(20);
//		color           : #ffffff;
//		font-weight     : $font-weight-3;
//
//		&:after { display : none; }
//	}
//
//}
// --------------------------------------------------------------------------------------------------------------------
// TEMP - LP Vorwärts
// --------------------------------------------------------------------------------------------------------------------

.ce_rsce-hero.hero-2-000-06 {
	
	position : relative;
	
	> .inside {
		
		&.image {
			height : 100vh;
			@include breakpoint(large) { height : calc(100vh - 78px); }
		}
		
		.bg-container {
			height : 100vh;
			width  : 100%;
			@include breakpoint(large) { height : calc(100vh - 78px); }
			
			.image_container { height : 100%; }
			
			img {
				height     : 100vh;
				width      : 100%;
				object-fit : cover;
				@include breakpoint(large) { height : calc(100vh - 78px); }
			}
		}
		
		// --------------------------------------------------------------------------------------------------------------------
		
		.content-container {
			position        : absolute;
			top             : 0;
			left            : 0;
			width           : 100%;
			height          : 100%;
			display         : flex;
			flex-direction  : row;
			justify-content : center;
			align-items     : center;
			
			.wrapper {
				@include flex-grid-row;
				align-items : center;
				width       : 100%;
			}
			
			.section-1 { // content-box
				//max-width : rem-calc(1200);
				//width     : 100%;
				@include flex-grid-column(12);
				
				@include breakpoint(medium) { @include flex-grid-column(6); }
				
				.logo-container {
					max-width     : rem-calc(450);
					margin-bottom : rem-calc(20);
				}
				
				.headline,
				.addition { color : $white; }
				
				.headline {
					display    : inline-block;
					padding    : 0 rem-calc(20);
					background : #8dae35;
					
					h1 {
						text-transform : uppercase;
						font-size      : rem-calc(34);
						//span {
						//	display        : block;
						//	line-height    : 1;
						//	font-size      : rem-calc(30);
						//	text-transform : none;
						//}
					}
				}
				
				.subheadline {
					display    : inline-block;
					margin-top : rem-calc(20);
					padding    : 0 rem-calc(20);
					color      : #ffffff;
				}
				
				.cta-container {
					display : block;
					
					p {
						position    : relative;
						display     : inline-block;
						margin-top  : rem-calc(20);
						padding     : rem-calc(10) rem-calc(20) rem-calc(10) rem-calc(35);
						background  : $white;
						line-height : 1;
						color       : $black;
						font-size   : rem-calc(26);
						font-weight : $font-weight-2;
						
						&:before {
							@include icon(arrow-right2);
							position  : absolute;
							top       : rem-calc(15);
							left      : rem-calc(15);
							color     : #8dae35;
							font-size : rem-calc(16);
						}
					}
				}
			}
			
			.section-2 {
				@include flex-grid-column(12);
				
				@include breakpoint(medium) { @include flex-grid-column(6); }
			}
		}
	}
}






