// TODO:IS Vorbereitung Basisinstallation, u.a. Ergänzungen für Core Elemente

p.small {
	font-size : 80%;
}

.color {
	color : $secondary-color;
}

.page_content, .page_footer {
	
	p a {
		text-decoration : underline;
	}
	
	p a.button {
		text-decoration : none;
	}
}

// Contao Floating Classes
// Breakpoint und Abstand ggf. Anpassen

.float_above {
	margin-bottom : $margin-small;
}

.float_left {
	margin-bottom : $margin-small;
	@include breakpoint(large) {
		float        : left;
		margin-right : $margin-small;
	}
}

.float_right {
	margin-bottom : $margin-small;
	@include breakpoint(large) {
		float       : right;
		margin-left : $margin-small;
	}
}

// Grid
.expanded .row {
	margin-left  : auto;
	margin-right : auto;
	max-width    : 75rem;
}

.page_content .row {
	margin-bottom : 1.5em;
}

@mixin bg-color {
	
	//padding: 1.5rem;
	padding-top : 2rem;
}

.bg-color-lightgrey {
	@include bg-color;
	background-color : $light-gray;
}

.bg-color-white {
	@include bg-color;
	background-color : $white;
}

.bg-color-primary {
	@include bg-color;
	background-color : $primary-color;
}

.text-center .row-one-column, .text-center.row-one-column,
.row-one-column.text-center {
	@include flex-align(center);
}

.row-one-column > * {
	@include flex-grid-column(12);
	@include breakpoint(large) {
		@include flex-grid-column(10);
	}
}

.row-two-columns > * {
	@include flex-grid-column(12);
	@include breakpoint(large) {
		@include flex-grid-column(6);
	}
}

.row-three-columns > * {
	@include flex-grid-column(12);
	@include breakpoint(medium) {
		@include flex-grid-column(6);
	}
	@include breakpoint(large) {
		@include flex-grid-column(4);
	}
}

.row-four-columns > * {
	@include flex-grid-column(12);
	@include breakpoint(medium) {
		@include flex-grid-column(6);
	}
	@include breakpoint(large) {
		@include flex-grid-column(3);
	}
}

.row-big-small > *:nth-child(odd) {
	@include flex-grid-column(12);
	//@include breakpoint(medium) {
	//@include flex-grid-column(6);
	//}
	@include breakpoint(large) {
		@include flex-grid-column(8);
	}
}

.row-big-small > *:nth-child(even) {
	@include flex-grid-column(12);
	//@include breakpoint(medium) {
	//@include flex-grid-column(6);
	//}
	@include breakpoint(large) {
		@include flex-grid-column(4);
	}
}

.row-small-big > *:nth-child(odd) {
	@include flex-grid-column(12);
	//@include breakpoint(medium) {
	//@include flex-grid-column(6);
	//}
	@include breakpoint(large) {
		@include flex-grid-column(4);
	}
}

.row-small-big > *:nth-child(even) {
	@include flex-grid-column(12);
	//@include breakpoint(medium) {
	//@include flex-grid-column(6);
	//}
	@include breakpoint(large) {
		@include flex-grid-column(8);
	}
}

.remove-grid {
	width     : 100% !important;
	flex      : 0 0 100% !important;
	max-width : none !important;
	margin    : 0 auto;
}

.remove-grid > * {
	//padding-left  : 1.25rem;
	//padding-right : 1.25rem;
}

// Gallery

.ce_gallery ul {
	@include flex-grid-row();
	-moz-box-pack   : center;
	justify-content : center;
}

.row .ce_gallery {
	padding-left  : 0;
	padding-right : 0;
}

.ce_gallery li {
	list-style : none;
}

.ce_gallery img {
	width : 100%;
}

// Gallery > Bildgrößen bzw. Anzahl in einer Reihe

.ce_gallery .cols_1 li {
	@include flex-grid-column(12);
}

.ce_gallery .cols_2 li {
	@include flex-grid-column(6);
}

.ce_gallery .cols_3 li {
	@include flex-grid-column(12);
	@include breakpoint(medium) {
		@include flex-grid-column(4);
	}
}

.ce_gallery .cols_4 li {
	@include flex-grid-column(6);
	@include breakpoint(medium) {
		@include flex-grid-column(3);
	}
}

.ce_gallery .cols_6 li {
	@include flex-grid-column(6);
	@include breakpoint(medium) {
		@include flex-grid-column(2);
	}
	
}

//Mime Icons

.page_content .ce_download, .page_content .ce_downloads {
	a[href$=".pdf"],
	a[href$=".doc"],
	a[href$=".docx"],
	a[href$=".word"] {
		@include icon(file-text3, before);
		margin-left : 20px;
		display     : inline-block;
	}
	
	a[href$=".png"],
	a[href$=".jpg"],
	a[href$=".svg"],
	a[href$=".bmp"],
	a[href$=".gif"],
	a[href$=".tif"] {
		@include icon(image, before);
		margin-left : 20px;
		display     : inline-block;
	}
	
	a[href$=".zip"] {
		@include icon(file-zip2, before);
		margin-left : 20px;
		display     : inline-block;
	}
	
	a[href$=".xl"],
	a[href$=".xls"],
	a[href$=".xlsx"] {
		@include icon(file-spreadsheet2, before);
		margin-left : 20px;
		display     : inline-block;
	}
	
	a[href$=".mp4"] {
		@include icon(volume-medium, before);
		margin-left : 20px;
		display     : inline-block;
	}
	
	a[href$=".mov"],
	a[href$=".avi"],
	a[href$=".wmv"] {
		@include icon(play4, before);
		margin-left : 20px;
		display     : inline-block;
	}
	
	a[href$=".pdf"]:before,
	a[href$=".doc"]:before,
	a[href$=".docx"]:before,
	a[href$=".word"]:before,
	a[href$=".jpg"]:before,
	a[href$=".png"]:before,
	a[href$=".jpg"]:before,
	a[href$=".svg"]:before,
	a[href$=".bmp"]:before,
	a[href$=".gif"]:before,
	a[href$=".zip"]:before,
	a[href$=".xl"]:before,
	a[href$=".xls"]:before,
	a[href$=".xlsx"]:before,
	a[href$=".mp4"]:before,
	a[href$=".wmv"]:before,
	a[href$=".mov"]:before,
	a[href$=".avi"]:before {
		margin-left     : -20px;
		margin-top      : 3px;
		text-decoration : none !important;
		position        : absolute;
	}
	
}

iframe {
	width     : 1px; // iOS Fix
	min-width : 100%; // iOS Fix
	border    : none;
}
