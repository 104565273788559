/* ===================================================================================================================
   Navigation - Breadcrumb
   ================================================================================================================ */

.mod_breadcrumb {
	
	@include flex-grid-column(12);
}

.mod_breadcrumb ul {
	margin     : 0;
	list-style : none;
}

.mod_breadcrumb li {
	display   : inline-block;
	font-size : rem-calc(14);
}


.mod_breadcrumb li.first {
	display   : none;
}

.mod_breadcrumb li:after {
	padding-left : 5px;
	content      : '\00BB';
}

.mod_breadcrumb li:last-child:after {
	padding : 0;
	content : '';
}

.mod_breadcrumb a{
	color : $black;
}
.mod_breadcrumb a:focus,
.mod_breadcrumb a:hover {
	color : $secondary-color;
}
