/* ===================================================================================================================
   News
   ================================================================================================================ */

.mod_newslist {
	max-width : 100%;
	flex      : 0 0 100%;
}

.mod_newslist {
	@include flex-grid-row();
}

.mod_newslist .more a {
	@include button();
	text-decoration : none;
}

.mod_newslist { }

.mod_newslist .pagination {
	@include flex-grid-column(12)
}

// -------------------------------------------------------------------------------------------------------------------
// Newslist Simple

.mod_newslist .layout_simple {
}

// -------------------------------------------------------------------------------------------------------------------
// Newslist Short

.mod_newslist .layout_short {
}

// -------------------------------------------------------------------------------------------------------------------
// Newslist Full

.mod_newslist .layout_full {
}

// Nachrichtenliste - Home
// -------------------------------------------------------------------------------------------------------------------

.page_type-home .mod_newslist h4 {
	@include flex-grid-column(12);
}

.page_type-home .mod_newslist .layout_short {
	transition     : background-color 0.25s ease-out 0s;
	padding-top    : 1rem;
	padding-bottom : 1rem;
}

.page_type-home .mod_newslist .layout_short:hover {
	background : $secondary-color;
	color      : $white;
}

.mod_newslist .layout_short {
	//text-align: center;
}

.mod_newslist .layout_short a {
	display : block;
	height  : 100%;
}

.mod_newslist .layout_short .more {
	text-decoration : underline;
}

.mod_newslist .layout_short h2, .mod_newslist .layout_short h2 a {
	font-size      : 1.5rem;
	position       : relative;
	font-weight    : $font-weight-1;
	//text-transform: uppercase;
	//color: $color-2;
	padding-bottom : 0;
}

.mod_newslist .layout_short h2 a:hover,
.mod_newslist .layout_short:hover h2,
.mod_newslist .layout_short:hover .teaser {
	color : $white;
}

.mod_newslist .layout_short p {
	
	//color: darken($color-2,10%);
}

.mod_newslist .layout_short:nth-child(2),
.mod_newslist .layout_short:nth-child(3) {
	margin-bottom  : $padding-small;
	margin-top     : $padding-small;
	padding-bottom : $padding-small;
	border-bottom  : 1px solid $medium-gray;
	@include breakpoint(medium) {
		margin-bottom  : 0;
		margin-top     : 0;
		padding-bottom : 0;
		border-bottom  : 0;
		//border-right   : 1px solid $medium-gray;
	}
	
}

.content_type-news .mod_newslist {
	padding : 0;
}

.content_type-news {
	padding-top    : $padding-small;
	padding-bottom : $padding-small;
	@include breakpoint(medium) {
		padding-top    : $padding-large;
		padding-bottom : $padding-large;
	}
}

p.more {
	font-weight : $font-weight-1;
}

.more a {
	//@include button();
	//text-transform : uppercase;
	font-weight : $font-weight-2;
	font-size   : rem_calc(16);
}

.button-back {
	margin-top : 2em;
}

// Nachrichtenliste - Layout 1
// -------------------------------------------------------------------------------------------------------------------

.news_list.layout-1 {
	@include flex-grid-column(12);
	margin-bottom : 2em;
	@include breakpoint(medium) {
		@include flex-grid-column(6);
	}
	@include breakpoint(large) {
		@include flex-grid-column(4);
	}
	
}

.news_list.layout-1 .news_list__wrapper-outside {
	background-color : $light-gray;
	height           : 100%;
	box-shadow       : $global-lighter-shadow;
	position         : relative;
}

.news_list.layout-1 .news_list__wrapper-inside {
	//height: 100%;
}

.news_list.layout-1 .image_container {
	position : relative;
	display  : block;
	height   : 100%;
}

.news_list.layout-1 .news_list__content {
	position : relative;
	padding  : 0 $padding-medium $padding-medium $padding-medium;
}

.news_list__metadata {
	position         : absolute;
	z-index          : 2;
	background-color : $secondary-color;
	top              : -35px;
	box-shadow       : $global-shadow;
	color            : $white;
	display          : inline-block;
	font-size        : 1.125rem;
	font-weight      : 400;
	padding          : 0.15rem 0.5rem;
	right            : -6px;
	text-transform   : uppercase;
	transform        : rotate(-3deg);
}

.news_list.layout-1 .hover {
	height           : 100%;
	width            : 100%;
	display          : block;
	position         : absolute;
	top              : 0;
	background-color : rgba($primary-color, 0);
	transition       : background-color 0.2s ease;
}

.news_list.layout-1:hover .hover {
	background-color : rgba($primary-color, 0.2);
}

.news_list .more a {
	@include button();
	
	//font-size   : rem-calc(19);
	font-weight    : 600;
	text-transform : uppercase;
	border         : 2px solid $medium-gray;
	margin-bottom  : 0;
	&:hover {
		color : $white;
	}
	
}

.news_reader.layout-1 .caroufredsel_skin {
	padding-left  : 0;
	padding-right : 0;
}

.ce_text.bg-color-lightgrey {
	padding : $padding-medium;
}

.ce_text.bg-color-lightgrey h3 {
	
	//padding-bottom: 0.5rem;
	text-transform : none;
}

.ce_text.bg-color-lightgrey h3:after {
	
	//content: none;
	
}
